import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"
import { isBrowser } from "@context/themeContext"
import { gtmPush, transformCartProduct } from "@helpers/gtmHelpers"

const MAX_STAGED_PRODUCTS = 5
const CACHE_PRECART = true
const CLEAR_CART_PARAM = "clear-cart"
const ORDER_PARAM = "order"
const ORDER_STATUS_PARAM = "status"
const ORDER_TOTAL_PARAM = "total"
const ORDER_TAX_PARAM = "shipping"
const ORDER_SHIPPING_PARAM = "tax"

const validatePayload = payload => {
  if (!payload) return false

  const payloadShape = {
    name: "",
    id: "",
    type: "",
    sku: "",
    brand: "",
    category: "",
    hasUpsells: false,
    upsellPath: "",
    attachTo: "",
    path: "",
    prices: {},
    variantDetails: {},
    variantSku: ""
  }

  for (const [key, value] of Object.entries(payload)) {
    if (typeof value !== typeof payloadShape[key]) {
      console.log(
        `Warning: Payload ${key} is ${typeof value}. Expected ${typeof payloadShape[
          key
        ]}`
      )

      alert(
        `We are having issues matching your selection to a product. Please contact a sales person.`
      )

      return false
    }
  }

  return true
}

const defaultState = {
  cartLoading: true,
  cart: new Map(),
  precart: new Map(),
  isOpen: false,
  add: () => {},
  toggle: () => {},
  remove: () => {},
  clear: () => {},
  getTotal: () => {},
  getSubtotal: () => {},
  getCartLinkProps: () => {},
  postPurchase: () => {},
  count: 0
}

const CartContext = React.createContext(defaultState)

/**
 * Provides context to cart and product configuration inputs / forms
 * @extends React
 */
class CartProvider extends React.Component {
  state = {
    precart: new Map(),
    cart: new Map(),
    count: 0,
    isOpen: false,
    purchased: false,
    cartLoading: true
  }

  /**
   * Memorizing product configuration prior to adding to cart
   */
  stage = ({ sku, ...cache }) => {
    const { cart, precart } = this.state

    // Check cart and do update instead
    if (cart.get(sku)) {
      this.add({ sku, ...cache })
      return `${sku} in cart, updating`
    }

    // Stop precart form getting too large.
    if (!precart.get(sku) && precart.size >= MAX_STAGED_PRODUCTS) {
      this.trimPrecart()
    }

    precart.set(sku, { sku, ...cache })

    if (CACHE_PRECART) localStorage.setItem("Cart.precart", mapToJson(precart))

    this.setState({ precart })

    return precart
  }

  /**
   * Trims oldest item from precart
   */
  trimPrecart = () => {
    const { precart } = this.state
    const item = precart.keys()[0]

    precart.delete(item)

    return `${item} removed`
  }

  /**
   * Adds item to cart
   */
  add = (payload, callback) => {
    const payloadValidated = validatePayload(payload)
    if (!payloadValidated) return

    const { sku, type, variantDetails } = payload
    const { cart, precart } = this.state

    // Validation action
    if (cart.has(sku)) {
      const currentProduct = cart.get(sku)

      // Product already in cart. Ignore action
      if (currentProduct.variantSku === payload.variantSku) {
        return cart
      }
    }

    const gtmProduct = transformCartProduct(payload)

    precart.delete(sku)

    // Remove invalid upsells from cart.
    if (type === "configurable") {
      try {
        const upsells = [...cart.values()].filter(
          ({ attachTo }) => attachTo && attachTo === sku
        )
        const invalidUpsells = upsells.filter(
          ({ sku }) => variantDetails.upsells.indexOf(sku) < 0
        )
        invalidUpsells.forEach(({ sku }) => cart.delete(sku))
      } catch (e) {
        console.log(e)
      }
    }

    cart.set(sku, {
      qty: 1,
      sku,
      displayName:
        payload.type === "configurable"
          ? this.variantName(payload)
          : payload.name,
      ...payload
    })

    localStorage.setItem("Cart.cart", mapToJson(cart))

    const ecommerceItem = [
      {
        item_id: gtmProduct.id,
        item_name: gtmProduct.name,
        affiliation: "Spa World",
        item_brand: gtmProduct.brand,
        item_category: gtmProduct.category,
        item_variant: gtmProduct.variant,
        price: gtmProduct.price,
        currency: gtmProduct.currency,
        quantity: gtmProduct.quantity
      }
    ]

    gtmPush({ ecommerce: null })
    gtmPush({
      event: "add_to_cart",
      ecommerce: {
        currency: gtmProduct.currency,
        value: gtmProduct.price,
        items: ecommerceItem
      }
    })

    this.setState({ cart, count: cart.size, precart })

    callback && callback()

    return cart
  }

  toggle = (payload, callback = false) => {
    let { cart } = this.state

    if (!cart.has(payload.sku)) {
      this.add(payload, callback)
    } else {
      this.remove(payload.sku)
    }
  }

  /**
   * Improves variant name appearance
   */
  variantName = ({ name, variantDetails }) => {
    if (!variantDetails) return name

    if (variantDetails.hasOwnProperty("specifics")) {
      const model = variantDetails.specifics.find(
        ({ name }) => name === "Model Type"
      )
      const depth = variantDetails.specifics.find(
        ({ name }) => name === "Depth"
      )
      const upgrade = variantDetails.specifics.find(
        ({ name, value }) => name === "Package" && value !== "Standard"
      )

      const specArray = [model, depth, upgrade]

      for (var i = 0; i < specArray.length; i++) {
        if (typeof specArray[i] === "undefined") continue

        if (specArray[i].hasOwnProperty("value")) {
          name += ` ${specArray[i].value}`
        }
      }
    }

    return name
  }

  /**
   * Gets cart totals
   */
  getTotal = () => {
    const { cart } = this.state
    let total = 0

    cart.forEach(({ prices, qty }, key) => {
      total = total + (prices.price + qty)
    })

    return total
  }

  /**
   * Gets cart totals less shipping
   */
  getSubtotal = () => {
    const { cart } = this.state

    let subtotal = 0

    cart.forEach(({ prices, qty, type }, key) => {
      if (type !== "shipping") {
        subtotal = subtotal + prices.price * qty
      }
    })

    return subtotal
  }

  /**
   * Get link to send customer to customise page if they have one configurable in
   * their cart
   */
  getCartLinkProps = () => {
    const { cart } = this.state

    const defaults = {
      to: "/cart/"
    }

    // is there one configurable or else standard cart.
    const allItems = [...cart.values()]
    const configurableItems = allItems.filter(
      ({ type }) => type && type === "configurable"
    )

    if (configurableItems.length !== 1) {
      return defaults
    }

    const primaryItem = configurableItems[0]

    if (
      !primaryItem.hasOwnProperty("upsellPath") &&
      !primaryItem.hasOwnProperty("path")
    ) {
      console.log("Product missing either upsellPath or path")
      return defaults
    }

    return {
      to: primaryItem.upsellPath || `${primaryItem.path}customise/`
    }
  }

  /**
   * Removes product from cart by sku. If other skus are attached to the given
   * sku, they will be removed also
   * @param  {String} sku Products unique identifier
   */
  remove = sku => {
    let { cart } = this.state

    if (!cart.has(sku)) return "Item not in cart"

    const removedItem = cart.get(sku)
    const gtmProduct = transformCartProduct(removedItem)

    const cartArray = [...cart.values()]
    let upsells = []

    try {
      upsells = cartArray.filter(({ attachTo }) => attachTo && attachTo === sku)
    } catch (e) {
      console.log(e)
    }

    const upsellSkus = upsells.map(({ sku }) => sku)
    const skusToRemove = [sku, ...upsellSkus]

    skusToRemove.forEach(key => cart.delete(key))

    localStorage.setItem("Cart.cart", mapToJson(cart))

    const ecommerceItem = [
      {
        item_id: gtmProduct.id,
        item_name: gtmProduct.name,
        affiliation: "Spa World",
        item_brand: gtmProduct.brand,
        item_category: gtmProduct.category,
        item_variant: gtmProduct.variant,
        price: gtmProduct.price,
        currency: gtmProduct.currency,
        quantity: gtmProduct.quantity
      }
    ]

    gtmPush({ ecommerce: null })
    gtmPush({
      event: "remove_from_cart",
      ecommerce: {
        currency: gtmProduct.currency,
        value: gtmProduct.price,
        items: ecommerceItem
      }
    })

    this.setState({
      cart,
      count: cart.size
    })

    return "Item(s) removed: " + upsellSkus.join(",")
  }

  /**
   * Pushes cart data to GTM
   *
   * @param  { Object } params [description]
   */
  postPurchase = params => {
    const cart = JSON.parse(localStorage.getItem("Cart.cart"))

    if (!cart) {
      return false
    }

    const actionField = {
      id: params[ORDER_PARAM],
      affiliation: "Online Store"
    }

    if (params[ORDER_TOTAL_PARAM] !== undefined) {
      actionField.revenue = params[ORDER_TOTAL_PARAM]
    }

    if (params[ORDER_TAX_PARAM] !== undefined) {
      actionField.tax = params[ORDER_TAX_PARAM]
    }

    if (params[ORDER_SHIPPING_PARAM] !== undefined) {
      actionField.shipping = params[ORDER_SHIPPING_PARAM]
    }

    const cartArray = [...cart.values()]
    const productArray = cartArray.map(product => transformCartProduct(product))

    this.setState({ purchased: true })

    gtmPush({
      ecommerce: {
        purchase: {
          actionField: actionField,
          products: productArray
        }
      }
    })
  }

  /**
   * Removes all items
   */
  clear = () => {
    let cart = new Map()
    let count = 0

    localStorage.setItem("Cart.cart", mapToJson(cart))
    this.setState({ cart, count })

    return `Cart cleared`
  }

  getParams = () => {
    if (!isBrowser) return false

    const query = window.location.search

    if (!query) return false

    return queryString.parse(query)
  }

  componentDidMount() {
    let lsCount = 0
    const params = this.getParams()
    const { purchased } = this.state

    if (!purchased && params[ORDER_PARAM] !== undefined) {
      this.postPurchase(params)
    }

    if (
      params[CLEAR_CART_PARAM] !== undefined ||
      params[ORDER_STATUS_PARAM] === "success"
    ) {
      localStorage.removeItem("Cart.cart")
    }

    const lsCart = jsonToMap(localStorage.getItem("Cart.cart"))

    const lsIsOpen = JSON.parse(localStorage.getItem("Cart.isOpen"))

    const lsPrecart = CACHE_PRECART
      ? jsonToMap(localStorage.getItem("Cart.precart"))
      : new Map()

    if (lsCart) {
      lsCart.forEach(({ qty }) => {
        lsCount = lsCount + qty
      })
      this.setState({ cart: lsCart, precart: lsPrecart, count: lsCount })
    }

    if (lsIsOpen) {
      this.setState({ isOpen: lsIsOpen })
    }

    this.setState({ cartLoading: false })
  }

  render() {
    const { children, checkoutURL } = this.props
    const { cart, precart, count, cartLoading } = this.state

    return (
      <Location>
        {({ location }) => {
          return (
            <CartContext.Provider
              value={{
                cart,
                precart,
                count,
                cartLoading,
                checkoutURL: `${checkoutURL}?${encodeCart(this.state.cart)}`,
                remove: this.remove,
                clear: this.clear,
                add: this.add,
                toggle: this.toggle,
                stage: this.stage,
                getTotal: this.getTotal,
                getSubtotal: this.getSubtotal,
                getCartLinkProps: this.getCartLinkProps,
                postPurchase: this.postPurchase
              }}
            >
              {children}
            </CartContext.Provider>
          )
        }}
      </Location>
    )
  }
}

/**
 * Converts a Map object to JSON
 * @param  {Map} map
 * @return {JSON}
 */
function mapToJson(map) {
  return JSON.stringify([...map])
}

/**
 * Restores Map object from JSON
 * @param  {JSON} jsonStr
 * @return {Map}
 */
function jsonToMap(jsonStr) {
  return new Map(JSON.parse(jsonStr))
}

/**
 * URL Encodes cart into Neto Cart consumer format
 * @param  {Map} cart
 * @return {String}
 */
function encodeCart(cart = new Map()) {
  const cartArray = [...cart].map(item => {
    const { variantSku, sku, qty } = item[1]

    return {
      sku: variantSku || sku,
      qty: qty || 1
    }
  })
  return encodeURIComponent(JSON.stringify(cartArray))
}

export default CartContext

export { CartProvider, mapToJson, jsonToMap, encodeCart }
