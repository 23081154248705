import React, { useContext, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swatch, Button, Pill, Modal } from "@components"
import style from "./viewer.mod.scss"
import { motion, AnimatePresence } from "framer-motion"
import ProductContext from "@context/productContext"
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp"
import { IoIosCloseCircle } from "@react-icons/all-files/io/IoIosCloseCircle"
import { IoIosHelpCircle } from "@react-icons/all-files/io/IoIosHelpCircle"
import {
  getOptionsMap,
  getCurrentRenderFromVariant,
  knownSpecifics
} from "@helpers/variants"
import IconViewer from "@svgs/viewer-icon.svg"
import { isBrowser } from "@context/themeContext"
import { gtmPush } from "@helpers/gtmHelpers"

const mql = isBrowser() ? window.matchMedia("(max-width: 1024px)") : false

const isMobile = mql.matches

const Viewer = ({
  placeholder,
  heading,
  name,
  lead,
  renders = [],
  modifiers = [],
  items = [],
  defaultVariant,
  video,
  productSpecificsComparison
}) => {
  const [currentVariant, setCurrentVariant] = useState(defaultVariant)
  const [currentRender, setCurrentRender] = useState(placeholder)

  const optionTypes = new Map(items.map(({ id, heading }) => [id, heading]))
  const currentValues =
    currentVariant && getOptionsMap(currentVariant.specifics)

  const {
    productLoading,
    setOption,
    validOptions,
    currentModel,
    options
  } = useContext(ProductContext)

  const getModelSpecificsFromCards = productSpecificsComparison.find(
    item => item.model.apiKey === "product_specifics_grid"
  )

  const modelLabels = getModelSpecificsFromCards?.cards.map(item => {
    return item.specificValue
  })

  const orderedModelSpecifics = []

  // Update table state when product comes online.
  useEffect(() => {
    if (!productLoading) {
      setCurrentVariant(currentModel)
      if (renders.length > 1) {
        const render = getCurrentRenderFromVariant(
          renders,
          currentModel,
          optionTypes
        )

        render?.image && setCurrentRender(render)
      }
    }
  }, [productLoading, currentModel, optionTypes, renders])
  // End

  // Handling Model Specifics
  // const ModelSpecificName = "Model Type"
  const modelSpecificName = getModelSpecificsFromCards?.specificName
  const currentModelType = currentValues.get(modelSpecificName)
  const validModelValues =
    (validOptions && validOptions.get(modelSpecificName)) || []
  // End

  getModelSpecificsFromCards &&
    modelLabels.forEach(label => {
      const spec = validModelValues.find(detail => detail === label)
      if (spec !== undefined) orderedModelSpecifics.push(spec)
    })

  const [isModalOpen, setModalOpen] = useState(false)
  const [plattarSrc, setPlattarSrc] = useState("")
  const [qrCodeSrc, setQrCodeSrc] = useState("")
  const [is3dViewerVisible, setIs3dViewerVisible] = useState(false)
  const [isArSelected, setIsArSelected] = useState(false)
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false)
  const [isCustomiseOpen, setIsCustomiseOpen] = useState(false)
  const [isPlattarConnected, setisPlattarConnected] = useState(false)

  const [shouldViewerAnimate, setShouldViewerAnimate] = useState(false)
  const [hasViewedHelpVideo, setHasViewedHelpVideo] = useState(false)

  const getLocalStorage = namespace =>
    JSON.parse(localStorage.getItem(namespace))

  isQrCodeVisible && modifiers.push(style["viewer--qr-code-viewer"])
  isCustomiseOpen && modifiers.push(style["viewer--customise-open"])

  shouldViewerAnimate && modifiers.push(style["viewer--animate"])

  const plattarEmbed = useRef(null)

  const viewerInit = () => {
    if (plattarEmbed) {
      plattarEmbed.current.startViewer()
    }
  }

  const launchAR = () => {
    console.log(window.PlattarARAdapter)
    if (window.PlattarARAdapter.Util.canAugment()) {
      plattarEmbed.current
        .startAR()
        .then(() => {
          // nothing
        })
        .catch(err => {
          renderQRCode()
        })
    } else {
      // fallback to rendering QR Code
      renderQRCode()
    }
  }

  const renderQRCode = () => {
    plattarEmbed.current
      .startQRCode()
      .then(() => {
        // nothing
      })
      .catch(err => {
        console.error(err)
      })
  }

  const loadCabinet = () => {
    console.log("LOAD CABINET")
    if (plattarEmbed?.current?.viewer) {
      plattarEmbed.current.viewer.messenger.selectProductVariation(
        `${currentVariant.viewerCabinetProductId}`,
        `${currentVariant.viewerCabinetVariationId}`
      )
    }
  }

  const loadShell = () => {
    console.log("LOAD SHELL")
    if (plattarEmbed?.current?.viewer) {
      plattarEmbed.current.viewer.messenger.selectProductVariation(
        `${currentVariant.viewerShellProductId}`,
        `${currentVariant.viewerShellVariationId}`
      )
    }
  }

  useEffect(() => {
    setIsArSelected(false)
    setIsQrCodeVisible(false)

    if (plattarEmbed?.current?.viewer) {
      console.log("CHECK")
      plattarEmbed.current.viewer.setAttribute(
        "scene-id",
        `${currentVariant.viewerSceneId}`
      )
    }
  }, [currentVariant.viewerSceneId])

  useEffect(() => {
    setIsArSelected(false)
    setIsQrCodeVisible(false)
    if (plattarEmbed) {
      loadCabinet()
    }
  }, [currentVariant.viewerCabinetVariationId])

  useEffect(() => {
    setIsArSelected(false)
    setIsQrCodeVisible(false)
    if (plattarEmbed) {
      loadShell()
    }
  }, [currentVariant.viewerShellVariationId])

  const close3dViewer = () => {
    setIs3dViewerVisible(false)
    setShouldViewerAnimate(false)
    setIsArSelected(false)
    setIsQrCodeVisible(false)
  }

  const open3dViewer = () => {
    // if (!isPlattarConnected) {
    viewerInit()
    // }
    setIs3dViewerVisible(true)
    setShouldViewerAnimate(true)
    setIsArSelected(false)
    setIsQrCodeVisible(false)
  }

  //---------- END VIEWER CONFIG ----------//

  //---------- MOBILE VIEWER CONFIG ----------//

  const toggleIsCustomiseOpen = () =>
    isCustomiseOpen ? setIsCustomiseOpen(false) : setIsCustomiseOpen(true)

  const variants = {
    collapsed: {
      height: "0",
      opacity: 0,
      transition: {
        opacity: {
          duration: 0.2,
          ease: "linear"
        },
        height: {
          ease: "easeOut"
        }
      }
    },
    expanded: {
      height: "auto",
      opacity: 1,
      transition: {
        opacity: {
          ease: "linear"
        },
        height: {
          ease: "easeOut"
        }
      }
    }
  }

  //---------- END MOBILE VIEWER CONFIG ----------//

  const openModal = () => {
    gtmPush({
      event: "view-ar-help-video",
      label: `AR help button clicked`
    })
    setModalOpen(true)
  }
  const closeModal = () => setModalOpen(false)

  // const handleHelpVideo = () => {
  //   const ls = getLocalStorage("hasViewedHelperVideo")
  //   if (ls) {
  //     setHasViewedHelpVideo(true)
  //   } else {
  //     setHasViewedHelpVideo(false)
  //     openModal()
  //   }
  // }

  const handleCloseButton = () => {
    close3dViewer()
  }

  const getClickId = () => {
    return name
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .trim("-")
  }

  return (
    <>
      <Modal
        video={video}
        globalState={isModalOpen}
        onChange={closeModal}
        ariaLabel={`Open modal and play video: ${video.title}`}
        gtm={{
          event: `video-engagement`,
          label: `Video Modal Click`,
          value: `${video.title}`
        }}
      />
      {items.length > 0 && (
        <div className={[style.viewer, ...modifiers].join(" ")}>
          <div className={style.viewer__intro}>
            <h2>{heading}</h2>
            <p>{lead}</p>
            <div className={style.viewer__buttons}>
              <Button
                type="button"
                children="Click to view in 3D"
                white
                secondary
                fullWidth
                id={`${getClickId()}-ar-preview`}
                disabled={is3dViewerVisible}
                icon={<IconViewer />}
                onClick={() => {
                  console.log("CLICKED")
                  open3dViewer()
                  gtmPush({
                    event: "initiate-360-viewer",
                    label: `Click to view in 3D button clicked`
                  })
                }}
              />
            </div>
          </div>

          {/* CONTAINER START */}
          <motion.div
            className={style.viewer__container}
            animate={
              shouldViewerAnimate
                ? { backgroundColor: "rgba(244, 244, 244, 1)" }
                : { backgroundColor: "rgba(244, 244, 244, 0)" }
            }
          >
            <div className={style.viewer__product}>
              <motion.div
                className={style.viewer__iframe}
                animate={is3dViewerVisible ? { opacity: 1 } : { opacity: 0 }}
              >
                <plattar-embed
                  ref={plattarEmbed}
                  id="embed"
                  embed-type="configurator"
                  scene-id={`${currentVariant.viewerSceneId}`}
                  width="100%"
                  height="100%"
                  qr-type="ar"
                />
              </motion.div>
              <div className={style.viewer__close}>
                <button onClick={handleCloseButton}>
                  <IoIosCloseCircle />
                </button>
              </div>
              <div className={style.viewer__render}>
                <AnimatePresence>
                  <motion.div
                    className={style["viewer__render-image"]}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.6 } }}
                    exit={{ opacity: 0 }}
                    key={`viewer-render-image-${currentRender?.id}`}
                  >
                    <GatsbyImage
                      className={style["viewer__render-image-gatsby"]}
                      objectFit="contain"
                      image={currentRender?.image?.gatsbyImageData}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
              <div className={style.viewer__ar}>
                <Button
                  type="button"
                  children="View in home"
                  secondary
                  onClick={() => {
                    launchAR()
                    setIsQrCodeVisible(true)
                  }}
                  disabled={!isMobile && isArSelected}
                  gaTarget={"initiate-view-in-home"}
                />
              </div>
              <div className={style.viewer__help}>
                <button onClick={openModal}>
                  <IoIosHelpCircle />
                </button>
              </div>
            </div>

            {/* CUSTOMISE TAB START */}
            <div
              className={style.viewer__customise}
              onClick={toggleIsCustomiseOpen}
            >
              <div className={style.viewer__tab}>
                <div className={style.viewer__circle}></div>
              </div>
              <motion.div
                className={style.viewer__arrow}
                animate={isCustomiseOpen ? "open" : "collapsed"}
                initial={"collapsed"}
                variants={{
                  open: { rotate: 180 },
                  collapsed: { rotate: 0 }
                }}
              >
                <IoIosArrowUp />
              </motion.div>
              <h2>{"Customise"}</h2>
            </div>
            {/* CUSTOMISE TAB END
            CONTROLS START
            This needs to be animated in on mobile devices when isCustomiseOpen is true after isArSelected is true  */}
            <motion.div
              className={style.viewer__controls}
              layoutTransition
              useInvertedScale
              key={`viewer-controls-motion}`}
              animate={
                shouldViewerAnimate && isMobile && !isCustomiseOpen
                  ? "collapsed"
                  : "expanded"
              }
              initial={shouldViewerAnimate ? "collapsed" : "expanded"}
              variants={variants}
            >
              {/* OPTIONS START */}
              <div className={style.viewer__options}>
                {orderedModelSpecifics.length > 1 && (
                  <div className={style.viewer__model}>
                    <h3>{modelSpecificName}</h3>
                    <div className={style.viewer__pills}>
                      {orderedModelSpecifics.map((item, i) => {
                        const isSelected = item === currentModelType
                        const disabled =
                          productLoading ||
                          orderedModelSpecifics.indexOf(item) < 0

                        return (
                          <Pill
                            secondary
                            elevated
                            name={modelSpecificName}
                            value={item}
                            isChecked={isSelected}
                            disabled={disabled}
                            type="radio"
                            onChange={({ target }) => {
                              // setisPlattarConnected(false)
                              // viewerInit()
                              setOption(target.name, target.value)
                            }}
                            key={`model-type-${i}`}
                          >
                            {item}
                          </Pill>
                        )
                      })}
                    </div>
                  </div>
                )}
                <div className={style.viewer__swatches}>
                  {items.map(({ swatches, heading }) => {
                    if (swatches.length < 1) return null
                    const validValues =
                      !productLoading && validOptions.get(heading)

                    return (
                      <div
                        key={`swatch-${heading}`}
                        className={style.viewer__wrap}
                      >
                        <h3>{`${heading}s`}</h3>
                        <div className={style.viewer__list}>
                          {swatches.map(item => {
                            const isSelected =
                              currentValues.get(heading) === item.name || false

                            const disabled =
                              productLoading ||
                              validValues.indexOf(item.name) < 0

                            return (
                              <div
                                key={`swatch-${item.id}`}
                                className={style.viewer__item}
                              >
                                <Swatch
                                  swatch={item.swatch}
                                  name={item.name}
                                  value={item.name}
                                  disabled={disabled}
                                  isChecked={isSelected}
                                  onChange={({ target }) => {
                                    !productLoading &&
                                      setOption(heading, target.name)
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {isQrCodeVisible && (
                  <div className={style.viewer__qr_code}>
                    <div className={style.viewer__home}>
                      <h3>{"View in home"}</h3>
                      <p>
                        {
                          "Open the camera app on your phone or tablet and scan this code"
                        }
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {/* OPTIONS END */}
            </motion.div>
            {/* CONTROLS END */}
          </motion.div>
          {/* CONTAINER END */}
          <p className={style.viewer__disclaimer}>
            {
              "*Disclaimer: The 3D and AR models may show additional extras. Actual colours may differ from renders."
            }
          </p>
        </div>
      )}
    </>
  )
}

export default Viewer
