import React from "react"
import { Container, Block, TrustBox } from "@components"
import { InView } from "react-intersection-observer"

const PreFooter = ({ isJacuzzi = false }) => {
  return (
    <Container>
      <Block
        gutters
        padding={"double-top"}
        heading={"Five Star Trust Pilot Rating"}
        lead={
          "We are proud that Spa World customers have given us a Five Star Trustpilot rating."
        }
        darkText={isJacuzzi}
      >
        <InView>
          {({ inView, ref, entry }) => (
            <div ref={ref}>
              <TrustBox inView={inView} />
            </div>
          )}
        </InView>
      </Block>
    </Container>
  )
}

export default PreFooter
