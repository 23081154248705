import React from "react"
import style from "./contact.mod.scss"
import { Input } from "@components"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../button"

const ContactProductForm = ({ register }) => {
  return (
    <>
      <Input
        label={"Your Phone Number"}
        type="tel"
        name="phone"
        {...register("phone")}
        required
        placeholder={"(##) ### #### or ### ### ####"}
        fullWidth
      />

      <Input
        label={"Your Postcode"}
        name="postcode"
        {...register("postcode")}
        required
        placeholder={"Enter your postcode"}
        fullWidth
      />
    </>
  )
}

const ContactProductFormHead = ({ location }) => {
  const {
    heading,
    details,
    hoverImage,
    image,
    excerpt,
    sku,
    clearanceUrl
  } = location.state

  return (
    <div className={style.contact__header}>
      <h3 className={style.contact__heading}>{"Selected Product"}</h3>
      <div className={style["contact__header-info"]}>
        <div>
          <h3>{heading}</h3>
          <table>
            <tbody>
              {details &&
                details.length > 0 &&
                details.map(
                  item =>
                    item.value && (
                      <tr key={`product-form-row-${item.name}`}>
                        <th>{item.name}</th>
                        <td>{item.value}</td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
          {excerpt && <p className={style.contact__heading}>{excerpt}</p>}
          {clearanceUrl && (
            <Button to={clearanceUrl}>Detailed Product Info</Button>
          )}
        </div>
        <div className={style["contact__header-image"]}>
          {(hoverImage || image) && (
            <GatsbyImage
              image={
                hoverImage ? hoverImage.gatsbyImageData : image.gatsbyImageData
              }
            />
          )}
        </div>
      </div>
      <input type="hidden" name="sku" value={sku || ""} />
    </div>
  )
}

export { ContactProductForm, ContactProductFormHead }
