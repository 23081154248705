import React, { useEffect, useState, useContext } from "react"
import Layout from "@layouts/layout"
import { graphql, navigate } from "gatsby"
import CartContext from "@context/cartContext"
import { Container, Block, Totals, Seo } from "@components"
import { isBrowser } from "@context/themeContext"
import { gtmPush } from "../helpers/gtmHelpers"

const defaultCheckout = [
  {
    label: "Pay now through secure checkout",
    type: "checkout"
  },
  {
    label: "Email me a quote",
    type: "quote"
  }
]

const Cart = ({ data: { datoCmsSite } }) => {
  const { cart, cartLoading, getSubtotal } = useContext(CartContext)

  useEffect(() => {
    const ecommerceItem = []
    if (cart.size !== 0) {
      cart.forEach(item => {
        ecommerceItem.push({
          item_id: item.sku,
          item_name: item.name,
          affiliation: "Spa World",
          item_brand: item.brand,
          item_category: item.category,
          item_variant: item.variantDetails?.name || "none",
          price: item.prices?.price,
          currency: item.prices?.currency,
          quantity: item.qty
        })
      })

      gtmPush({ ecommerce: null })
      gtmPush({
        event: "view_cart",
        ecommerce: {
          currency: ecommerceItem[0].currency,
          value: getSubtotal(),
          items: ecommerceItem
        }
      })
    }
  }, [])

  return (
    <CartContext.Consumer>
      {({ getCartLinkProps }) => {
        if (isBrowser) {
          const cartLink = getCartLinkProps()
          cartLink?.to.indexOf("/cart/") === -1 && navigate(cartLink.to)
        }

        return (
          <Layout minimal>
            <Seo title={`Confirm your order`} />
            <Block
              padding={"double"}
              heading={`Confirm your order`}
              type={"section"}
              id={"upgrades"}
              background={"blue-swish"}
              gutters
            >
              <Container maxWidth="inner">
                <Totals elevated checkoutOptions={defaultCheckout} />
              </Container>
            </Block>
          </Layout>
        )
      }}
    </CartContext.Consumer>
  )
}

export default Cart

export const query = graphql`
  query CartQuery {
    datoCmsSite {
      name
    }
  }
`
