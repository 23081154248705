import React, { useContext, useState } from "react"
// import PhoneSvg from "@svgs/phone.svg"
import style from "./header-control.mod.scss"
import { Button, Logo, MenuIcon, Cart, Search } from "@components"
import FoneDynamicsContext from "@context/foneDynamicsContext"
import { TransitionLink } from "@helpers/pageTransitions"
import { Dialog, DialogActions } from "@mui/material"

const HeaderControl = ({
  minimal,
  toggleSidebar,
  isSidebarOpen,
  isSearchOpen,
  setSearchOpen
}) => {
  const { num } = useContext(FoneDynamicsContext)
  const classes = [style.control]
  const siteLanguage = process.env.GATSBY_LANGUAGE
  const [isModalOpen, setIsModalOpen] = useState(false)

  minimal && classes.push(`${style.control}--minimal`)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <header className={classes.join(" ")}>
      <div className={style.control__container}>
        <div className={style.control__wrapper}>
          <div className={style.control__logo}>
            <Logo />
          </div>
          <div className={style.control__contentswrapper}>
            {siteLanguage === "en-AU" && (
              <>
                <div className={style.control__text}>
                  <TransitionLink to="/">Spa Pools & More</TransitionLink>
                </div>
                <div className={style.control__line}></div>
                <div className={style.control__contents}>
                  <TransitionLink
                    className={style.control__link}
                    to="https://shop.spaworld.com.au/"
                  >
                    Parts & Accessories
                  </TransitionLink>
                </div>
              </>
            )}
          </div>

          <div className={style.control__controls}>
            {!minimal && (
              <>
                <div className={style.control__search}>
                  <Search
                    isSearchOpen={isSearchOpen}
                    setSearchOpen={setSearchOpen}
                  />
                </div>
                <div className={style.control__phone}>
                  <Button
                    short
                    type={"button"}
                    unstyled
                    onClick={handleOpenModal}
                    noTarget
                    gaTarget={"header-call-button"}
                  >
                    <PhoneSvg svgId={"phone-1"} />
                  </Button>
                </div>
                <div className={style.control__phone_mobile}>
                  <Button
                    to={`tel:${num}`}
                    unstyled
                    noTarget
                    gaTarget={"header-call-button"}
                  >
                    <PhoneSvg svgId={"phone-2"} />
                  </Button>
                </div>
              </>
            )}
            <div className={style.control__cart}>
              <Cart linkMode={true} />
            </div>
            {!minimal && (
              <div className={style.control__hamburger}>
                <MenuIcon toggle={toggleSidebar} isOpen={isSidebarOpen} />
              </div>
            )}
          </div>
        </div>
      </div>

      <Dialog
        className={style.control__modal}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <h3>Phone:</h3>
        <h4>{num}</h4>
        <DialogActions>
          <Button short primary onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </header>
  )
}

export default HeaderControl

const PhoneSvg = ({ svgId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 39.38 41"
  >
    <defs>
      <path
        id={`${svgId}-path`}
        d="M17 1l-4-1h-1l-1 4v1l2 2c-1 3-3 5-6 6l-2-2H4l-4 1v1l1 4h1c8 0 15-7 15-15V1z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        width="39.1"
        height="39.1"
        fill="#005A8B"
        rx="19.6"
        transform="translate(0 1)"
      ></rect>
      <g transform="translate(11 12)">
        <mask id={`${svgId}-mask`} fill="#fff">
          <use xlinkHref={`#${svgId}-path`}></use>
        </mask>
        <g fill="#FFF" mask={`url(#${svgId}-mask)`}>
          <path d="M0 0h17v17H0z"></path>
        </g>
      </g>
    </g>
  </svg>
)
