import React from "react"
import style from "./location.mod.scss"
import { Button, Image } from "@components"
import { Link } from "gatsby"
const Location = ({
  name,
  addressLine1,
  addressLine2,
  city,
  state,
  postcode,
  openHours,
  openHoursText,
  primaryPhoneNumber,
  salesPhoneNumber,
  partsPhoneNumber,
  email,
  hero,
  title,
  path,
  isJacuzzi,
  listing = false,
  partsAndAccessoriesText,
  partsAndAccessoriesUrl,
  isCustom,
  hideAddress,
  customBox,
  customBoxLink,
  customBoxLinkText
}) => {
  const callUsNumber =
    primaryPhoneNumber || salesPhoneNumber || partsPhoneNumber

  const modifiers = []

  listing && modifiers.push(style["location--listing-page"])
  isJacuzzi && modifiers.push(style["location--jacuzzi"])

  return (
    <div className={[style.location, ...modifiers].join(" ")}>
      <div className={style.location__wrapper}>
        {isJacuzzi && listing && (
          <div className={style.location__arc}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 760 446">
              <defs>
                <clipPath id="a">
                  <path
                    fill="#1c1c1c"
                    d="M-8635-3943h760v446h-760z"
                    data-name="Rectangle 37"
                  />
                </clipPath>
              </defs>
              <g
                clip-path="url(#a)"
                data-name="Mask Group 93"
                transform="translate(8635 3943)"
              >
                <g fill="#1c1c1c" data-name="Group 4">
                  <path
                    d="M-8640.8-3946s409.8-10.3 494.8-19.6c79.7-3.5 103.6-7.3 207.4 6.4q30.9 98.2 30.9 228.8c0 137-34 238.3-34 238.3l-699-6.2z"
                    data-name="Rectangle Copy 4"
                  />
                  <path
                    d="M-8636.3-3940.2c65.9-12.4 419.4-19.6 504.6-26.4 75.7-3.3 103.8-5.1 213.2 9.2 20.9 64.8 32.4 141.1 32.4 226.8 0 137-35.7 238.5-35.7 238.5h-714.5s-33.8-370.3 0-448z"
                    data-name="Rectangle Copy 6"
                    opacity=".8"
                  />
                </g>
              </g>
            </svg>
          </div>
        )}
        <div className={style.location__title}>
          {listing && (
            <Link to={path} title={title}>
              <h3>{title}</h3>
            </Link>
          )}
          <div className={style.location__main}>
            <div className={style.location__contact}>
              {!listing && (
                <h3 className={style.location__heading}>Contact Details</h3>
              )}
              <div className={style.location__address}>
                <h4>{name}</h4>
                {!hideAddress && (
                  <address>
                    {addressLine1 && <div>{addressLine1}</div>}
                    {addressLine2 && <div>{addressLine2}</div>}
                    <div>
                      {city}, {state !== "NZ" && state} {postcode}
                    </div>
                  </address>
                )}
              </div>

              {salesPhoneNumber && (
                <div className={style.location__sales}>
                  <h4>Sales & Customer Service</h4>
                  <a
                    data-ga-target={"showroom-sales-call-button"}
                    href={`tel:${salesPhoneNumber}`}
                  >
                    {salesPhoneNumber}
                  </a>
                </div>
              )}
              {partsPhoneNumber && (
                <div className={style.location__parts}>
                  <h4>Parts</h4>
                  <a
                    data-ga-target={"showroom-parts-call-button"}
                    href={`tel:${partsPhoneNumber}`}
                  >
                    {partsPhoneNumber}
                  </a>
                </div>
              )}
            </div>
            {openHours && (
              <div className={style.location__opening}>
                {!listing && (
                  <h3 className={style.location__heading}>Opening Hours</h3>
                )}
                <div className={style.location__hours}>
                  {listing && <h4>Opening Hours</h4>}
                  {openHours.map(item => {
                    return (
                      <p key={`open-hours-${item.dayRange}`}>
                        <span>{item.dayRange.replace(/-/gi, " - ")}</span>{" "}
                        {item.opens} - {item.closes}
                      </p>
                    )
                  })}
                  {openHoursText && (
                    <p className={style.location__notes}>{openHoursText}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.location__buttons}>
          {email && (
            <Button
              noTarget
              to={`mailto:${email}`}
              fullWidth
              gaTarget={"showroom-email-button"}
            >
              Send us an email
            </Button>
          )}
          {!hideAddress && (
            <Button
              gaTarget={"showroom-directions-button"}
              to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
                addressLine1
              )}+${city}+${state}+${postcode}`}
              fullWidth
            >
              Get Directions
            </Button>
          )}
          {!listing && callUsNumber && (
            <Button
              gaTarget={"showroom-call-button"}
              noTarget
              to={`tel:${callUsNumber}`}
              fullWidth
            >
              Call us today
            </Button>
          )}
          {listing && (
            <Button to={path} gaTarget={"showroom-detail-button"} fullWidth>
              Find out more
            </Button>
          )}
        </div>
        {partsAndAccessoriesText && (
          <>
            <div className={style.location__line}></div>
            <div className={style.location__parts}>
              <p> {partsAndAccessoriesText}</p>
              {partsAndAccessoriesUrl && (
                <div className={style.location__buttons}>
                  <Button
                    white
                    secondary
                    target="_blank"
                    to={partsAndAccessoriesUrl}
                    gaTarget={"showroom-accessories-button"}
                  >
                    Shop Parts & Accessories
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
        {customBox && (
          <>
            <div className={style.location__line}></div>
            <div className={style.location__parts}>
              <p> {customBox}</p>
              {customBoxLink && (
                <div className={style.location__buttons}>
                  <Button
                    white
                    secondary
                    to={customBoxLink}
                    gaTarget={"showroom-custom-button"}
                    isNewTab
                  >
                    {customBoxLinkText || "View Products"}
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {listing && <Image image={hero} />}
    </div>
  )
}

export default Location
