import React, { useState } from "react"
import style from "./toggle.mod.scss"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

const content = {
  open: {
    display: "block",
    opacity: 1,
    y: 0
  },
  closed: {
    display: "none",
    y: "-20%",
    opacity: 0,
    transition: {
      x: { type: "spring", stiffness: 100, duration: 0.3 },
      display: {
        delay: 0.3
      }
    }
  }
}

const Toggle = ({ title, children, openInitially = false, modifier }) => {
  const [isOpen, setOpen] = useState(openInitially)
  return (
    <motion.div
      data-is-open={isOpen}
      className={[style.toggle, modifier].join(" ")}
      layout
    >
      <button className={style.toggle__head} onClick={() => setOpen(!isOpen)}>
        {title}
        <span className={style.toggle__arrow} />
      </button>
      <motion.div
        className={style.toggle__content}
        children={children}
        initial={"closed"}
        variants={content}
        animate={isOpen ? "open" : "closed"}
      />
    </motion.div>
  )
}

export default Toggle

Toggle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  openInitially: PropTypes.bool,
  modifier: PropTypes.string
}
