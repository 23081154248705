import React from "react"
import { graphql, StaticQuery } from "gatsby"
import style from "./authored-by.mod.scss"
import Container from "../elements/container"
import Block from "./block"
import { GatsbyImage } from "gatsby-plugin-image"

const AuthoredBy = ({ authoredByText, author }) => {
  return (
    <div className={style.author}>
      {author && (
        <Container maxWidth={"inner"} gutters={"small"}>
          <Block gutters padding={"both"}>
            <div className={style.author__wrapper}>
              {author.image && (
                <div className={style.author__image}>
                  <GatsbyImage image={author.image.gatsbyImageData} />
                </div>
              )}
              <div className={style.author__name}>
                {`${authoredByText} ${author.name}`}
              </div>
            </div>
          </Block>
        </Container>
      )}
    </div>
  )
}
export default AuthoredBy

export const query = graphql`
  fragment AuthoredBy on DatoCmsBlogPost {
    authoredBy {
      authoredByText
      author {
        name
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "100"
              w: "100"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
        }
      }
    }
  }
`
