import React, { useState } from "react"
import style from "./checkbox.mod.scss"
import { FaRegCircle } from "@react-icons/all-files/fa/FaRegCircle"
import { FaCircle } from "@react-icons/all-files/fa/FaCircle"

const Checkbox = React.forwardRef(
  ({ label, name, placeholder, required, setValue, inverted = false }, ref) => {
    const [isChecked, setCheck] = useState(false)

    const inputConfig = {
      id: `checkbox--${name}`,
      name,
      ref,
      placeholder,
      required
    }

    return (
      <div className={style.checkbox}>
        <input
          name={name}
          type="checkbox"
          checked={isChecked}
          {...inputConfig}
          onChange={e => {
            setCheck(!isChecked)
            if (setValue) {
              setValue(name, e.target.checked)
            }
          }}
          value={isChecked}
        />
        {label && (
          <label htmlFor={inputConfig.id}>
            {isChecked ? <FaCircle /> : <FaRegCircle />}
            {label}
            {required && <span aria-label="required">*</span>}
          </label>
        )}
      </div>
    )
  }
)

export default Checkbox
