import React from "react"
import style from "./breadcrumbs.mod.scss"
import { Link } from "gatsby"
import { Superscript } from "@components"

const Breadcrumbs = ({ breadcrumbs }) => (
  <nav className={style.breadcrumbs} aria-label="Breadcrumb">
    {breadcrumbs && (
      <>
        <ul className={style.breadcrumbs__list}>
          {breadcrumbs.map(({ path, title }, i) => (
            <li key={`${i}-${title}`} className={style.breadcrumbs__item}>
              <Link to={path} children={<Superscript children={title} />} />
            </li>
          ))}
        </ul>
        <hr />
      </>
    )}
  </nav>
)

export default Breadcrumbs
