import React from "react"
import { graphql } from "gatsby"
import style from "./table.mod.scss"
import PropTypes from "prop-types"

const Table = ({
  head = [],
  body = [],
  foot = [],
  firstColumnHeader = false,
  modifier = "",
  ...props
}) => (
  <div
    className={[style.table, `${style.table}--${modifier}`].join(" ")}
    {...props}
  >
    <div className={style.table__inner}>
      <table>
        {head.length > 0 && (
          <thead>
            {head.map((row, i) => (
              <tr key={`head-row-${i}`}>
                {row.map((label, ii) => (
                  <th
                    className={style.table__head}
                    key={`head-row-${i}-cell-${ii}`}
                  >
                    {label}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        {body.length > 0 && (
          <tbody>
            {body.map((row, i) => (
              <tr key={`body-row-${i}`}>
                {row.map((cell, ii) =>
                  firstColumnHeader && ii === 0 ? (
                    <th key={`body-row-${i}-cell-${ii}`} scope="row">
                      {cell}
                    </th>
                  ) : (
                    <td key={`body-row-${i}-cell-${ii}`}>{cell}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        )}

        {foot.length > 0 && (
          <tfoot>
            {foot.map((row, i) => (
              <tr key={`foot-row-${i}}`}>
                {row.map((label, ii) => (
                  <td
                    className={style.table__foot}
                    key={`foot-row-${i}-cell-${ii}`}
                    children={label}
                  />
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  </div>
)

export default Table

Table.propTypes = {
  head: PropTypes.array,
  body: PropTypes.array,
  foot: PropTypes.array,
  firstColumnHeader: PropTypes.bool,
  modifier: PropTypes.string
}

export const query = graphql`
  fragment Table on DatoCmsTable {
    id
    firstColumnHeader
    padding
    model {
      apiKey
    }
    linkHash
    tableHead {
      id
      model {
        apiKey
      }
      tableCells {
        id
        content
        model {
          apiKey
        }
      }
    }
    tableBody {
      id
      model {
        apiKey
      }
      tableCells {
        id
        content
        model {
          apiKey
        }
      }
    }
  }
`
