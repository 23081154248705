import React, { useRef } from "react"
import cn from "classnames"
import style from "./reviews.mod.scss"
import { ReviewBadge, Stars } from "../../components"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/swiper-bundle.css"
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft"
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight"
import { JsonLd } from "react-schemaorg"

const Reviews = ({
  rating,
  reviews,
  location,
  fullBlock = true,
  title = null
}) => {
  const classes = cn(style.reviews)
  const swiperRef = useRef(null)

  return (
    <div className={classes} id="reviews">
      <h2>{title || "Showroom Reviews"}</h2>
      {fullBlock && (
        <div className={style.reviews__badge}>
          <ReviewBadge rating={rating} large />
        </div>
      )}
      <div className={style.reviews__line} />
      <div className={style.reviews__slider}>
        <div className={style.reviews__controls}>
          <CarouselPrev />
          <CarouselNext />
        </div>
        <Swiper
          ref={swiperRef}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next"
          }}
          modules={[Navigation]}
        >
          {reviews?.nodes
            .filter(slide => slide.rating)
            .map(slide => (
              <SwiperSlide key={slide.id}>
                <Slide {...slide} location={location} fullBlock={fullBlock} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Reviews

const Slide = ({
  reviewer,
  comments,
  id,
  rating,
  sourceType,
  reviewDate,
  businessName,
  location,
  fullBlock
}) => {
  const classes = cn(style.slide)

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Review",
    reviewBody: comments,
    itemReviewed: {
      "@type": "Store",
      name: businessName,
      telephone:
        location.primaryPhoneNumber ||
        location.salesPhoneNumber ||
        location.partsPhoneNumber,
      address: fullBlock
        ? {
            "@type": "PostalAddress",
            streetAddress: `${location.addressLine1} ${location.addressLine2}`,
            addressLocality: location.city,
            addressRegion: location.state,
            postalCode: location.postcode,
            addressCountry: process.env.GATSBY_LANGUAGE.replace("en-", "")
          }
        : ""
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: rating,
      worstRating: 0,
      bestRating: 5
    },
    author: {
      "@type": "Person",
      name: reviewer.name
    }
  }

  return (
    <div className={classes}>
      <div className={style.slide__heading}>
        <h4>{reviewer.name}</h4>
        <p className={style.slide__bullet}>&bull;</p>
        <p> On {sourceType}</p>
      </div>
      <div className={style.slide__rating}>
        <Stars rating={rating.toFixed(1)} />
        <p className={style.slide__bullet}>&bull;</p>
        <p className={style.slide__date}>{reviewDate}</p>
      </div>
      <p className={style.slide__comments}>{comments}</p>
      <JsonLd item={schema} />
    </div>
  )
}

const CarouselPrev = () => (
  <button aria-label="Previous" className="swiper-button-prev">
    <MdKeyboardArrowLeft />
  </button>
)

const CarouselNext = () => (
  <button aria-label="Next" className="swiper-button-next">
    <MdKeyboardArrowRight />
  </button>
)
