import React, { useRef, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useForm } from "react-hook-form"
import { Input, Select, Textarea, Checkbox, Button } from "@components"
import { ContactWarrantyForm } from "./warranty-form"
import { ContactProductForm } from "./product-form"
import { ContactQuoteForm } from "./quote-form"
import ContactDisclaimer from "./contact-disclaimer"
import ContactWrapper from "./contact-wrapper"
import ZapierForm from "react-zapier-form"
import { gtmPush } from "@helpers/gtmHelpers"
import { useCart } from "@helpers/useCart"
import smoothscroll from "smoothscroll-polyfill"
import axios from "axios"

const Contact = props => {
  const { location, formType = "contact", name = "", buttonId } = props
  const isWetTestEnquiry = formType === "wet-test"
  const isWarrantyEnquiry = formType === "warranty"
  const isQuote = formType === "quote"
  const isClearanceEnquiry = formType === "product"
  const isContactForm = formType === "contact"
  const isSolutionsForm = formType === "solutions"
  const isServicesForm = formType === "services"
  const isDownloadForm = formType === "download"
  const isVirtualConsultationForm = formType === "virtual-consultation"
  const isFinanceEnquiry = formType === "finance-enquiry"
  let buttonText = "Send Message"
  if (isWarrantyEnquiry) buttonText = "Submit"
  if (isDownloadForm) buttonText = "Send"

  const isClearance = location?.state?.isClearance

  const data = useStaticQuery(graphql`
    {
      contentYaml {
        shippingOptions
      }
    }
  `)
  const { state = {} } = location
  const cart = useCart()

  let highestPrice = 0

  !cart.loading &&
    cart.items.forEach(item => {
      if (item.prices.price > highestPrice) {
        highestPrice = item.prices.price
      }
    })

  const inputs = {
    type: useRef(null),
    productName: useRef(null),
    productCategory: useRef(null),
    productBrand: useRef(null),
    subject: useRef(null),
    optOut: useRef(null),
    formName: useRef(null)
  }

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  // Get cart product values to pass through to Zapier
  const productsInCart = []
  const brandsInCart = []
  const categoriesInCart = []

  for (const [key, value] of cart.items) {
    productsInCart.push(value?.name)

    value?.brand && brandsInCart.push(value.brand)

    value?.category && categoriesInCart.push(value.category)
  }

  const postSend = event => {
    const gtmData = { event: "enquirySent", enquiryType: formType }

    for (var name in inputs) {
      if (inputs.hasOwnProperty(name)) {
        const input = inputs[name]
        const isCheckbox =
          input.current && input.current.getAttribute("type") === "checkbox"

        if (isCheckbox) {
          gtmData[name] = input?.current.checked
        } else {
          gtmData[name] = input?.current?.value || ""
        }
      }
    }

    gtmPush(gtmData)

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const formProps = { error: "", success, loading }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()
  const onSubmit = async data => {
    setLoading(true)

    data.formName = isClearance ? "Clearance" : name
    if (["finance-enquiry", "quote"].includes(formType)) {
      data.productName =
        state?.productName || state?.heading || productsInCart.join(", ")
    }

    const response = await axios.post("/.netlify/functions/contactForm", data)

    // handle ticket attachment for Zoho Desk
    if (formType === "warranty") {
      const fileElement = document.getElementById("input--attachment")
      if (fileElement && fileElement.files.length) {
        let file = fileElement.files[0]
        let formData = new FormData()
        formData.set("file", file)
        formData.set("Email", "admin")
        formData.set("Password", "AXhtEkflIO0TFBKN")
        await axios.post(
          `https://spaworld-middleware.hcdev.nz/api/ticket-attachment/${response.data.id}`,
          formData
        )
      }
    }
    postSend()

    setLoading(false)
    setSuccess(true)
  }

  const formTypeOptions = [
    {
      value: "Sales",
      text: "Sales"
    },
    {
      value: "Delivery",
      text: "Delivery"
    },
    {
      value: "Warranty",
      text: "Warranty"
    },
    {
      value: "Parts",
      text: "Parts"
    },
    {
      value: "Certification Service",
      text: "Certification Service"
    },
    {
      value: "Book Platinum Handover",
      text: "Book Platinum Handover"
    },
    {
      value: "General Enquiry",
      text: "General Enquiry"
    }
  ]
  if (process.env.GATSBY_LANGUAGE === "en-AU") {
    formTypeOptions.push({
      value: "After Sale Service",
      text: "After Sale Service"
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContactWrapper formProps={formProps} {...props}>
        <>
          {!isWarrantyEnquiry && !isDownloadForm && !isVirtualConsultationForm && (
            <Select
              label={"How would you like us to contact you?"}
              name={"preference"}
              {...register("preference")}
              options={[
                {
                  value: "Email",
                  text: "Email"
                },
                {
                  value: "SMS",
                  text: "SMS"
                },
                {
                  value: "Whatsapp",
                  text: "Whatsapp"
                },
                {
                  value: "Phone Mornings",
                  text: "Phone - Mornings"
                },
                {
                  value: "Phone Daytime",
                  text: "Phone - Daytime"
                },
                {
                  value: "Phone Evening",
                  text: "Phone - Evening"
                }
              ]}
              required
            />
          )}
          <Input
            label={"Your First Name"}
            name="firstName"
            {...register("firstName")}
            required
            placeholder={"Enter your first name"}
            fullWidth
          />
          <Input
            label={"Your Last Name"}
            name="lastName"
            {...register("lastName")}
            required
            placeholder={"Enter your last name"}
            fullWidth
          />
          <Input
            label={"Your Email"}
            type="email"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            name="email"
            {...register("email")}
            required
            placeholder={"Enter your email"}
            fullWidth
          />
          {isWarrantyEnquiry && <ContactWarrantyForm register={register} />}
          {(isClearanceEnquiry || isWetTestEnquiry) && (
            <ContactProductForm register={register} />
          )}

          {(isQuote || isFinanceEnquiry) && (
            <ContactQuoteForm
              shippingOptions={data.contentYaml.shippingOptions}
              isFinanceEnquiry={isFinanceEnquiry}
              location={location}
              register={register}
            />
          )}

          {(isContactForm ||
            isSolutionsForm ||
            isServicesForm ||
            isFinanceEnquiry ||
            isVirtualConsultationForm) && (
            <>
              {!isFinanceEnquiry && (
                <>
                  <Input
                    label={"Your Phone Number"}
                    type="tel"
                    name="phone"
                    {...register("phone")}
                    required
                    placeholder={"(##) ### #### or ### ### ####"}
                    fullWidth
                  />
                  <Input
                    label={"Your Postcode"}
                    name="postcode"
                    {...register("postcode")}
                    required
                    placeholder={"Enter your postcode"}
                    fullWidth
                  />
                </>
              )}
              {!isSolutionsForm &&
                !isServicesForm &&
                !isDownloadForm &&
                !isFinanceEnquiry &&
                !isVirtualConsultationForm && (
                  <Select
                    label={
                      "So that we better answer your request, how can we help you?"
                    }
                    required
                    ref={inputs.subject}
                    name="howCanWeHelp"
                    {...register("howCanWeHelp")}
                    options={formTypeOptions}
                  />
                )}
              {(isVirtualConsultationForm || isFinanceEnquiry) && (
                <Select
                  label={"Product Interest?"}
                  required
                  ref={inputs.subject}
                  name="productInterest"
                  {...register("productInterest")}
                  options={[
                    {
                      value: "Spa",
                      text: "Spa"
                    },
                    {
                      value: "Swim Spa",
                      text: "Swim Spa"
                    },
                    {
                      value: "Sauna",
                      text: "Sauna"
                    },
                    {
                      value: "Umbrella",
                      text: "Umbrella"
                    },
                    {
                      value: "Outdoor Heating",
                      text: "Outdoor Heating"
                    },
                    {
                      value: "Pergola",
                      text: "Pergola"
                    },
                    {
                      value: "Parts & Service",
                      text: "Parts & Service"
                    },
                    {
                      value: "Delivery",
                      text: "Delivery"
                    },
                    {
                      value: "Warranty",
                      text: "Warranty"
                    }
                  ]}
                />
              )}
            </>
          )}

          {isServicesForm && (
            <>
              <Select
                label={
                  "So that we better answer your request, how can we help you?"
                }
                required
                ref={inputs.subject}
                name="howCanWeHelp"
                {...register("howCanWeHelp")}
                options={[
                  {
                    value: "Maintenance Service",
                    text: "Maintenance Service"
                  },
                  {
                    value: "Warranty Issue",
                    text: "Warranty Issue"
                  },
                  {
                    value: "Non-Warranty Issue",
                    text: "Non-Warranty Issue"
                  }
                ]}
              />
              <Input
                label={"Spa Model"}
                name="spaModel"
                {...register("spaModel")}
                placeholder={"Enter Spa Model"}
                fullWidth
              />
            </>
          )}

          {!isWarrantyEnquiry &&
            !isSolutionsForm &&
            !isDownloadForm &&
            !isVirtualConsultationForm && (
              <Input
                label={"Message"}
                name="message"
                {...register("message")}
                required
                placeholder={"Add your message here"}
                fullWidth
                multiline
              />
            )}

          {(isSolutionsForm ||
            isVirtualConsultationForm ||
            isServicesForm ||
            isDownloadForm) && <ContactDisclaimer />}

          {!isWarrantyEnquiry && (
            <Checkbox
              ref={inputs.optOut}
              label={
                "I don’t want to receive helpful emails or SMS messages about products, special offers or services."
              }
              name="optout"
              {...register("optout")}
              setValue={setValue}
              inverted
            />
          )}

          <Button type="submit" fullWidthMobile id={buttonId}>
            {buttonText}
          </Button>
        </>
      </ContactWrapper>
      {/* Misc Tracking fields */}
      <input
        type="hidden"
        name="type"
        ref={inputs.type}
        value={formType}
        {...register("type")}
      />
      <input
        type="hidden"
        name="formName"
        ref={inputs.formName}
        value={isClearance ? "Clearance" : name}
        {...register("formName")}
      />
      <input
        type="hidden"
        name="productName"
        ref={inputs.productName}
        value={
          state?.productName ||
          state?.heading ||
          productsInCart.join(", ") ||
          ""
        }
        {...register("productName")}
      />
      <input
        type="hidden"
        name="productCategory"
        ref={inputs.productCategory}
        value={state?.productCategory || categoriesInCart.join(" ") || ""}
      />
      <input
        type="hidden"
        name="productBrand"
        ref={inputs.productBrand}
        value={
          state?.productBrand ||
          state?.brand?.name ||
          brandsInCart.join(" ") ||
          ""
        }
      />
    </form>
  )
}

export default Contact

export const query = graphql`
  fragment Contact on DatoCmsContactForm {
    id
    model {
      apiKey
    }
    linkHash
    __typename
    name
    headingNode {
      childMarkdownRemark {
        html
      }
    }
    formDocument {
      alt
      url
    }
    formType
    webhook
    buttonId
    successMessage
    failureMessage
    padding
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "658", fit: "crop", w: "1170", q: 60, auto: "format" }
      )
      alt
    }
  }
`
