import React from "react"
import PropTypes from "prop-types"
import style from "./block.mod.scss"
import { Superscript } from "@components"

const Block = ({
  children,
  type,
  id,
  padding = "None",
  background = "None",
  maxWidth = "None",
  gutters = false,
  guttersReverse = false,
  heading = "",
  lead = "",
  overflow = "Unset",
  tight,
  padIntro,
  linkHash,
  isH1 = false,
  darkText = false
}) => {
  const _padding = padding && padding !== null ? padding : "None"
  const modifiers = [
    style[
      "block--padding-" +
        _padding
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ],
    style[
      "block--bg-" +
        background
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ],
    style[`block--max-width-${maxWidth.toLowerCase().replace(/ /gi, "-")}`],
    style[`block--overflow-${overflow.toLowerCase().replace(/ /gi, "-")}`]
  ]

  gutters && modifiers.push(style["block--gutters"])
  guttersReverse && modifiers.push(style["block--gutters-reverse"])
  tight && modifiers.push(style["block--tight"])
  padIntro && modifiers.push(style["block--pad-intro"])
  darkText && modifiers.push(style["block--dark-text"])

  const config = {
    className: [style.block, ...modifiers].join(" "),
    id: linkHash || id,
    children: (
      <>
        {(heading || lead) && (
          <div className={style.block__intro}>
            {heading &&
              (isH1 ? (
                <h1
                  className={style.block__heading_one}
                  children={<Superscript children={heading} />}
                />
              ) : (
                <h2
                  className={style.block__heading}
                  children={<Superscript children={heading} />}
                />
              ))}
            {lead && (
              <div
                className={style.block__lead}
                children={<Superscript children={lead} />}
              />
            )}
          </div>
        )}
        {children}
      </>
    )
  }

  switch (type) {
    case "section":
      return <section {...config} />
    default:
      return <div {...config} />
  }
}

Block.propTypes = {
  children: PropTypes.node.isRequired
}

export default Block
