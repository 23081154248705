import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
// TODO: Add page transitions
/* // Page transitions not performant - to be reinstated later
import { TransitionState } from 'gatsby-plugin-transition-link';
import Link from 'gatsby-plugin-transition-link';
*/
// import { motion } from 'framer-motion';
// import Layout from '@layouts/layout';

// const PageTransition = ({ children }) => {
//     const pageVariants = {
//         initial: {
//             opacity: 0
//         },
//         in: {
//             opacity: 1,
//             transition: {
//                 when: 'beforeChildren',
//                 staggerChildren: 0.1
//             }
//         },
//         out: {
//             opacity: 0,
//             transition: {
//                 when: 'afterChildren'
//             }
//         }
//     };
//     /* // Page transitions not performant - to be reinstated later
//     return (
//         <TransitionState>
//             {({ mount, transitionStatus }) => {
//                 return (
//                     <motion.div
//                         initial="initial"
//                         style={{ backgroundColor: 'white' }}
//                         animate={mount ? 'in' : 'out'}
//                         variants={pageVariants}
//                     >
//                         {children}
//                     </motion.div>
//                 );
//             }}
//         </TransitionState>
//     );
//     */
//     return (
//         <Layout>
//             <motion.div
//                 initial="initial"
//                 animate={'in'}
//                 variants={pageVariants}
//             >
//                 {children}
//             </motion.div>
//         </Layout>
//     );
// };

const TransitionLink = props => {
  const config = {
    ...props
    /* // Page transitions not performant - to be reinstated later
        exit: {
            length: 0.5
        },
        entry: {
            delay: 0.2
        }
        */
  }

  if (props.gaTarget) config["data-ga-target"] = props.gaTarget

  if (!props.to.endsWith("/")) config["to"] = `${props.to}/`
  if (props.hash) {
    config.to += "#" + props.hash
  }

  return <Link {...config} />
}

export { TransitionLink }

export const query = graphql`
  fragment Link on DatoCmsLink {
    id
    displayText
    url
    newWindow
    link {
      ... on DatoCmsPage {
        id
        path
      }
      ... on DatoCmsProductLandingPage {
        id
        path
      }
      ... on DatoCmsProduct {
        id
        path
      }
      ... on DatoCmsCollection {
        id
        path
      }
      ... on DatoCmsBlogPost {
        id
        path
      }
      ... on DatoCmsLocation {
        id
        path
      }
      ... on DatoCmsShowroomList {
        id
        path
      }
      ... on DatoCmsLandingPage {
        id
        path
      }
      ... on DatoCmsArea {
        id
        path
      }
    }
    locationHash
  }
`
