import React, { useState, useContext, useEffect } from "react"
import ProductContext from "@context/productContext"
import { knownSpecifics, getOptionsMap } from "@helpers/variants"
import { Block, Cards, Card, Pill } from "@components"

const ProductSpecificsGrid = ({
  specificName,
  cards = [],
  defaultVariant,
  ...block
}) => {
  const {
    productLoading,
    options,
    setOption,
    validOptions = [],
    currentModel,
    currentPrice,
    getVariantComparison
  } = useContext(ProductContext)

  const [currentVariant, setCurrentVariant] = useState(defaultVariant)
  // Update table state when product comes online.
  useEffect(() => {
    if (!productLoading) {
      setCurrentVariant(currentModel)
    }
  }, [productLoading, currentModel])

  const currentValues = getOptionsMap(currentVariant.specifics)
  const nameIsValid = options?.size > 0 ? options.has(specificName) : true
  const currentOption = currentValues.get(specificName)
  const specific =
    nameIsValid && knownSpecifics.find(item => item.name === specificName)
  const validValues = (validOptions && validOptions.get(specificName)) || []

  // Provide helpful error
  if (!nameIsValid) {
    console.log(
      `Specific ${specificName} is not valid. Possible options are ${[
        ...options.keys()
      ].join(", ")}`
    )

    return null
  }

  const displayName = specificName === "Model Type" ? "Model" : specificName

  return (
    <Block type={"section"} id={specific.slug}>
      <Cards
        key={block.id}
        {...block}
        moduleStyle="inset"
        orientation="horizontal"
        columns="2"
        size="large"
      >
        {cards.map(card => {
          const currentValue = card.specificValue

          const disabled =
            productLoading || validValues.indexOf(currentValue) < 0

          const variantPrice = getVariantComparison(specificName, currentValue)
            ?.comparePrice?.price

          const valueIsValid = validValues.find(value => value === currentValue)

          const isSelected = currentOption === currentValue

          const priceDifference =
            !productLoading && variantPrice
              ? variantPrice - currentPrice?.price
              : null

          const labeledDifference =
            Math.sign(priceDifference) === -1
              ? `Remove <span>$${priceDifference * -1}</span>`
              : `Add <span>$${priceDifference}</span>`

          return (
            <Card
              heading={card.name}
              key={`product-model-card-${card.id}`}
              image={card.image}
              caption={card.descriptionNode.childMarkdownRemark.html}
              salesFeatures={card.salesFeatures}
              value={
                priceDifference && priceDifference !== 0
                  ? labeledDifference
                  : false
              }
              defaultModel={card.defaultModel}
              elevated
              disabled={false}
              selected={isSelected}
              pill={
                nameIsValid &&
                valueIsValid && (
                  <Pill
                    secondary
                    elevated
                    name={specificName}
                    value={currentValue}
                    isChecked={isSelected}
                    disabled={disabled}
                    type="radio"
                    onChange={({ target }) => {
                      setOption(target.name, target.value)
                    }}
                  >
                    {isSelected ? `Selected ${displayName}` : `Select`}
                  </Pill>
                )
              }
              model
            />
          )
        })}
      </Cards>
    </Block>
  )
}

export default ProductSpecificsGrid
