import React from "react"
import Img from "gatsby-image"
import style from "./featured-card.mod.scss"
import { Button } from "../components"
import UndoLogo from "./undo-logo"

const toSuperscript = (str = "") => {
  return {
    __html:
      str !== null
        ? str.replace(/®/gi, "<sup>®</sup>").replace(/™/gi, "<sup>™</sup>")
        : ""
  }
}

const JacuzziFeaturedCard = ({
  id,
  heading = "",
  caption = "",
  linkTo,
  moduleStyle = "",
  image,
  link,
  links = [],
  images = []
}) => {
  if (linkTo) links.push(linkTo)
  if (link) links.push(link)
  if (image) images.push(image)

  const isGallery = images.length > 1
  const isMultiLinks = links.length > 1
  const modifiers = [
    style[
      "jacuzzi-featured-card--" + moduleStyle.toLowerCase().replace(/ /gi, "-")
    ],
    isGallery && style["jacuzzi-featured-card--carousel"],
    isMultiLinks && style["jacuzzi-featured-card--multiLinks"]
  ]

  // const params = {
  //     pagination: {
  //         el: '.swiper-pagination',
  //         type: 'fraction'
  //     },
  //     navigation: {
  //         nextEl: '.swiper-button-next',
  //         prevEl: '.swiper-button-prev'
  //     },
  //     renderPrevButton: () => <Prev />,
  //     renderNextButton: () => <Next />
  // };

  const Arch = () => (
    <svg
      className={style[`jacuzzi-featured-card__svg`]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1873 984"
    >
      <defs />
      <defs>
        <linearGradient
          id={`jacuzzi-featured-card__svg-${id}`}
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={style["secondar-color"]} />
          <stop offset="100%" stopColor={style["primary-color"]} />
        </linearGradient>
      </defs>
      <path
        opacity=".6"
        fill={`#1c1c1c`}
        d="M0 0h1763c42 138 64 298 64 482 0 193-24 359-72 502H0V0z"
      />
      <path
        opacity=".89"
        fill={`#1c1c1c`}
        d="M0 0h1804c44 138 68 302 68 485 0 195-25 363-76 508H0V0z"
      />
    </svg>
  )

  return (
    <div className={[style[`jacuzzi-featured-card`], ...modifiers].join(" ")}>
      <div className={style[`jacuzzi-featured-card__image`]}>
        {images.map(({ fluid }, i) => (
          <Img
            key={`jacuzzi-featured-card-${heading}--${i}`}
            fluid={fluid}
            style={{ height: "100%" }}
            alt={image.alt || ""}
          />
        ))}
        <div className={style["jacuzzi-featured-card__undo"]}>
          <UndoLogo />
        </div>
      </div>

      <div className={style[`jacuzzi-featured-card__content-wrap`]}>
        <Arch />
        <div className={style[`jacuzzi-featured-card__content`]}>
          <h2 dangerouslySetInnerHTML={toSuperscript(heading)}></h2>
          <p dangerouslySetInnerHTML={toSuperscript(caption)}></p>
          <div className={style[`jacuzzi-featured-card__links`]}>
            {links.map((link, i) => (
              <Button
                key={`link${i}`}
                to={`${link.link ? link.link.path : ""}${
                  link.locationHash ? "#" + link.locationHash : ""
                }`}
                url={`${link.url}`}
                newWindow={link.newWindow}
              >
                {link.displayText}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JacuzziFeaturedCard
