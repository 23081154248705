import React, { useState, Fragment, useContext } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import style from "./totals.mod.scss"
import CartContext from "@context/cartContext"
import { CurrencyFormatter } from "@context/productContext"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Button, Radio } from "@components"

// TODO: Complete routing for checkout options
const defaultCheckout = [
  {
    label: "Email me a quote",
    type: "quote"
  }
]

const Totals = ({
  checkoutOptions = defaultCheckout,
  shippingOptions = []
}) => {
  const [checkoutType, setCheckoutType] = useState(checkoutOptions[0].type)
  const { cart, add, remove, getSubtotal, checkoutURL } = useContext(
    CartContext
  )
  const classes = [style.totals]
  const data = useStaticQuery(graphql`
    {
      contentYaml {
        cart {
          TAX_LABEL
          TAX_RATIO
          disclaimer
        }
      }
    }
  `)
  const { TAX_LABEL, TAX_RATIO, disclaimer } = data.contentYaml.cart
  const proceedHandler = (event, checkoutURL) => {
    event.preventDefault()
    switch (checkoutType) {
      case "checkout":
        window.location = checkoutURL
        break
      case "quote":
        navigate("/contact-us/get-a-quote/")
        break
      case "zip":
      case "parker-lane":
      default:
        navigate(
          checkoutOptions.find(({ type }) => type === checkoutType)?.path ||
            "/contact-us/"
        )
    }
  }

  return (
    <form
      className={classes.join(" ")}
      onSubmit={e => proceedHandler(e, checkoutURL)}
    >
      <TotalsTable
        cart={cart}
        add={add}
        getSubtotal={getSubtotal}
        checkoutType={checkoutType}
        setCheckoutType={setCheckoutType}
        shippingOptions={shippingOptions}
        checkout={checkoutOptions}
        TAX_LABEL={TAX_LABEL}
        TAX_RATIO={TAX_RATIO}
      >
        {[...cart.values()].map(item => (
          <TotalsTableRow
            item={item}
            remove={remove}
            key={`cart-item-${item.sku}`}
          />
        ))}
      </TotalsTable>
      <div className={style.totals__actions}>
        <Button white large secondary to={"/"}>
          Continue Shopping
        </Button>
        <Button type={"submit"} white large secondary>
          Proceed
        </Button>
      </div>

      <div
        className={style.totals__disclaimer}
        dangerouslySetInnerHTML={{ __html: disclaimer }}
      />
    </form>
  )
}

const TotalsTable = ({
  cart,
  getSubtotal,
  checkout,
  TAX_LABEL,
  TAX_RATIO,
  children,
  checkoutType,
  shippingOptions,
  setCheckoutType
}) => {
  const total = getSubtotal()
  const tax = total * TAX_RATIO

  return (
    <table className={style.totals__table}>
      <tfoot>
        <tr>
          <th scope="row">
            <div className={style.totals__title}>{`Subtotal `}</div>
            <span className={style.totals__tax}>
              {`Inc `}
              <CurrencyFormatter value={tax} />
              {` ${TAX_LABEL}`}
            </span>
          </th>
          <td colSpan={2} className={style.totals__subtotal}>
            <CurrencyFormatter value={total} />
          </td>
        </tr>
      </tfoot>
      <tbody className={style.totals__products}>
        <tr>
          <th scope="rowgroup" colSpan="3">
            <h4>Products</h4>
          </th>
        </tr>
        {children.length > 0 ? (
          <Fragment>{children}</Fragment>
        ) : (
          <tr>
            <td colSpan="3">{`Your cart is empty`}</td>
          </tr>
        )}
      </tbody>
      {shippingOptions.length > 0 && (
        <tbody className={style.totals__shipping}>
          <tr>
            <th scope="rowgroup" colSpan="3">
              <h4>{"Shipping Options**"}</h4>
            </th>
          </tr>
          {shippingOptions.map(
            ({ name, baseRate, description, flatRate = false }) => (
              <tr colSpan="3">
                <td>
                  <strong>{name}</strong>
                  {description && ` - ${description}`}
                </td>
                {baseRate === null && <td colSpan="2">{"by arrangement"}</td>}
                {baseRate > 0 && (
                  <td colSpan="2">
                    {!flatRate && "from "}
                    <CurrencyFormatter value={baseRate} />
                  </td>
                )}
                {baseRate === 0 && <td colSpan="2">{"Free"}</td>}
              </tr>
            )
          )}
          <tr className={style[`totals__shipping-disclaimer`]} colSpan="3">
            <td colSpan="3">
              <strong>{"**Note: "}</strong>
              {`Shipping prices are estimates only. See `}
              <Button unstyled to={`/shipping-terms-and-conditions`} onBlue>
                {"shipping terms and conditions."}
              </Button>
            </td>
          </tr>
        </tbody>
      )}

      <tbody className={style.totals__next}>
        <tr>
          <th scope="rowgroup" colSpan="3">
            <h4>Select Next Step</h4>
          </th>
        </tr>
        <tr>
          <td colSpan="3">
            {checkout.map(({ type, label }) => (
              <Radio
                tight
                required
                key={`checkout-option-${type}`}
                name="checkout"
                value={type}
                label={label}
                checked={checkoutType === type}
                onChange={({ target }) => setCheckoutType(target.value)}
                whiteAsterisk
              />
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const TotalsTableRow = ({ item, remove }) => {
  const isConfigurable = item.type === "configurable"
  let options = []
  const deliveryPrice = item.variantDetails?.fromDeliveryPrice

  if (isConfigurable) {
    const { specifics } = item.variantDetails
    options = specifics.filter(({ name, value }) => {
      switch (name) {
        case "Model Type":
        case "Depth":
        case "Package":
          return false
        default:
          return true
      }
    })
  }

  return (
    <tr>
      <td className={style.totals__product}>
        <strong>{`${item.displayName} `}</strong>
        {isConfigurable && (
          <dl className={style.totals__options}>
            {options.map(({ name, value }) => (
              <Fragment key={`item-${item.sku}-${name}`}>
                <dt>{name}</dt>
                <dd>{value}</dd>
              </Fragment>
            ))}
          </dl>
        )}
        {deliveryPrice && (
          <dl className={style.totals__options}>
            <dd>
              <em>{`Additional delivery charge from $${deliveryPrice}`}</em>
            </dd>
          </dl>
        )}
      </td>
      <td className={style.totals__price}>
        <CurrencyFormatter value={item.prices.price} />
      </td>
      <td>
        <button
          className={style.totals__remove}
          onClick={() => remove(item.sku)}
        >
          <FaTimes />
        </button>
      </td>
    </tr>
  )
}

export default Totals
