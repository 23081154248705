import { useState, useEffect } from "react"
import { jsonToMap } from "@context/cartContext"

const defaults = {
  items: new Map([]),
  count: 0,
  loading: true
}

function useCart(friendID) {
  const [cart, setCart] = useState(defaults)

  useEffect(() => {
    let lsCount = 0

    const lsCart = jsonToMap(localStorage.getItem("Cart.cart"))

    if (lsCart) {
      lsCart.forEach(({ qty }) => {
        lsCount = lsCount + qty
      })

      setCart({
        items: lsCart,
        count: lsCount,
        loading: false
      })
    }
  }, [])

  return cart
}

export { useCart }
