import React, { Fragment } from "react"
import style from "./radio.mod.scss"

const Radio = ({
  label,
  required,
  checked = false,
  tight,
  whiteAsterisk,
  ...props
}) => {
  const classes = [style.radio]
  tight && classes.push(style["radio--tight"])
  whiteAsterisk && classes.push(style["radio--white-asterisk"])

  return (
    <div className={classes.join(" ")}>
      <label>
        <input
          type="radio"
          checked={checked && "checked"}
          required={required && "required"}
          {...props}
        />
        {label && (
          <Fragment>
            <span className={style.radio__icon}></span>
            {label}
            {required && <span aria-label="required">*</span>}
          </Fragment>
        )}
      </label>
    </div>
  )
}

export default Radio
