import React, { useContext, useState, useEffect, useRef } from "react"
import config from "@config/website"
import { graphql, navigate } from "gatsby"
import CartContext from "@context/cartContext"
import ProductContext from "@context/productContext"
import { isBrowser } from "@context/themeContext"
import { ProductFinanceDisclaimer } from "../../templates/product"
import style from "./new-product-intro.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Scroll from "@helpers/scroll"
import {
  Breadcrumbs,
  Block,
  Price,
  Button,
  Container,
  Recommendation,
  Superscript,
  Tooltip
} from "@components"
import IconJets from "@svgs/jets.svg"
import IconDimensions from "@svgs/dimensions.svg"
import IconSeats from "@svgs/seats.svg"
import BadgeViewerIcon from "@svgs/badge-viewer.svg"
import Clearlift from "@svgs/clearlift-small.svg"
import UvFree from "@svgs/uv-free.svg"
import UvUpgrade from "@svgs/uv-upgrade.svg"
import FreeService from "@svgs/service.svg"
import FreeDelivery from "@svgs/free-delivery.svg"
import MotorisedLouvres from "@svgs/motorised-louvres.svg"
import Infrared from "@svgs/infrared.svg"
import useAddToCart from "@helpers/useAddToCart"
import { gtmPush } from "@helpers/gtmHelpers"
import Customise from "./customise"
import { Collapse } from "@mui/material"

const NewProductIntro = ({
  breadcrumbs,
  name,
  sku,
  disclaimer,
  heroImage,
  newHeroImage,
  newHeroImageTwo,
  newHeroImageThree,
  brand,
  finance,
  sectionNav,
  category,
  isWide,
  showImageDisclaimer,
  id,
  links,
  virtualConsultation,
  excerpt,
  parentPrice,
  has3dViewer,
  freeDelivery,
  motorised,
  clearliftCompatible,
  uvFree,
  uvIncluded,
  freeService,
  infrared,
  swimspaImage,
  spaImage,
  ...product
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [isCustomiseOpen, setIsCustomiseOpen] = useState(false) // State to manage Collapse
  const [mainImage, setMainImage] = useState(newHeroImage)

  const modifiers = []
  isWide && modifiers.push(style["newtro--wide"])

  const productCat = category?.title
    ?.substring(0, category.title.length - 1)
    ?.toLowerCase()

  const { addToCart } = useAddToCart()
  const { cart, cartLoading } = useContext(CartContext)
  const { currentPrice, valid, productLoading, currentModel } = useContext(
    ProductContext
  )

  const parentInCart = !cartLoading && cart.has(sku)
  const cartProduct = parentInCart && cart.get(sku)
  const modelInCart =
    !cartLoading && !productLoading && cartProduct
      ? cartProduct.variantSku === currentModel
      : false

  const linksArr = []
  links.length > 0 &&
    links.map(link => {
      if (!link?.link?.path && !link.url) return

      const isGetAQuote = link?.link?.path.includes(`get-a-quote`)
      const isWetTest = link?.link?.path.includes(`book-a-wet-test`)
      const isVirtualConsultation = (link?.link?.path || link.url).includes(
        virtualConsultation
      )

      let formProductData = {}

      if (isWetTest) {
        formProductData = {
          heading: name,
          image: heroImage,
          brand,
          excerpt,
          productName: name,
          productBrand: brand.title,
          productCategory: category.title
        }
      }

      if (isGetAQuote) {
        formProductData = {
          heading: name,
          image: heroImage.gatsbyImageData,
          excerpt
        }
      }

      const config = (isWetTest || isVirtualConsultation || isGetAQuote) && {
        onClick:
          (isWetTest || isGetAQuote) &&
          (e => {
            e.preventDefault()
            navigate(link.link.path, {
              state: {
                heading: name,
                image: heroImage,
                brand,
                excerpt,
                productName: name,
                productBrand: brand.title,
                productCategory: category.title,
                ...formProductData
              }
            })
          })
      }

      if (isWetTest) config.gaTarget = "intro-wet-test-button"
      if (isVirtualConsultation)
        config.gaTarget = "intro-virtual-consultation-button"
      if (isGetAQuote) config.gaTarget = "intro-get-a-quote-button"

      return linksArr.push({
        to: link?.link?.path || link.url,
        children: link.displayText,
        id: link.id,
        ...config
      })
    })

  const [isStickyNav, setStickyNav] = useState(false)
  const [stickyNavOffset, setStickyNavOffset] = useState()
  const navModifiers = []

  const productNav = useRef()

  isStickyNav && navModifiers.push(style["newtro--nav-sticky"])

  const hasIcons =
    freeDelivery ||
    clearliftCompatible ||
    motorised ||
    infrared ||
    freeService ||
    uvFree ||
    uvIncluded

  // ***** New ***** //

  const specImage = isWide ? swimspaImage : spaImage

  useEffect(() => {
    const navbar = productNav.current
    setStickyNavOffset(navbar.offsetTop)
  }, [])

  if (isBrowser()) {
    window.onscroll = function() {
      myFunction()
    }
  }

  function myFunction() {
    if (window.pageYOffset >= stickyNavOffset) {
      setStickyNav(true)
    } else {
      setStickyNav(false)
    }
  }

  return (
    <div id="product-intro" className={[style.newtro, ...modifiers].join(" ")}>
      <Container>
        <Block gutters>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

          <div className={style.newtro__wrap}>
            <div className={style.newtro__grid}>
              <div className={style.newtro__content}>
                <div className={style.newtro__image}>
                  <div className={style.newtro__image_main}>
                    {mainImage ? (
                      <GatsbyImage
                        loading="eager"
                        image={mainImage.gatsbyImageData}
                      />
                    ) : (
                      <div
                        className={style["newtro__placeholder"]}
                      >{`Missing Image`}</div>
                    )}
                    {hasIcons && (
                      <div className={style.newtro__icons}>
                        {freeService && <FreeService />}
                        {clearliftCompatible && <Clearlift />}
                        {freeDelivery && <FreeDelivery />}
                        {motorised && <MotorisedLouvres />}
                        {infrared && <Infrared />}
                        {uvFree && <UvFree />}
                        {uvIncluded && <UvUpgrade />}
                      </div>
                    )}
                  </div>
                  <div className={style.newtro__image_thumbs}>
                    {heroImage && (
                      <div
                        className={style.newtro__image_thumb}
                        onClick={() => setMainImage(newHeroImage)}
                      >
                        <GatsbyImage
                          loading="eager"
                          image={newHeroImage.gatsbyImageData}
                        />
                      </div>
                    )}
                    {newHeroImageTwo && (
                      <div
                        className={style.newtro__image_thumb}
                        onClick={() => setMainImage(newHeroImageTwo)}
                      >
                        <GatsbyImage
                          loading="eager"
                          image={newHeroImageTwo.gatsbyImageData}
                        />
                      </div>
                    )}
                    {newHeroImageThree && (
                      <div
                        className={style.newtro__image_thumb}
                        onClick={() => setMainImage(newHeroImageThree)}
                      >
                        <GatsbyImage
                          loading="eager"
                          image={newHeroImageThree.gatsbyImageData}
                        />
                      </div>
                    )}
                  </div>
                  <p className={style.newtro__disc}>
                    {"Images may show optional extras."}
                  </p>
                  <div className={style.newtro__seperator} />
                  <div className={style.newtro__specis}>
                    <ProductAggregateSpecs netoProduct={product.netoProduct} />
                  </div>
                </div>
              </div>
              <div>
                <div className={style.newtro__pricing}>
                  <div className={style.newtro__title}>
                    <h1>{name}</h1>
                  </div>
                  <div className={style.newtro__options}>
                    <div className={style.newtro__price}>
                      <h3>{"Build Your Spa"}</h3>
                      {/* <Price prices={parentPrice} isAggregate={true} /> */}
                      <Price
                        prices={
                          !productLoading && valid ? currentPrice : parentPrice
                        }
                        isAggregate={productLoading || !valid}
                        customize={true}
                        modifier={"simple"}
                      />
                    </div>
                    <div className={style.newtro__custom}>
                      <h4
                        className={style.newtro__label}
                        onClick={() => setIsCustomiseOpen(!isCustomiseOpen)}
                      >
                        Customise your spa
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 31 31"
                        >
                          <path
                            d="M30 10.2L15.4 24.8.7 10.2a2.5 2.5 0 113.6-3.6l11 11.1 11.2-11a2.5 2.5 0 113.5 3.5z"
                            fill="#2886a2"
                          ></path>
                        </svg>
                      </h4>
                      <Collapse in={isCustomiseOpen}>
                        <Customise {...product} simple />
                      </Collapse>
                    </div>
                    <div className={style.newtro__buttons}>
                      <Button
                        fullWidthMobile
                        gaTarget={"intro-add-to-cart"}
                        wide
                        onClick={e => {
                          e.preventDefault()

                          addToCart(
                            { id, sku, name, prices: parentPrice, ...product },
                            navigate(product.upsellPath)
                          )
                        }}
                      >
                        {modelInCart ? "In cart" : "Add to cart"}
                      </Button>
                      <Button
                        to={"/contact-us/get-a-quote/"}
                        state={{
                          heading: name,
                          image: heroImage.gatsbyImageData,
                          excerpt
                        }}
                        wide
                        orange
                      >
                        {"Get a Quote"}
                      </Button>
                    </div>
                  </div>
                  <div className={style.newtro__finances}>
                    {finance?.length > 0 && (
                      <ProductIntroFinance finance={finance} />
                    )}
                  </div>
                </div>
                {brand && (
                  <div className={style["newtro__brand"]}>
                    <img alt={brand.name} src={brand.logo.url} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Block>
      </Container>

      {sectionNav.length > 1 && (
        <div className={style["newtro__nav-wrapper"]}>
          <nav
            ref={productNav}
            className={[style["newtro__nav"], ...navModifiers].join(" ")}
          >
            <ul>
              {sectionNav.map((section, i) => (
                <li
                  key={`product-section-link-${section.id}-${i}`}
                  className={
                    has3dViewer ? style[`newtro__nav-${section.id}`] : ""
                  }
                  onClick={() => {
                    if (has3dViewer && section.text === "3D Viewer") {
                      gtmPush({
                        event: "product-sticky-nav-click",
                        label: "3D Viewer Sticky Nav Clicked"
                      })
                    }
                  }}
                >
                  <Scroll type="id" element={section.id}>
                    <a href={`#${section.id}`}>{section.text}</a>
                  </Scroll>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </div>
  )
}

export default NewProductIntro

const ProductIntroFinance = ({ finance }) => {
  return (
    <div className={style["newtro__finance"]}>
      {finance.length > 1 ? (
        <>
          <div className={style["product-intro__finance-title"]}>
            {finance.map(item => (
              <GatsbyImage
                image={
                  item.wideIcon
                    ? item.logoWide.gatsbyImageData
                    : item.logo.gatsbyImageData
                }
              />
            ))}
          </div>
          <div className={style["newtro__finance-disclaimer"]}>
            <ProductFinanceDisclaimer
              displayText={"Finance Options on Select Models*"}
              linkPath={"/contact-us/finance-enquiry/"}
            />
          </div>
        </>
      ) : (
        <>
          <div className={style["newtro__finance-title"]}>
            <GatsbyImage
              image={
                finance[0].wideIcon
                  ? finance[0].logoWide.gatsbyImageData
                  : finance[0].logo.gatsbyImageData
              }
            />
          </div>
          <div className={style["newtro__finance-disclaimer"]}>
            <ProductFinanceDisclaimer
              displayText={finance[0].name}
              linkPath={finance[0].enquiryFormPage.path}
            />
          </div>
        </>
      )}
    </div>
  )
}

const ProductAggregateSpecs = ({ netoProduct }) => {
  const { seats, dimensions, jets } = netoProduct
  const specs = []

  seats &&
    specs.push({
      name: `Seating`,
      icon: <IconSeats />,
      label: `From ${seats} adults`
    })

  dimensions &&
    specs.push({
      name: `Dimensions`,
      icon: <IconDimensions />,
      label: `From ${dimensions}`
    })

  jets &&
    specs.push({
      name: `Jets`,
      icon: <IconJets />,
      label: `${jets}`
    })

  if (specs.length < 1) return null

  return (
    <div className={style.newtro__specs}>
      {specs.map(({ name, icon, label }) => (
        <dl key={`spec-${name}`} className={style.newtro__spec}>
          <dt>
            <h3>{name}</h3>
          </dt>
          <dd>
            <span className={style.newtro__spec_icon}>{icon}</span>
            <span className={style.newtro__spec_label}>{label}</span>
          </dd>
        </dl>
      ))}
    </div>
  )
}
