import React from "react"
import Button from "./button"
import style from "./cart-popup.mod.scss"

const CartPopup = () => {
  const modifiers = []

  return (
    <div className={[style.cartpopup, ...modifiers].join(" ")}>
      <div className={style.cartpopup__content}>
        <div className={style.cartpopup__item}>
          <p>Spa Pools & More Cart</p>
          <Button
            to={"/cart"}
            center
            secondary
            short
            white
            children={"View Cart"}
          />
        </div>
        <div className={style.cartpopup__item}>
          <p>Parts & Accessories Cart</p>
          <Button
            center
            secondary
            short
            white
            children={"View Cart"}
            to="https://www.spastore.com.au/_mycart"
            target="_blank"
          />
        </div>
      </div>
    </div>
  )
}

export default CartPopup
