import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./contact.mod.scss"
import { Input, Select } from "@components"
import CartContext from "@context/cartContext"
import { CurrencyFormatter } from "@context/productContext"

const ContactQuoteForm = ({
  shippingOptions,
  location,
  register,
  isFinanceEnquiry
}) => {
  const { count } = useContext(CartContext)

  if (!shippingOptions) return null

  const shipping = shippingOptions.map(item => {
    return { value: item, text: item }
  })

  return (
    <>
      <Input
        label={"Your Phone Number"}
        type="tel"
        name="phone"
        {...register("phone")}
        required
        placeholder={"(##) ### #### or ### ### ####"}
        fullWidth
      />
      <Input
        label={"Your Postcode"}
        name="postcode"
        {...register("postcode")}
        required
        placeholder={"Enter your postcode"}
        fullWidth
      />
      {count === 0 && !location.state?.heading && (
        <Input
          label={"Product"}
          name={"product"}
          {...register("product")}
          required
          placeholder={"Enter the product you want to receive a quote for"}
          fullWidth
        />
      )}
      {!isFinanceEnquiry && (
        <Select
          label={"Preferred Shipping Option "}
          name={"Shipping"}
          {...register("shipping")}
          options={shipping}
        />
      )}
    </>
  )
}

const ContactQuoteFormHead = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      contentYaml {
        cart {
          TAX_LABEL
          TAX_RATIO
        }
      }
    }
  `)

  const productDetailPageData = location.state?.heading ? true : false

  return (
    <CartContext.Consumer>
      {({ count, cart, getSubtotal }) => {
        const { TAX_LABEL, TAX_RATIO } = data.contentYaml.cart
        const subtotal = getSubtotal()
        const tax = subtotal * TAX_RATIO
        return (
          <div className={style.contact__header}>
            <h3 className={style.contact__heading}>{"Selected Products"}</h3>
            <div className={style.contact__quote}>
              <div className={style["contact__quote-info"]}>
                {productDetailPageData && (
                  <div className={style["contact__quote-product-data"]}>
                    <div>
                      <h4>{location.state.heading}</h4>
                      {location.state.excerpt && (
                        <p>{location.state.excerpt}</p>
                      )}
                    </div>
                    <div>
                      <GatsbyImage image={location.state.image} />
                    </div>
                  </div>
                )}

                {!productDetailPageData && count > 0 && (
                  <table>
                    <tbody>
                      {[...cart.values()].map(item => {
                        return item.type !== "shipping" ? (
                          <tr key={item.displayName}>
                            <th>
                              <h4>{item.displayName}</h4>
                            </th>
                            <td>
                              <h4>
                                <CurrencyFormatter value={item.prices.price} />
                              </h4>
                            </td>
                          </tr>
                        ) : null
                      })}
                    </tbody>
                  </table>
                )}
              </div>

              {!productDetailPageData && count > 0 && (
                <div className={style["contact__quote-sum"]}>
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          {"Sub Total "}
                          <span>
                            {`Inc `}
                            <CurrencyFormatter value={tax} />
                            {` ${TAX_LABEL}`}
                          </span>
                        </th>
                        <td>
                          <CurrencyFormatter value={subtotal} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )
      }}
    </CartContext.Consumer>
  )
}

export { ContactQuoteForm, ContactQuoteFormHead }
