import React, { Children, cloneElement } from "react"
import { graphql } from "gatsby"
import style from "./cards.mod.scss"
import PropTypes from "prop-types"

const Cards = ({ children = [], modifier, orientation, columns, size }) => {
  const classes = [style.cards]
  modifier && classes.push(`${style.cards}--${modifier}`)
  orientation && classes.push(`${style.cards}--${orientation}`)
  orientation &&
    columns &&
    classes.push(`${style.cards}--${orientation}-${columns}-col`)
  orientation &&
    columns &&
    size &&
    classes.push(`${style.cards}--${orientation}-${columns}-col-for-${size}`)
  columns && size && classes.push(`${style.cards}--${columns}-col-for-${size}`)
  
  const arrayChildren = Children.toArray(children)
  
  return (
    <div className={classes.join(" ")}>
      {arrayChildren.length > 0 && (
        <div className={style.cards__inner}>
          <ul className={style.cards__list}>
            {Children.map(arrayChildren, (child, index) => {
              return (
                <li
                  key={`list-item-${index}`}
                  className={style.cards__item}
                >
                  {cloneElement(child)}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Cards

export const query = graphql`
  fragment BadgeList on DatoCmsBadgeList {
    id
    padding
    model {
      apiKey
    }
    linkHash
    __typename
    badges {
      ...Badge
    }
  }
`

Cards.propTypes = {
  children: PropTypes.array.isRequired,
  modifier: PropTypes.string,
  orientation: PropTypes.string,
  columns: PropTypes.string,
  size: PropTypes.string
}
