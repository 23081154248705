import React from "react"
import { graphql } from "gatsby"
import Badge from "./badge"
import style from "./icon-grid.mod.scss"

const IconGrid = ({ items }) => {
  return (
    <div className={style.icon_grid}>
      {items &&
        items.map(item => {
          let badge = (
            <Badge
              image={
                item.image.gatsbyImageData
                  ? item.image.gatsbyImageData
                  : item.image.url
              }
              alt={item.image.alt || ""}
              id={item.id}
              caption={item.caption}
              moduleStyle="icon-grid"
            />
          )
          if (item.link) {
            let to = "/"
            if (item.link.url) {
              to = item.link.url
            } else if (item.link.link && item.link.link.path) {
              to = item.link.link.path
            }
            return (
              <a href={to} aria-label={item.link.displayText} key={item.id}>
                {badge}
              </a>
            )
          }
          return <span key={item.id}>{badge}</span>
        })}
    </div>
  )
}

export default IconGrid

export const query = graphql`
  fragment IconGrid on DatoCmsIconGrid {
    id
    model {
      apiKey
    }
    padding
    items {
      id
      caption
      link {
        id
        displayText
        url
        link {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsProductLandingPage {
            id
            path
          }
          ... on DatoCmsProduct {
            id
            path
          }
          ... on DatoCmsCollection {
            id
            path
          }
          ... on DatoCmsBlogPost {
            id
            path
          }
        }
        locationHash
      }
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { w: "200", fit: "crop", auto: "format", q: 50 }
        )
        alt
        url
      }
    }
  }
`
