import React from "react"
import ModuleArea from "./moduleArea"
import { Block, Container, LocationMapWithFinder } from "@components"

export default class LocationMapModuleArea extends ModuleArea {
  getComponent(block, productName, productBrand, location) {
    if (block.model) {
      if (block.model.apiKey === "location_map") {
        return (
          <Container>
            <Block
              heading={block?.heading}
              lead={block?.leadNode?.childMarkdownRemark?.html}
              gutters
            >
              <Block padding="both">
                <LocationMapWithFinder
                  showVirtualConsultation={block.showVirtualConsultation}
                />
              </Block>
            </Block>
          </Container>
        )
      } else {
        return super.getComponent(block, productName, productBrand, location)
      }
    } else {
      return (
        <center>
          {`Module Type: `}
          <code>{block.model && block.model.apiKey}</code>
          {` is not supported.`}
        </center>
      )
    }
  }
}
