import React from "react"
import cn from "classnames"
import style from "./badge.mod.scss"
import { Stars } from "@components"

const ReviewBadge = ({ rating, large = false }) => {
  const classes = cn(style.badge, {
    [style[`badge--large`]]: large
  })

  return (
    <div className={classes}>
      <div className={style.badge__top}>
        <div className={style.badge__number}>{rating.avgRating.toFixed(1)}</div>
        <div className={style.badge__stars}>
          <Stars rating={rating.avgRating.toFixed(1)} />
        </div>
      </div>
      <a href="#reviews">
        <p className={style.badge__count}>{`${rating.reviewCount} Reviews`}</p>
      </a>
    </div>
  )
}

export default ReviewBadge
