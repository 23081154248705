import React from "react"
import { graphql } from "gatsby"
import style from "./jet-layout.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Superscript } from "@components"

const JetLayout = ({
  fullWidthImage,
  squareImage,
  jetList,
  modelName,
  isWide
}) => {
  const classes = [style["jet-layout"]]
  const image = isWide ? fullWidthImage : squareImage
  isWide && classes.push(style["jet-layout--wide"])

  return (
    <figure className={classes.join(" ")}>
      <h3 className={style["jet-layout__mobile-title"]}>{modelName}</h3>
      <div className={style["jet-layout__image"]}>
        <GatsbyImage
          image={image.gatsbyImageData}
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
      <figcaption className={style["jet-layout__content"]}>
        <h3
          className={style["jet-layout__desktop-title"]}
          children={<Superscript children={modelName} />}
        />
        <ul className={style["jet-layout__list"]}>
          {jetList &&
            jetList
              .filter(({ jetType }) => jetType)
              .map(({ quantity, jetType: { name, icon } }) => {
                return (
                  <li
                    className={style["jet-layout__item"]}
                    key={`jet-list-${name}-${quantity}x-${modelName}`}
                  >
                    <div className={style["jet-layout__jet"]}>
                      {icon && (
                        <GatsbyImage
                          className={style["jet-layout__icon"]}
                          image={icon.gatsbyImageData}
                          alt={name}
                        />
                      )}
                      <span
                        className={style["jet-layout__qty"]}
                      >{`${quantity} x ${name}`}</span>
                    </div>
                  </li>
                )
              })}
        </ul>
      </figcaption>
    </figure>
  )
}

export default JetLayout

export const query = graphql`
  fragment JetLayout on DatoCmsJetLayout {
    id
    modelName
    jetList {
      quantity
      jetType {
        name
        icon {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { fit: "crop", w: "30", q: 60, dpr: 2 }
          )
          alt
        }
      }
    }
    fullWidthImage: image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: {
          fit: "crop"
          w: "1080"
          q: 60
          orient: 8
          auto: "format"
        }
      )
      alt
    }
    squareImage: image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", w: "427", q: 60, auto: "format" }
      )
      alt
    }
  }
`
