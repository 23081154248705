import { useContext, useEffect, useState } from "react"
import ProductContext from "@context/productContext"
import CartContext from "@context/cartContext"

const useAddToCart = () => {
  const {
    productLoading,
    setSku,
    parentProduct,
    currentModel,
    currentPrice
  } = useContext(ProductContext)
  const { add, cartLoading } = useContext(CartContext)
  const [loading, setLoading] = useState(true)
  const [callback, setCallback] = useState(false)
  const [addToCartSku, setAddToCartSku] = useState(null)

  useEffect(() => {
    // when the product and cart are done loading set our loading state to false. Loading is used later in another useEffect to determine when to add a product to the cart.
    if (!productLoading && !cartLoading) {
      setLoading(false)
    }
  }, [productLoading, cartLoading])

  const addToCart = (providedModel, callback) => {
    // store callback in state so that it can be referenced in a useEffect later on
    callback && setCallback(callback)

    // if the sku being passed to addToCart is not equal to the sku of the currentModel from the productContext then update the currentModel to the sku that's been passed in.
    if (currentModel.sku !== providedModel.sku) {
      setSku(providedModel.sku)
    }

    // if the sku held in our addToCartSku state does not equal the sku that's been passed to addToCart then update our state to the new sku
    if (addToCartSku !== providedModel.sku) {
      setAddToCartSku(providedModel)
    }
  }

  useEffect(() => {
    // if addToCartSku is not null and the product and cart are no longer loading then add the product to the cart
    if (addToCartSku && !loading) {
      // add the below product information to the cart. This useEffect runs everytime addToCartSku changes. This means that when addToCart runs and the addToCartSku doesn't equal the passed sku, the updated addToCartSku product will be added to the cart.
      add({
        sku: parentProduct.sku,
        id: parentProduct.id,
        type: parentProduct.type,
        path: parentProduct.path,
        hasUpsells: parentProduct.hasUpsells,
        upsellPath: parentProduct.upsellPath,
        name: addToCartSku.name,
        variantSku: addToCartSku.sku,
        prices: addToCartSku.prices || currentPrice,
        variantDetails: addToCartSku,
        brand: parentProduct.brand,
        category: parentProduct.category
      })

      // if a callback is stored in our state then it should run here.
      callback && callback()

      // reset the callback
      return () => {
        setCallback(null)
      }
    }
  }, [addToCartSku])

  return {
    addToCart
  }
}

export default useAddToCart
