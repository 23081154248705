import React, { useContext, useEffect } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import style from "./search-box.mod.scss"
import CloseIcon from "@svgs/close.svg"
import { Input } from "@components"
import { motion } from "framer-motion"
import ThemeContext from "@context/themeContext"

const CustomSearchBox = ({
  refine,
  currentRefinement,
  onFocus,
  onClick = () => {},
  clearsSearch,
  isOpen,
  context = {},
  modifiers = [],
  containClose = false,
  animatePresence = false,
  icon = <CloseIcon />,
  reverseIcon = false,
  name = "search",
  cancelEnter = false
}) => {
  const { focus, setFocus, onSubmit } = context
  const { location } = useContext(ThemeContext)
  const inputRef = React.useRef(null)

  useEffect(() => {
    if (location.pathname !== "/search-results/") onClick(false)
  }, [location.pathname])

  useEffect(() => {
    if (focus === true) {
      setTimeout(() => inputRef.current.focus(), 350)
    }
  }, [focus])

  modifiers.push(style[`search-box--${isOpen ? "open" : "closed"}`])
  containClose && modifiers.push(style[`search-box--contain-close`])
  animatePresence && modifiers.push(style[`search-box--animate-presence`])

  const animationConfig = animatePresence && {
    variants: {
      hidden: {
        opacity: 0,
        tabIndex: -1,
        pointerEvents: "none",
        transformOrigin: "100% 50%",
        x: "100%",
        transition: {
          x: {
            ease: "easeOut"
          }
        }
      },
      visible: {
        opacity: 1,
        tabIndex: 0,
        x: 0,
        pointerEvents: "auto",
        transformOrigin: "100% 50%",
        transition: {
          x: {
            ease: "easeOut"
          }
        }
      }
    }
  }

  return (
    <form
      className={[style["search-box"], ...modifiers].join(" ")}
      onSubmit={onSubmit}
    >
      <div className={style["search-box__inner"]}>
        <motion.div
          className={style["search-box__input"]}
          variants={animationConfig.variants}
          initial={"hidden"}
          animate={isOpen ? "visible" : "hidden"}
        >
          <Input
            type="text"
            placeholder="Search"
            ariaLabel="Search"
            onChange={e => {
              context.setFocus(true)
              refine(e.target.value)
            }}
            value={clearsSearch === true ? "" : currentRefinement}
            onFocus={onFocus}
            focus={focus}
            name={name}
            icon={icon}
            onIconClick={() => onClick(false)}
            autoComplete="off"
            ref={inputRef}
            reverseIcon={reverseIcon}
            cancelEnter={cancelEnter}
          />
        </motion.div>
      </div>
    </form>
  )
}

const SearchBox = connectSearchBox(CustomSearchBox)

export default SearchBox
