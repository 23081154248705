import React from "react"
import style from "./select.mod.scss"

const Select = React.forwardRef(
  (
    {
      row = false,
      fullWidth = false,
      strongBorder,
      label,
      name,
      options,
      ErrorMessage,
      helpMessage,
      modifier,
      onChange,
      value,
      custom,
      ...selectOptions
    },
    ref
  ) => {
    const modifiers = []

    ErrorMessage && modifiers.push(style["select--error"])
    modifier && modifiers.push(style[`select--${modifier}`])
    row && modifiers.push(style[`select--row`])
    custom && modifiers.push(style[`select--custom`])
    fullWidth && modifiers.push(style[`select--full-width`])
    strongBorder && modifiers.push(style[`select--strong-border`])

    const selectConfig = {
      id: `select--${name}`,
      name,
      ref,
      value,
      ...selectOptions
    }

    return (
      <div className={[style.select, ...modifiers].join(" ")}>
        {label && (
          <label htmlFor={`select--${name}`}>
            {label}
            {selectConfig.required && <span aria-label="required">*</span>}
          </label>
        )}
        <select
          {...selectConfig}
          onChange={onChange && (e => onChange(e, name))}
        >
          {selectConfig.placeholder && (
            <option value="">{selectConfig.placeholder.text}</option>
          )}
          {options?.length > 0 &&
            options.map(({ value, text, disabled, label, options = [] }) => {
              if (label && options.length > 0) {
                return (
                  <optgroup label={label} key={`select--opt-group--${label}`}>
                    {options.map(({ name, value, text, disabled }) => (
                      <option
                        key={`select--${name}--${value}`}
                        children={text}
                        value={value}
                        disabled={disabled}
                        aria-label={`Select ${text}`}
                      />
                    ))}
                  </optgroup>
                )
              }

              return (
                <option
                  key={`select--${name}--${value}`}
                  children={text}
                  value={value}
                  disabled={disabled}
                  aria-label={`Select ${text}`}
                />
              )
            })}
        </select>
        {helpMessage && <div className={style.select__help}>{helpMessage}</div>}
        {ErrorMessage && (
          <div className={style.select__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    )
  }
)

export default Select
