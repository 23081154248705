import React from "react"
import LogoSvg from "@svgs/logo.svg"
import MobileLogoSvg from "@svgs/mobile-logo.svg"
import { TransitionLink } from "@helpers/pageTransitions"
import style from "./logo.mod.scss"

const Logo = ({ position = "header", modifiers = [] }) => {
  position && modifiers.push(style[`logo--${position}`])

  return (
    <TransitionLink to="/">
      <div className={[style.logo, ...modifiers].join(" ")}>
        <div className={style["logo--desktop"]}>
          <LogoSvg />
        </div>
        {/* <div className={style["logo--mobile"]}>
          <MobileLogoSvg />
        </div> */}
      </div>
    </TransitionLink>
  )
}

export default Logo
