import React, { useState } from "react"
import style from "./embed.mod.scss"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaVrCardboard } from "@react-icons/all-files/fa/FaVrCardboard"
import { graphql } from "gatsby"
import { gtmPush } from "@helpers/gtmHelpers"
import { Block, Disclaimer } from "@components"

const Embed = ({
  placeholder,
  iframeProps,
  Icon = FaVrCardboard,
  disclaimer,
  gtm = {},
  buttonText = "Load VR"
}) => {
  const [embed, setEmbed] = useState({ loaded: false })

  return (
    <div className={style.embed}>
      {embed.loaded ? (
        <div className={style.embed__wrapper}>
          <iframe className={style.embed__iframe} {...iframeProps}></iframe>
        </div>
      ) : (
        <div className={style.embed__placeholder}>
          <GatsbyImage
            className={style.embed__image}
            image={placeholder.gatsbyImageData}
          />
          <button
            className={style.embed__button}
            onClick={() => {
              gtmPush(gtm)
              setEmbed({ loaded: true })
            }}
          >
            <Icon />
            <span>{buttonText}</span>
          </button>
        </div>
      )}
      {disclaimer && (
        <Block padding="both">
          <Disclaimer
            children={disclaimer}
            alignment="left-for-desktop"
            width={"small"}
          />
        </Block>
      )}
    </div>
  )
}

Embed.propTypes = {
  placeholder: PropTypes.shape({
    gatsbyImageData: PropTypes.object.isRequired
  }),
  iframeProps: PropTypes.object.isRequired,
  Icon: PropTypes.element
}

export default Embed

export const query = graphql`
  fragment ViewerEmbed on DatoCmsViewer {
    id
    heading
    scene
    padding
    placeholder {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "658", fit: "crop", w: "1170", q: 60, auto: "format" }
      )
      alt
    }
    model {
      apiKey
    }
    linkHash
    __typename
  }

  fragment TourEmbed on DatoCmsTour {
    id
    url
    padding
    placeholder {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "658", fit: "crop", w: "1170", q: 60, auto: "format" }
      )
      alt
    }
    model {
      apiKey
    }
    linkHash
    __typename
  }
`
