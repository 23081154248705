import React from "react"
import PropTypes from "prop-types"
import style from "./container.mod.scss"

const Container = ({
  children,
  fullWidth = false,
  maxWidth = "None",
  id,
  gutters = "None"
}) => {
  const modifiers = [
    style[`container--max-width-${maxWidth.toLowerCase().replace(/ /gi, "-")}`],
    style[`container--gutters-${gutters.toLowerCase().replace(/ /gi, "-")}`]
  ]

  fullWidth && modifiers.push(`${style.container}--full-width`)

  return (
    <div className={[style.container, ...modifiers].join(" ")} id={id}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}

export default Container
