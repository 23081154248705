const filterProductsByAnswers = (products, answers) => {
  let results = [...products]

  answers.forEach(({ excludeMatches, relatedProducts }) => {
    if (relatedProducts !== null) {
      results = results.filter(({ tags }) => {
        const test =
          typeof tags.find(tag => tag.id === relatedProducts.id) === "undefined"

        return excludeMatches ? test : !test
      })
    }
  })

  return results.map(result => result)
}

export { filterProductsByAnswers }
