import { oneLine, stripIndent } from "common-tags"

const NAMESPACE = "dataLayer"

const gtmPush = obj => {
  if (typeof window === "undefined") return false

  if (window[NAMESPACE] === undefined) {
    window[NAMESPACE] = []
  }

  window[NAMESPACE].push(obj)

  return window[NAMESPACE]
}

const transformCartProduct = (
  { name, sku, brand, category, variantDetails, prices },
  quantity = 1
) => {
  const product = {
    name,
    id: sku,
    quantity
  }

  if (prices !== undefined) {
    product.price = prices.price
    product.currency = prices.currency
  }

  if (variantDetails !== undefined) {
    product.variant = variantDetails.name
  }

  if (category !== undefined) {
    product.category = category
  }

  if (brand !== undefined) {
    product.brand = brand
  }

  return product
}

const generateGTM = ({ id, environmentParamStr, dataLayerName }) => stripIndent`
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${environmentParamStr}';f.parentNode.insertBefore(j,f);
  })(window,document,'script','${dataLayerName}', '${id}');`

const generateGTMIframe = ({ id, environmentParamStr }) =>
  oneLine`<iframe src="https://www.googletagmanager.com/ns.html?id=${id}${environmentParamStr}" height="0" width="0" style="display: none; visibility: hidden" aria-hidden="true"></iframe>`

const generateDefaultDataLayer = (dataLayer, dataLayerName) => {
  let result = `window.${dataLayerName} = window.${dataLayerName} || [];`

  if (dataLayer.type === `function`) {
    result += `window.${dataLayerName}.push((${dataLayer.value})());`
  } else {
    if (dataLayer.type !== `object` || dataLayer.value.constructor !== Object) {
      console.log(
        `Oops the plugin option "defaultDataLayer" should be a plain object. "${dataLayer}" is not valid.`
      )
    }

    result += `window.${dataLayerName}.push(${JSON.stringify(
      dataLayer.value
    )});`
  }

  return stripIndent`${result}`
}

export {
  generateGTM,
  generateGTMIframe,
  generateDefaultDataLayer,
  gtmPush,
  transformCartProduct
}
