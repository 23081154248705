module.exports = {
  "en-AU": {
    pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: "Spa World Australia",
    titleAlt: "Spa World", // Title for JSONLD
    currency: "AUD",
    url: "https://www.spaworld.com.au/", // Domain of your site. No trailing slash!
    devUrl: "https://development--spaworld-au.netlify.app",
    logo: "src/assets/img/logo.svg", // Used for SEO
    description:
      "Let the Spa World team help you find the very best spa pool that best suits your home and budget. View our top quality range of spas for sale today!",

    // JSONLD / Manifest
    favicon: "src/assets/img/favicon.png", // Used for manifest favicon generation
    shortName: "Spa World", // shortname for manifest. MUST be shorter than 12 characters
    author: "HC", // Author for schemaORGJSONLD
    themeColor: "#3D63AE",
    backgroundColor: "#EBEDF2",

    phoneNumber: "1300 959 541",
    twitter: "https://twitter.com/spaworldaus", // Twitter Username
    facebook: "https://www.facebook.com/SpaWorldAU/", // Facebook Site Name
    instagram: "https://www.instagram.com/spaworldau/", //Instagram
    pinterest: "https://www.pinterest.com.au/spaworldau/", //Pinterest

    //Tracking
    googleAnalyticsID: "",
    gtmContainerID: "GTM-NMJPWZC",
    facebookPixel: "459285920901263",
    foneDynamicsId: "FD-64612061",
    facebookDomainVerification: "31xh9wkdmev0vqu3f1sx9wpilpnrf2"
  },
  "en-NZ": {
    pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: "Spa World New Zealand",
    titleAlt: "Spa World", // Title for JSONLD
    currency: "NZD",
    url: "https://www.spaworld.co.nz/", // Domain of your site. No trailing slash!
    devUrl: "https://development--spaworld-nz.netlify.app",
    logo: "src/assets/img/logo.svg", // Used for SEO
    description:
      "Let the Spa World team help you find the very best spa pool that best suits your home and budget. View our top quality range of spas for sale today!",

    // JSONLD / Manifest
    favicon: "src/assets/img/favicon.png", // Used for manifest favicon generation
    shortName: "Spa World", // shortname for manifest. MUST be shorter than 12 characters
    author: "HC", // Author for schemaORGJSONLD
    themeColor: "#3D63AE",
    backgroundColor: "#EBEDF2",

    phoneNumber: "0800 400 195",
    twitter: "https://twitter.com/spaworldaus", // Twitter Username
    facebook: "https://www.facebook.com/SpaWorldNZ/", // Facebook Site Name
    instagram: "https://www.instagram.com/spaworldnz/", //Instagram
    pinterest: "https://www.pinterest.com.au/spaworldau/", //Pinterest

    //Tracking
    googleAnalyticsID: "",
    gtmContainerID: "GTM-NP57DR9",
    facebookPixel: "1992100597759157",
    foneDynamicsId: "FD-16450931",
    facebookDomainVerification: "6vuw7gw36ufnwkz1kc6ewsqxiwok8j"
  }
}
