import React from "react"
import cn from "classnames"
import style from "./prompt.mod.scss"
import { useModal } from "../../../context/modalContext"
import Button from "../button"

const Prompt = () => {
  const classes = cn(style.prompt)
  const { openModal } = useModal()

  return (
    <div className={classes}>
      <div className={style.prompt__wrap}>
        <p className={style.prompt__mobile}>
          Cant find what you are looking for, try our AI assistant.
        </p>
        <p className={style.prompt__desktop}>
          Cant find what you are looking for, try our AI assistant to get more
          information on our products and services.
        </p>
        <div className={style.prompt__button}>
          <Button
            short
            type={"button"}
            secondary
            onClick={openModal}
            white
            fullWidthMobile
          >
            AI Assistant
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Prompt
