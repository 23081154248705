import React from "react"
import { graphql } from "gatsby"
import style from "./blockquote.mod.scss"
import { Superscript } from "@components"

const Blockquote = ({ moduleStyle = "Default", quote, credit }) => {
  const modifiers = [
    style["blockquote--" + moduleStyle.toLowerCase().replace(/ /gi, "-")]
  ]

  return (
    <div className={[style.blockquote, ...modifiers].join(" ")}>
      <blockquote className={style.blockquote__wrapper}>
        <div
          className={style.blockquote__quote}
          children={<Superscript children={`"${quote}"`} />}
        />
        <cite className={style.blockquote__credit}>{credit}</cite>
      </blockquote>
    </div>
  )
}

export default Blockquote

export const query = graphql`
  fragment Blockquote on DatoCmsBlockquote {
    id
    quote
    credit
    padding
    model {
      apiKey
    }
    linkHash
    __typename
  }
`
