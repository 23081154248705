import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./content.mod.scss"
import { Video, Button, Superscript } from "@components"

const Content = ({
  id,
  heading,
  contentNode: {
    childMarkdownRemark: { html }
  },
  image,
  video,
  links = [],
  link,
  type = "simple",
  moduleStyle = "default",
  linkAlignment = "left"
}) => {
  if (link) links.push(link)

  const modifiers = [
    style["content--" + moduleStyle.toLowerCase().replace(/ /gi, "-")],
    style["content--" + type.toLowerCase().replace(/ /gi, "-")]
  ]

  const linkModifiers = [
    style["content__links--" + linkAlignment.toLowerCase().replace(/ /gi, "-")]
  ]

  return (
    <div className={[style.content, ...modifiers].join(" ")}>
      <div className={style.content__wrapper}>
        {type === "image" && (
          <div key={`content-${id}-media`} className={style.content__image}>
            <GatsbyImage image={image.gatsbyImageData} />
          </div>
        )}
        {type === "video" && (
          <div className={style.content__video}>
            <Video key={`content-${id}-media`} video={video} image={image} />
          </div>
        )}
        <div className={style.content__body}>
          {heading && (
            <h3
              className={style.content__heading}
              children={<Superscript children={heading} />}
            />
          )}
          {html && (
            <div
              className={style.content__caption}
              children={<Superscript children={html} />}
            />
          )}
          {links.length > 0 && (
            <div className={[style.content__links, ...linkModifiers].join(" ")}>
              {links.map(
                ({
                  url,
                  link,
                  displayText,
                  locationHash,
                  id,
                  newWindow = false
                }) => {
                  return (
                    <div
                      className={style.content__link}
                      key={`content-link-${id}`}
                    >
                      <Button
                        to={url ? url : link && link.path}
                        children={displayText}
                        hash={locationHash}
                        gaTarget={"content-button-click"}
                        fullWidthMobile
                        isNewTab={newWindow}
                      />
                    </div>
                  )
                }
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Content

export const query = graphql`
  fragment Content on DatoCmsContent {
    id
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    moduleStyle
    links: link {
      ...Link
    }
    linkAlignment
    padding
    linkHash
    model {
      apiKey
    }
    __typename
  }

  fragment ContentWithImage on DatoCmsContentWithImage {
    id
    model {
      apiKey
    }
    __typename
    heading
    linkHash
    moduleStyle
    padding
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "405", fit: "crop", w: "720", q: 60, auto: "format" }
      )
      alt
    }
  }

  fragment ContentWithVideo on DatoCmsContentWithVideo {
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "400", fit: "crop", w: "600", q: 50, auto: "format" }
      )
      alt
    }
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
    heading
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    moduleStyle
    padding
    linkHash
    link {
      ...Link
    }
    model {
      apiKey
    }
    __typename
  }
`
