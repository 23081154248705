import Typography from "typography"

const typography = new Typography({
  googleFonts: [
    {
      name: "Raleway",
      styles: ["400", "500", "600", "700"]
    }
  ],
  includeNormalize: false,
  baseFontSize: "16px",
  baseLineHeight: 1.666,
  scaleRatio: 2.65,
  headerFontFamily: ["Raleway", "Helvetica", "Arial", "sans-serif"],
  headerWeight: 600,
  bodyFontFamily: ["Raleway", "Helvetica", "Arial", "sans-serif"],
  bodyWeight: 400,
  boldWeight: 600,
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => ({
    body: {
      fontVariantNumeric: "lining-nums",
      fontFeatureSettings: "lnum on"
    },
    "h2,h3": {
      fontWeight: 500
    }
  })
})

// Export helper functions
export const { scale, rhythm, options } = typography
export default typography
