import React, { Fragment } from "react"
import { graphql } from "gatsby"
import style from "./price.mod.scss"
import { CurrencyFormatter } from "@context/productContext"
import { ConditionalDiv } from "@helpers/miscHelpers"

const allLabels = {
  aggregate: {
    standard: "From",
    was: "",
    now: "From",
    save: "Save up to"
  },
  single: {
    standard: "",
    was: "Was",
    now: "Now",
    save: "Save"
  }
}

const Price = props => {
  const { prices, isAggregate = false, modifier = "", isSearchResult } = props
  const modifiers = []
  modifier && modifiers.push(style[`price--${modifier}`])
  const { isWasNow, onSale } = prices

  return (
    <div className={[style.price, ...modifiers].join(" ")}>
      {isSearchResult ? (
        <PriceResults {...props} />
      ) : isWasNow || onSale ? (
        <PriceWasNow {...props} />
      ) : (
        <PriceStandard {...props} />
      )}
    </div>
  )
}

const PriceWasNow = ({ prices, isAggregate = false, modifier = "" }) => {
  const labels = isAggregate ? allLabels.aggregate : allLabels.single
  return (
    <Fragment>
      {!isAggregate && (prices.save > 0 || prices.saveUpTo > 0) && (
        <dl className={style.price__was}>
          <dt>{labels.was}</dt>
          <dd>
            <CurrencyFormatter value={prices.was} />
          </dd>
        </dl>
      )}
      <ConditionalDiv
        condition={modifier === "split"}
        config={{ className: style.price__wrapper }}
      >
        <dl className={style.price__now}>
          <dt>{(prices.save > 0 || prices.saveUpTo > 0) && labels.now}</dt>
          <dd>
            <CurrencyFormatter value={prices.now} />
          </dd>
        </dl>
        {isAggregate && prices.saveUpTo > 0 && (
          <dl className={style.price__save}>
            <dt>{labels.save}</dt>
            <dd>
              <CurrencyFormatter value={prices.saveUpTo} />
            </dd>
          </dl>
        )}
        {!isAggregate && prices.save > 0 && (
          <dl className={style.price__save}>
            <dt>{labels.save}</dt>
            <dd>
              <CurrencyFormatter value={prices.save} />
            </dd>
          </dl>
        )}
      </ConditionalDiv>
    </Fragment>
  )
}

const PriceStandard = ({ prices, isAggregate = false }) => {
  const labels = isAggregate ? allLabels.aggregate : allLabels.single
  return (
    <dl className={style.price__standard}>
      {labels.standard && <dt>{labels.standard}</dt>}
      <dd>
        <CurrencyFormatter value={prices.standard} />
      </dd>
    </dl>
  )
}

const PriceResults = ({ prices, isAggregate = false }) => {
  const labels = isAggregate ? allLabels.aggregate : allLabels.single
  return (
    <div className={style.price__result}>
      {prices.storeItem && prices.onSale ? (
        <>
          <dl className={style.price__result_whypay}>
            <dt>{"Why Pay"}</dt>
            <dd className={style.price__result_store_why}>
              ${prices.standard}
            </dd>
          </dl>
          <div className={style.price__result_nowsave}>
            <dl>
              <dt>{"Now"}</dt>
              <dd className={style.price__result_storeitem}>${prices.now}</dd>
            </dl>
            <dl className={style.price__save_tag}>
              <dt>{"SAVE"}</dt>
              <dd>${prices.save}</dd>
            </dl>
          </div>
        </>
      ) : (
        <dl>
          <dt>{"From"}</dt>
          <dd>
            ${prices.now}
            {/* <CurrencyFormatter value={prices.now} /> */}
          </dd>
        </dl>
      )}
    </div>
  )
}

export default Price

export const query = graphql`
  fragment Price on NetoProduct {
    currency
    promotionPrice
    promotionStartDate
    promotionExpiryDate
    retailPrice
    rrp
    saveUpTo
  }
`
