import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./cta.mod.scss"
import PropTypes from "prop-types"

const Cta = ({ images = [], message, button }) => {
  return (
    <div className={style.cta}>
      <div className={style.cta__inner}>
        <div className={style.cta__images}>
          {images.length > 0 &&
            images.map(image => (
              <div className={style.cta__image} key={image.id}>
                <GatsbyImage image={image.logoFull} />
              </div>
            ))}
        </div>
        <div className={style.cta__divider}></div>
        {message && <p className={style.cta__message}>{message}</p>}
      </div>
      {button && <div className={style.cta__button}>{button}</div>}
    </div>
  )
}

Cta.propTypes = {
  images: PropTypes.array.isRequired,
  button: PropTypes.node,
  message: PropTypes.string
}

export default Cta

export const query = graphql`
  fragment FinanceCta on DatoCmsFinance {
    name
    valueProposition
    logoFull {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "34", fit: "crop", w: "88", q: 60 }
      )
      alt
    }
  }
`
