import { graphql } from "gatsby"
import Clearance from "./clearance"

const Sale = Clearance

export default Sale

export const query = graphql`
  query saleProducts($language: String!) {
    products: allNetoSpecial(filter: { categories: { eq: "Specials" } }) {
      nodes {
        ...SpecialProductCard
        datoProduct: datoSpecialProduct {
          name
          id
          slug
          country
          serial: sku
          featuredSpecs {
            value
            model {
              apiKey
            }
            __typename
            label
            id
            icon {
              alt
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                imgixParams: {
                  h: "26"
                  fit: "crop"
                  w: "26"
                  q: 60
                  auto: "format"
                }
              )
            }
          }
          specs {
            value
            model {
              apiKey
            }
            __typename
            label
            id
          }
          image(locale: $language) {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "330"
                fit: "fill"
                fill: "solid"
                w: "500"
                q: 60
                auto: "format"
              }
            )
            alt
          }
          brand(locale: $language) {
            name
            slug
            id
            logo {
              alt
              url
            }
          }
        }
      }
    }

    content: contentYaml {
      regions {
        text
        value
      }
    }

    globalConfig: datoCmsGlobal {
      metaTags: salePageMetaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      filterTiles {
        id
        name
        image {
          gatsbyImageData(
            imgixParams: {
              w: "767"
              h: "704"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
        }
      }
      heading: salesHeading
      lead: salesLead
      modularContent: sales {
        ... on DatoCmsContentWithImage {
          ...ContentWithImage
        }
        ... on DatoCmsContent {
          ...Content
        }
      }
      specialsImagePlaceholder {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "330"
            fit: "fill"
            fill: "solid"
            w: "500"
            q: 60
            auto: "format"
          }
        )
      }
      banner: salesBanner {
        id
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { h: "600", fit: "cover", q: 60, auto: "format" }
          )
          alt
        }
      }
    }

    brands: allDatoCmsBrand {
      nodes {
        ...BrandTag
      }
    }
  }
`
