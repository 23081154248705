import React from "react"
import { graphql } from "gatsby"
import style from "./video.mod.scss"
import { Modal } from "@components"
import Blob from "@svgs/video-blob.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import Play from "@svgs/play.svg"
import UndoLogo from "../jacuzzi/undo-logo"
import { JsonLd } from "react-schemaorg"
import { duration } from "moment"

const Video = ({
  moduleStyle = "Default",
  video,
  meta,
  image,
  heading,
  imageOptions,
  elevated = true,
  radius = true,
  jacuzziImage,
  isJacuzzi,
  description,
  transcript,
  duration,
  ...props
}) => {
  const modifiers = [
    style["video--" + moduleStyle.toLowerCase().replace(/ /gi, "-")]
  ]
  !image && modifiers.push(style["video--no-image"])
  !elevated && modifiers.push(style["video--no-elevated"])
  !radius && modifiers.push(style["video--no-radius"])
  isJacuzzi && modifiers.push(style["video--jacuzzi"])

  const schema = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    name: video.title,
    thumbnailUrl: video.thumbnailUrl,
    embedUrl: video.url,
    width: video.width,
    height: video.height,
    uploadDate: meta?.createdAt,
    duration: ""
  }

  if (description) {
    schema.description = description
  }

  if (transcript) {
    schema.transcript = transcript
  }

  if (duration) {
    schema.duration = `PT${duration}`
  }

  const videoStyle = () => {
    switch (moduleStyle) {
      case "Blob":
        return (
          <MaskedVideo
            image={image}
            video={video}
            schema={schema}
            meta={meta}
          />
        )
      default:
        return (
          <SquareVideo
            image={isJacuzzi ? jacuzziImage : image}
            video={video}
            isJacuzzi={isJacuzzi}
            schema={schema}
            meta={meta}
          />
        )
    }
  }

  return (
    <div className={[style.video, ...modifiers].join(" ")}>
      {heading && <h2 className={style.video__heading}>{heading}</h2>}
      {videoStyle()}
    </div>
  )
}

const SquareVideo = ({ image, video, isJacuzzi, schema, meta }) => {
  return (
    <div className={style.video__standard}>
      {image ? (
        <GatsbyImage
          className={style.video__image}
          image={image.gatsbyImageData}
        />
      ) : (
        <div className={style.video__placeholder} />
      )}
      <div className={style.video__modal}>
        <Modal
          video={video}
          placement="center"
          icon={<Play />}
          largeIcon
          ariaLabel={`Open modal and play video: ${video.title}`}
          gtm={{
            event: `video-engagement`,
            label: `Video Model Click`,
            value: `${video.title}`
          }}
        />
      </div>
      {isJacuzzi && (
        <div className={style.video__undo}>
          <UndoLogo />
        </div>
      )}
      {meta && <JsonLd item={schema} />}
    </div>
  )
}

const MaskedVideo = ({ image, video, schema, meta }) => {
  const maskedImageData = {
    ...image.gatsbyImageData,
    images: {
      ...image.gatsbyImageData.images,
      fallback: {
        ...image.gatsbyImageData.images.fallback,
        src: image.gatsbyImageData.images.fallback.src.replace(
          /auto=format/g,
          "mask=https://www.datocms-assets.com/28472/1604627401-video-blob.png&fm=png"
        ),
        srcSet: image.gatsbyImageData.images.fallback.srcSet.replace(
          /auto=format/g,
          "mask=https://www.datocms-assets.com/28472/1604627401-video-blob.png&fm=png"
        )
      }
    }
  }

  return (
    <div className={style.video__masked}>
      <div className={style.video__blob}>
        <Blob />
      </div>
      {image && (
        <div className={style.video__clipped}>
          <GatsbyImage
            className={style.video__image}
            image={maskedImageData}
            style={{
              position: "unset"
            }}
          />
        </div>
      )}
      <Modal
        video={video}
        placement="center"
        icon={<Play />}
        largeIcon
        ariaLabel={`Open modal and play video: ${video.title}`}
        gtm={{
          event: `video-engagement`,
          label: `Video Model Click`,
          value: `${video.title}`
        }}
      />
      {meta && <JsonLd item={schema} />}
    </div>
  )
}

export default Video

export const query = graphql`
  fragment Video on DatoCmsVideo {
    id
    padding
    moduleStyle
    heading
    description
    duration
    transcript
    meta {
      createdAt
    }
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "658", w: "1170", fit: "crop", q: 60, auto: "format" }
      )
      alt
    }
    jacuzziImage: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "650", w: "1440", fit: "crop", q: 60, auto: "format" }
      )
      alt
    }
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
    model {
      apiKey
    }
    linkHash
    __typename
  }

  fragment AvanteVideo on DatoCmsProduct {
    avanteImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "658", w: "1170", fit: "crop", q: 60, auto: "format" }
      )
      alt
    }
    avanteUpgradeVideo {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }
`
