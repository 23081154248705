import React from "react"
import cn from "classnames"
import style from "./product-quote.mod.scss"
import { Block, Button, Container } from "../components"
import { GatsbyImage } from "gatsby-plugin-image"
import QuotesIcon from "@svgs/quotes.svg"

const ProductQuote = ({ heading, staff, link }) => {
  const classes = cn(style.quote)

  return (
    <div className={classes}>
      <Block gutters>
        <Container>
          <div className={style.quote__main}>
            {staff && (
              <div className={style.quote__staff}>
                <div className={style.quote__image}>
                  <GatsbyImage image={staff.image.gatsbyImageData} />
                </div>
                <div className={style.quote__details}>
                  <p className={style.quote__name}>{staff.name}</p>
                  <p className={style.quote__title}>{staff.jobTitle}</p>
                </div>
              </div>
            )}
            <div className={style.quote__content}>
              <div className={style.quote__text}>
                <div className={style.quote__icon_left}>
                  <QuotesIcon />
                </div>
                <p>{heading}</p>
                <div className={style.quote__icon_right}>
                  <QuotesIcon />
                </div>
              </div>
              {(link?.link || link?.url) && (
                <Button secondary white to={link?.link?.path || link?.url}>
                  {link.displayText}
                </Button>
              )}
            </div>
          </div>
        </Container>
      </Block>
    </div>
  )
}

export default ProductQuote
