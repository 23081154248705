import React from "react"
import cn from "classnames"
import style from "./modal.mod.scss"
import { useModal } from "../../../context/modalContext"

const RivaModal = () => {
  const { isOpen, closeModal } = useModal()
  const classes = cn(style.modal, {
    [style[`modal--open`]]: isOpen
  })

  return (
    <div className={classes}>
      <div className={style.modal__bg} onClick={closeModal}>
        <div className={style.modal__wrap}>
          <div id="customgpt_chat" className={style.modal__gpt} />
        </div>
      </div>
    </div>
  )
}

export default RivaModal
