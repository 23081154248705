import React from "react"
import style from "./button.mod.scss"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { TransitionLink } from "../../helpers/pageTransitions"
import Scroll from "@helpers/scroll"
import { Superscript } from "@components"

const ButtonContent = ({ children }) => (
  <span className={style.button__text}>
    {typeof children === "string" ? (
      <Superscript children={children} />
    ) : (
      children
    )}
  </span>
)

const Button = ({
  to = "/",
  children,
  type = null,
  url = false,
  title,
  onClick,
  noTarget = false,
  modifiers = [],
  disabled,
  isNewTab = false,
  isExternal = false,
  isMenuItem = false,
  isCardWrap = false,
  isNested = false,
  unstyled = false,
  center = false,
  activeClass = "",
  gaTarget,
  state,
  icon,
  localLink = false,
  hash,
  id,
  noFollow = false,
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          websiteUrl
        }
      }
    }
  `)

  if (url) {
    to = url
    console.info(`url on button is deprecated. Update`)
  }

  if (!url && !to) {
    to = "/"
  }

  props.secondary && modifiers.push(`${style.button}--secondary`)
  props.white && modifiers.push(`${style.button}--white`)
  props.orange && modifiers.push(`${style.button}--orange`)
  props.secondary &&
    props.white &&
    modifiers.push(`${style.button}--secondary--white`)
  props.fullWidthMobile && modifiers.push(`${style.button}--full-width-mobile`)
  props.fullWidth && modifiers.push(`${style.button}--full-width`)
  isMenuItem && modifiers.push(`${style.button}--menu-item`)
  icon && modifiers.push(`${style.button}--icon`)
  center && modifiers.push(`${style.button}--center`)
  props.short && modifiers.push(`${style.button}--short`)
  props.wide && modifiers.push(`${style.button}--wide`)
  props.circular && modifiers.push(`${style.button}--circular`)
  props.block && modifiers.push(`${style.button}--block`)
  props.tertiary && modifiers.push(`${style.button}--tertiary`)
  props.moduleStyle &&
    modifiers.push(
      `${style.button}--${props.moduleStyle.toLowerCase().replace(/ /gi, "-")}`
    )
  props.onBlue && modifiers.push(`${style.button}--on-blue`)
  type === "button" &&
    unstyled &&
    modifiers.push(`${style.button}--unstyled-button`)
  isCardWrap && modifiers.push(`${style.button}--card-wrap`)
  isNested && modifiers.push(`${style.button}--nested-button`)

  const { websiteUrl } = data.site.siteMetadata

  const externalLink =
    isExternal ||
    to.search(/http/g) > -1 ||
    to.search(/^tel:/g) > -1 ||
    to.search(/^mailto:/g) > -1 ||
    (isExternal && modifiers.push(`${style.button}--external`))

  const scrollLink = to.search(/^#/g) > -1

  const activeClassConfig = {
    activeClassName:
      !scrollLink &&
      !externalLink &&
      !type &&
      activeClass &&
      style[`button--${activeClass}`]
  }

  const config = {
    className: unstyled
      ? [style["button--unstyled"], ...modifiers].join(" ")
      : [style.button, ...modifiers].join(" "),
    onClick,
    state
  }

  if (gaTarget) config["data-ga-target"] = gaTarget

  if (!noTarget && externalLink && !localLink) {
    config.target = "_blank"
    if (noFollow) {
      config.rel = "noopener noreferrer nofollow"
    } else config.rel = "noopener noreferrer"
  }

  if (title && type) {
    config.ariaLabel = title
  }

  if (title && !type) {
    config.title = title
  }

  if (hash && !externalLink) {
    config.hash = hash
  }

  if (id) {
    config.id = id
  }

  if (disabled) config.disabled = "disabled"

  if (isNewTab) config.target = "_blank"

  if (externalLink || isNewTab) {
    return (
      <a href={to} {...config}>
        <ButtonContent children={children} />
      </a>
    )
  } else if (scrollLink) {
    return (
      <Scroll type="id" element={to.replace("#", "")}>
        <a href={to} {...config}>
          <ButtonContent children={children} />
        </a>
      </Scroll>
    )
  } else if (type) {
    return (
      <button type={type} {...config}>
        {icon && <span className={style.button__icon}>{icon}</span>}
        <ButtonContent children={children} />
      </button>
    )
  } else {
    return (
      <TransitionLink
        to={to.replace(websiteUrl, "")}
        {...config}
        {...activeClassConfig}
      >
        <ButtonContent children={children} />
      </TransitionLink>
    )
  }
}

export default Button

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  size: PropTypes.string,
  modifiers: PropTypes.array
}

export const query = graphql`
  fragment Button on DatoCmsButton {
    id
    displayText
    url
    noFollow
    padding
    model {
      apiKey
    }
    linkHash
    openInNewTab
    __typename
    link {
      ... on DatoCmsPage {
        id
        path
      }
      ... on DatoCmsProductLandingPage {
        id
        path
      }
      ... on DatoCmsLandingPage {
        id
        path
      }
      ... on DatoCmsProduct {
        id
        path
      }
      ... on DatoCmsCollection {
        id
        path
      }
      ... on DatoCmsBlogPost {
        id
        path
      }
      ... on DatoCmsLocation {
        id
        path
      }
      ... on DatoCmsShowroomList {
        id
        path
      }
      ... on DatoCmsArea {
        id
        path
      }
    }
    locationHash
  }
`
