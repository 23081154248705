import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Table,
  Recommendation,
  Button,
  Price,
  ShowMore,
  Superscript
} from "@components"
import { getPricing } from "@context/productContext"

const EMPTY_CELL = ""

const Compare = ({ product, compareProducts }) => {
  const data = useStaticQuery(graphql`
    {
      contentYaml {
        comparison {
          labels {
            seats
            dimensions
            volume
            jets
            weightFull
            rrp
          }
        }
      }
    }
  `)

  const CompareTableHead = () => {
    const productNames = compareProducts.map(product => (
      <h4
        children={
          <Superscript
            children={product.name}
            key={`product-${product.name}`}
          />
        }
      />
    ))
    const productImages = compareProducts.map(product => (
      <GatsbyImage
        image={product.compareThumb.gatsbyImageData}
        key={`image-${product.name}`}
      />
    ))

    return [
      [
        EMPTY_CELL,
        [
          <GatsbyImage
            image={product.avanteThumb.gatsbyImageData}
            key={`compare-image-${product.name}`}
          />,
          product && (
            <Recommendation
              productId={product.id}
              moduleStyle="center"
              key={`compare-rec-${product.name}`}
            />
          )
        ],
        ...productImages
      ],
      [EMPTY_CELL, <h4>{product.name}</h4>, ...productNames]
    ]
  }

  const CompareTableBody = () => {
    const products = [product, ...compareProducts]
    const productAttributes = data.contentYaml.comparison.labels
    const rowsArr = []

    const getValue = (key, productValue, product) => {
      if (productValue === undefined || productValue === null) return "n/a"
      switch (key) {
        case "dimensions":
          return `From ${productValue}`
        case "volume":
          return `${productValue}`
        case "weightFull":
          return `${productValue}`
        case "jets":
          return `${productValue}`
        case "rrp":
          return (
            <Price
              prices={getPricing(product.netoProduct)}
              isAggregate={product.netoVariants.length > 0}
              modifier={"compare"}
            />
          )
        default:
          return productValue
      }
    }

    Object.entries(productAttributes).map(([key, value]) => {
      const rowValues = [
        value,
        products.map(product =>
          getValue(key, product.netoProduct[key], product)
        )
      ].flat()

      return rowsArr.push(rowValues)
    })

    return rowsArr
  }

  const CompareTableFooter = () => {
    const productCtas = compareProducts.map(product => (
      <Button
        secondary
        short
        elevated
        to={product.path}
        children={"Find Out More"}
        gaTarget={"crosssell-button"}
      />
    ))

    return [[EMPTY_CELL, EMPTY_CELL, ...productCtas]]
  }

  return (
    <ShowMore
      enabled={CompareTableBody().length > 4}
      maxHeight={250}
      mobileOnly
      contentId={"#compareTable"}
    >
      <div id="compareTable">
        <Table
          modifier="compare"
          head={CompareTableHead()}
          body={CompareTableBody()}
          foot={CompareTableFooter()}
          firstColumnHeader
        />
      </div>
    </ShowMore>
  )
}

export default Compare

export const query = graphql`
  fragment Compare on DatoCmsProduct {
    slug
    id
    path
    name
    compareThumb: heroImage {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: {
          h: "135"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          w: "200"
          q: 60
        }
      )
      alt
    }
    netoProduct {
      id
      dimensions
      weightFull
      volume
      seats
      netoId
      name
      jets
      model
      promotionPrice
      promotionStartDate
      promotionExpiryDate
      retailPrice
      rrp
      saveUpTo
    }
    netoVariants {
      id
    }
  }
`
