import React from "react"
import style from "./ar-preview.mod.scss"
import { graphql } from "gatsby"
import { Container, Button } from "@components"
import { GatsbyImage } from "gatsby-plugin-image"

const ArPreview = ({ desktopHeading, mobileHeading, qrCode, previewLink }) => {
  return (
    <Container>
      <div className={style["ar-preview"]}>
        <h2 className={style["ar-preview__desktop-heading"]}>
          {desktopHeading}
        </h2>
        <h2 className={style["ar-preview__mobile-heading"]}>{mobileHeading}</h2>
        <div className={style["ar-preview__image"]}>
          <GatsbyImage image={qrCode.gatsbyImageData} />
        </div>
        <div className={style["ar-preview__button"]}>
          <Button children={"View in home"} to={previewLink} />
        </div>
      </div>
    </Container>
  )
}

export default ArPreview

export const query = graphql`
  fragment ArPreview on DatoCmsArProductPreview {
    id
    desktopHeading
    qrCode {
      alt
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "500", fit: "crop", w: "500", q: 60, auto: "format" }
      )
    }
    mobileHeading
    previewLink
    model {
      apiKey
    }
    linkHash
    __typename
  }
`
