import React, { useState } from "react"
import style from "./accordion.mod.scss"
import { Button } from "../components"

const Accordion = ({ items, firstOpen = false, id }) => {
  const classes = [style.accordion]

  return (
    <div className={classes.join(" ")} key={`accordion-${id}`}>
      {items.map((item, i) => (
        <AccordionItem
          item={item}
          key={item.id}
          itemNo={i}
          firstOpen={firstOpen}
        />
      ))}
    </div>
  )
}

export default Accordion

const AccordionItem = ({ item, itemNo, firstOpen }) => {
  const [isOpen, setIsOpen] = useState(firstOpen && itemNo === 0)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className={style.accordion__item} key={`accordion-item--${item.id}`}>
      <Button
        unstyled
        onClick={e => {
          e.preventDefault()
          toggleOpen()
        }}
      >
        <div className={style.accordion__heading}>
          <h4>{item.heading}</h4>
          <div className={style.accordion__icon}>{toggleIcon(isOpen)}</div>
        </div>
      </Button>
      {isOpen && (
        <div
          className={style.accordion__body}
          dangerouslySetInnerHTML={{ __html: item.text }}
        ></div>
      )}
    </div>
  )
}

const toggleIcon = (isOpen = false) => {
  return isOpen ? (
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 512 512">
      <path d="M233 1c-49.4 4.8-95 23-135 53.8C78.5 69.8 53.2 98 38.6 121c-6.3 9.9-18.6 35.1-23 47.1-4.9 13.4-10.2 34-12.8 49.9-3.1 19.5-3.1 56.5 0 76 4.5 27.6 11.6 49.8 23.7 74.5 13.2 26.9 26.9 46 48.9 68.1 22.1 22 41.2 35.7 68.1 48.9 24.7 12.1 46.9 19.2 74.5 23.7 19.5 3.1 56.5 3.1 76 0 27.6-4.5 49.8-11.6 74.5-23.7 26.9-13.2 46-26.9 68.1-48.9 22-22.1 35.7-41.2 48.9-68.1 8.8-17.9 13.2-29.6 18-47.6 6.3-24 7.8-37.1 7.8-64.9 0-27.8-1.5-40.9-7.8-64.9-4.8-18-9.2-29.7-18-47.6-13.2-26.9-26.9-46-48.9-68.1-22.1-22-41.2-35.7-68.1-48.9-24.6-12.1-47.4-19.3-74-23.5C279.8.7 247-.4 233 1zm46.8 40.1c48.8 5.3 93.4 27 128.8 62.3C450.1 144.9 472 197.7 472 256s-21.9 111.1-63.4 152.6S314.3 472 256 472s-111.1-21.9-152.6-63.4S40 314.3 40 256s21.9-111.1 63.4-152.6c34.9-34.9 80-56.9 127.6-62.3 11-1.2 37.6-1.3 48.8 0z"></path>
      <path d="M151.5 235.5c-5.9 1.6-11.8 7.3-13.6 13.3-2.6 8.8 1.9 19 10.3 23.1l4.4 2.1h206.8l4.4-2.1c8.4-4.1 12.9-14.3 10.2-23.2-1.9-6.3-8.3-12.2-14.8-13.6-8.3-1.7-201-1.4-207.7.4z"></path>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 512 512">
      <path d="M233 1c-49.4 4.8-95 23-135 53.8C78.5 69.8 53.2 98 38.6 121c-6.3 9.9-18.6 35.1-23 47.1-4.9 13.4-10.2 34-12.8 49.9-3.1 19.5-3.1 56.5 0 76 4.5 27.6 11.6 49.8 23.6 74.5 13.2 26.9 26.9 46 48.9 68.1 22.1 22 41.2 35.7 68.1 48.9 24.7 12.1 46.9 19.2 74.5 23.6 19.5 3.1 56.5 3.1 76 0 27.6-4.5 49.8-11.6 74.5-23.7 26.9-13.2 46-26.9 68.1-48.9 22-22.1 35.7-41.2 48.9-68.1 8.8-17.9 13.2-29.6 18-47.6 6.3-24 7.8-37.1 7.8-64.9 0-27.8-1.5-40.9-7.8-64.9-4.8-18-9.2-29.7-18-47.6-13.2-26.9-26.9-46-48.9-68.1-22.1-22-41.2-35.7-68.1-48.9-24.6-12.1-47.4-19.3-74-23.5C279.8.7 247-.4 233 1zm46.8 40.1c48.8 5.3 93.4 27 128.8 62.3C450.1 144.9 472 197.7 472 256s-21.9 111.1-63.4 152.6S314.3 472 256 472s-111.1-21.9-152.6-63.4S40 314.3 40 256s21.9-111.1 63.4-152.6c34.9-34.9 80-56.9 127.6-62.3 11-1.2 37.6-1.3 48.8 0z"></path>
      <path d="M251.5 135.9c-6.7 1.7-12.7 7.4-14.4 13.8-.7 2.4-1.1 19-1.1 44V234h-39c-25.1 0-40.8.4-44.2 1.1-6.5 1.4-12.9 7.3-14.8 13.6-2.7 8.9 1.8 19 10.2 23.2 4.3 2 5.6 2.1 46.1 2.1h41.6l.3 42.2c.3 40.7.4 42.5 2.4 46.3 1.1 2.2 4 5.3 6.3 7 3.7 2.6 5.1 3 11.1 3s7.4-.4 11.1-3c2.3-1.7 5.2-4.8 6.3-7 2-3.8 2.1-5.6 2.4-46.3l.3-42.2h41.6c40.5 0 41.8-.1 46.1-2.1 8.4-4.1 12.9-14.3 10.2-23.2-1.9-6.3-8.3-12.2-14.8-13.6-3.4-.7-19.1-1.1-44.2-1.1h-39v-40.3c0-25-.4-41.6-1.1-44-1.1-4.3-5.9-10.1-9.8-12.1-3.5-1.8-9.9-2.6-13.6-1.7z"></path>
    </svg>
  )
}
