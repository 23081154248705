import React, { useState } from "react"
import style from "./finder.mod.scss"
import { Button, Block, Input } from "@components"
import { useStaticQuery, navigate, graphql } from "gatsby"

const Finder = ({
  locationLists,
  locations,
  children,
  allLocations,
  showVirtualConsultation = true
}) => {
  const [locationNotFound, setLocationNotFound] = useState(false)

  const checkPostcode = e => {
    const query = e.target[0].value
    const location = locations.findIndex(item =>
      item.areasServed.split(",").includes(query)
    )
    if (location !== -1) navigate(locations[location].path)
    else {
      const prefix = query.slice(0, 2)
      const fallbackLocation = allLocations.findIndex(
        location =>
          location.postcodes && location.postcodes.split(",").includes(prefix)
      )
      if (fallbackLocation !== -1) navigate(allLocations[fallbackLocation].path)
      else setLocationNotFound(true)
    }
  }
  const data = useStaticQuery(graphql`
    query FinderQuery {
      content: contentYaml {
        showrooms {
          finder
        }
        virtualConsultation
        virtualTour
      }
    }
  `)

  return (
    <div className={style.finder}>
      <div className={style.finder__wrapper}>
        <div className={style.finder__top}>
          {showVirtualConsultation && (
            <>
              <h3>{"Can't make it into a showroom?"}</h3>
              <Block padding="bottom-half">
                <Button to={data.content.virtualConsultation} fullWidth>
                  Book a Virtual Consultation
                </Button>
              </Block>
              <Block padding="bottom">
                <Button to={data.content.virtualTour} fullWidth>
                  Showroom Virtual Tour
                </Button>
              </Block>
            </>
          )}
          <h3 className={style.finder__local}>{"Find your Local Showroom"}</h3>
          <p>{data.content.showrooms.finder}</p>
          {process.env.GATSBY_LANGUAGE === "en-AU" && (
            <form
              className={style.finder__form}
              onSubmit={e => {
                e.preventDefault()
                checkPostcode(e)
              }}
            >
              <Input
                required
                label="Enter your Postcode"
                placeholder="Enter Postcode"
                pattern="\d{4}"
              />
              <Button type="submit" to={"/"}>
                Go
              </Button>
              {locationNotFound && (
                <p className={style.finder__error}>No location found</p>
              )}
            </form>
          )}
        </div>
        {locationLists && (
          <div className={style.finder__bottom}>
            <h3>Our Locations</h3>
            <ul>
              {locationLists.map(item => {
                const singleLocation =
                  item.locations.length === 1 && item.locations[0]

                return (
                  <li key={`location-list-${item.id}`}>
                    <Button
                      to={singleLocation ? singleLocation.path : item.path}
                      unstyled
                    >
                      {item.heading}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
      {children && children}
    </div>
  )
}

export default Finder
