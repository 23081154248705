import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./specs.mod.scss"
import { Button, Table, Block, ShowMore } from "@components"
import { Superscript } from "@components"

const Specs = ({
  specTableHeader,
  specTableRows,
  specDimensions,
  flipProductDimensions,
  specDescription,
  spaImage,
  swimspaImage,
  specDoc,
  specsDoc,
  isWide,
  name,
  disclaimer,
  showDimensions = true
}) => {
  const modifiers = []
  isWide && modifiers.push(style["specs--wide"])

  const specImage = isWide ? swimspaImage : spaImage

  const SpecsTableHead = () => [
    [
      "",
      ...specTableHeader.map(({ heading }) => (
        <h3 children={<Superscript children={heading} />} />
      ))
    ]
  ]

  const columnCount = specTableHeader.length + 1

  const SpecsTableRows = () => {
    return specTableRows.map(({ specLabel, specDetails }) => [
      specLabel,
      ...specDetails.map(({ value }) => value)
    ])
  }

  const dimensions = specDimensions.dimensions.replace(/[Xx]/g, "x").split("x")
  const formattedDimensions = dimensions
    .slice(0, 2)
    .map(dimension => `${dimension}m`)

  const getClickId = () => {
    return name
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .trim("-")
  }

  let newSpecDoc = ""

  if (specsDoc.length > 0) {
    newSpecDoc = specsDoc[0].url || specsDoc[0].item?.url
  } else {
    newSpecDoc = specDoc?.url
  }

  return (
    <div className={[style["specs"], ...modifiers].join(" ")}>
      <div className={style.specs__buttons}>
        {newSpecDoc && (
          <Button
            gaTarget={"download-specs-button"}
            to={newSpecDoc}
            fullWidth={true}
            align={"center"}
            id={`${getClickId()}-download-specs`}
          >
            {`Download Specs`}
          </Button>
        )}
      </div>
      <Block gutters>
        <figure className={style.specs__diagram}>
          {specImage && <GatsbyImage image={specImage.gatsbyImageData} />}
          {showDimensions && (
            <figcaption>
              {!flipProductDimensions ? (
                <>
                  <span className={style.specs__length}>
                    {formattedDimensions[0]}
                  </span>
                  <span className={style.specs__width}>
                    {formattedDimensions[1]}
                  </span>
                </>
              ) : (
                <>
                  <span className={style.specs__length}>
                    {formattedDimensions[1]}
                  </span>
                  <span className={style.specs__width}>
                    {formattedDimensions[0]}
                  </span>
                </>
              )}
            </figcaption>
          )}
          {disclaimer && (
            <p className={style["specs__disclaimer"]}>{disclaimer}</p>
          )}
        </figure>
      </Block>

      <ShowMore
        maxHeight={275}
        enabled={SpecsTableRows().length > 4}
        contentId={"#specsTable"}
      >
        <div className={style.specs__table} id="specsTable">
          <Table
            firstColumnHeader={true}
            data-columns={columnCount}
            modifier={"specs"}
            head={SpecsTableHead()}
            body={SpecsTableRows()}
          />
        </div>
      </ShowMore>
    </div>
  )
}

export default Specs

export const query = graphql`
  fragment Specs on DatoCmsProduct {
    specTableHeader {
      heading
      id
    }

    specTableRows {
      id
      specDetails {
        id
        value
      }
      specLabel
    }

    specDimensions: netoProduct {
      width
      length
      dimensions
    }

    showDimensions
    flipProductDimensions

    specDescription: specificationsDescriptionNode {
      childMarkdownRemark {
        html
      }
    }

    spaImage: specificationsImage {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", w: "542", q: 60, auto: "format" }
      )
      alt
    }

    swimspaImage: specificationsImage {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", w: "1122", q: 60, auto: "format" }
      )
      alt
    }

    specDoc: specificationDocument {
      url
    }
    specsDoc {
      ... on DatoCmsDocAsset {
        id
        item {
          url
        }
      }
      ... on DatoCmsDocUrl {
        id
        url
      }
    }
  }
`
