import React, { useState, useEffect } from "react"
import style from "./trustbox.mod.scss"

const siteLanguage = process.env.GATSBY_LANGUAGE

const trustPilotHref =
  siteLanguage === "en-AU"
    ? "https://uk.trustpilot.com/review/spaworld.com.au"
    : "https://uk.trustpilot.com/review/spaworld.co.nz"

const TrustBox = ({ horizontal, inView = true }) => {
  const [loaded, setLoading] = useState(false)
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)

  useEffect(() => {
    if (window.Trustpilot && !loaded && inView) {
      window.Trustpilot.loadFromElement(ref.current, true)
      setLoading(true)
    }
  }, [inView, loaded])

  const trustPilot = horizontal ? (
    <TrustBoxHorizontal ref={ref} />
  ) : (
    <TrustBoxCarousel ref={ref} />
  )

  return <div className={style.trustbox}>{trustPilot}</div>
}

const TrustBoxHorizontal = React.forwardRef((props, ref) => {
  return (
    <>
      <h4>Read our customer reviews on Trustpilot</h4>
      <div
        ref={ref}
        className={style.trustbox__widget}
        data-locale="en-GB"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id={process.env.GATSBY_TRUSTPILOT_ID}
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
      >
        <a href={trustPilotHref} target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      </div>
    </>
  )
})

const TrustBoxCarousel = React.forwardRef((props, ref) => {
  return (
    <div className={style.trustbox__carousel}>
      <div
        ref={ref}
        className={style.trustbox__widget}
        data-locale="en-GB"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id={process.env.GATSBY_TRUSTPILOT_ID}
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-stars="3,4,5"
        data-review-languages="en"
      >
        <a href={trustPilotHref} target="_blank" rel="noreferrer">
          {"Trustpilot"}
        </a>
      </div>
      <p className={style.trustbox__caption}>
        {
          "Trustpilot is the most trusted, independent, on-line review and ratings platform."
        }
      </p>
    </div>
  )
})
export default TrustBox
