import React, { useEffect, useState, useContext } from "react"
import style from "./contact.mod.scss"
import { ContactProductFormHead } from "./product-form"
import { ContactQuoteFormHead } from "./quote-form"
import { motion } from "framer-motion"
import { Loading, Video, Button, Embed } from "@components"
import CartContext from "@context/cartContext"

const ContactWrapper = ({
  formProps,
  formType,
  video,
  image,
  failureMessage,
  successMessage,
  location,
  children,
  headingNode,
  formDocument,
  embed,
  id,
  name = ""
}) => {
  const { count } = useContext(CartContext)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const { error, success, loading } = formProps
  const isWetTestEnquiry = formType === "wet-test"
  const isQuote = formType === "quote"
  const isClearanceEnquiry = formType === "product"
  const isDownloadForm = formType === "download"

  const variants = {
    hidden: { opacity: 0, scale: 0, tabIndex: -1, position: "absolute" },
    visible: { opacity: 1, scale: 1, tabIndex: 0, position: "static" }
  }

  const formName = name.toLowerCase().replace(/ /g, "-")

  useEffect(() => {
    const lsIsFormSubmitted = JSON.parse(
      localStorage.getItem(`Form-${formType}-${formName}.isSubmitted`)
    )

    setIsFormSubmitted(lsIsFormSubmitted)
  }, [])

  const handleSuccess = () => {
    localStorage.setItem(
      `Form-${formType}-${formName}.isSubmitted`,
      JSON.stringify(success.status === 200 ? true : false)
    )
  }

  useEffect(() => {
    if (success && formType === "download") {
      handleSuccess()
    }
  }, [success])

  return (
    <div className={style.contact}>
      {video && (
        <div className={style.contact__video}>
          <Video image={image} video={video} elevated={false} radius={false} />
        </div>
      )}

      {!isFormSubmitted && !success && headingNode?.childMarkdownRemark?.html && (
        <div
          className={style.contact__heading}
          dangerouslySetInnerHTML={{
            __html: headingNode.childMarkdownRemark.html
          }}
        />
      )}

      {location.state && (isClearanceEnquiry || isWetTestEnquiry) && (
        <ContactProductFormHead location={location} />
      )}

      {isQuote && (count > 0 || location.state?.heading) && (
        <ContactQuoteFormHead location={location} />
      )}

      <div className={style.contact__lower}>
        {error && (
          <motion.div
            initial={"hidden"}
            animate={"visible"}
            variants={variants}
            className={style.contact__surface}
          >
            <div className={style.contact__body}>
              <center>{failureMessage}</center>
            </div>
          </motion.div>
        )}

        <motion.div
          initial={"visible"}
          animate={
            !success && !loading && !isFormSubmitted ? "visible" : "hidden"
          }
          variants={variants}
          className={style.contact__surface}
        >
          {children}
        </motion.div>

        {loading && <Loading />}

        {(success || isFormSubmitted) && (
          <motion.div
            initial={"hidden"}
            animate={"visible"}
            variants={variants}
            className={style.contact__surface}
          >
            <div className={style.contact__body}>
              <center>{successMessage}</center>
              {isDownloadForm && formDocument?.url && (
                <div className={style.contact__button}>
                  <Button to={formDocument.url} fullWidthMobile>
                    {"Download Now"}
                  </Button>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default ContactWrapper
