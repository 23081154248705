import React, { useContext } from "react"
import PhoneSvg from "@svgs/phone.svg"
import style from "./header.mod.scss"
import { Button, Logo, Menu, MenuIcon, Cart, Search } from "@components"
import FoneDynamicsContext from "@context/foneDynamicsContext"
import { motion } from "framer-motion"
import CartPopup from "./cart-popup"

const Header = ({
  minimal,
  toggleSidebar,
  isSidebarOpen,
  menuItems,
  isSearchOpen,
  setSearchOpen,
  isBrowser
}) => {
  const foneDynamics = useContext(FoneDynamicsContext)

  const classes = [style.header]

  minimal && classes.push(`${style.header}--minimal`)

  const variants = {
    visible: {
      opacity: 1,
      x: 0,
      transformOrigin: "100% 50%",
      transition: {
        opacity: {
          ease: "linear"
        },
        x: {
          ease: "easeOut"
        }
      }
    },
    hidden: {
      opacity: 0,
      x: 100,
      transformOrigin: "100% 50%",
      transition: {
        opacity: {
          ease: "linear"
        },
        x: {
          ease: "easeOut"
        }
      }
    }
  }

  return (
    <header className={classes.join(" ")}>
      <div className={style.header__container}>
        <div className={style.header__wrapper}>
          <div className={style.header__logo}></div>

          {!minimal && (
            <div className={style.header__menu}>
              <motion.div
                initial={"visible"}
                animate={!isSearchOpen ? "visible" : "visible"}
                variants={variants}
                aria-hidden={!isSearchOpen ? "true" : "false"}
              >
                <Menu items={menuItems} />
              </motion.div>
            </div>
          )}

          <div className={style.header__controls}>
            {/* {!minimal && (
              <>
                <div className={style.header__search}>
                  <Search
                    isSearchOpen={isSearchOpen}
                    setSearchOpen={setSearchOpen}
                  />
                </div>
                <div className={style.header__phone}>
                  <Button
                    to={`tel:${foneDynamics.num}`}
                    unstyled
                    noTarget
                    gaTarget={"header-call-button"}
                  >
                    <PhoneSvg />
                  </Button>
                </div>
              </>
            )} */}
            {/* <div className={style.header__cart}>
              <Cart linkMode={true} />
            </div> */}
            {/* {!minimal && (
              <div className={style.header__hamburger}>
                <MenuIcon toggle={toggleSidebar} isOpen={isSidebarOpen} />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
