import React, { useState } from "react"
import SwiperCore, { Pagination, Navigation, Controller } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import style from "./carousel.mod.scss"
import {
  Lead,
  Thumb,
  Button,
  Badge,
  Image,
  Video,
  Superscript,
  Disclaimer,
  Testimonial
} from "@components"
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft"
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight"
import { IoIosEye } from "@react-icons/all-files/io/IoIosEye"
import { graphql } from "gatsby"
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image"
import { ConditionalLink } from "@helpers/miscHelpers"

SwiperCore.use([Pagination, Navigation, Controller])

const Carousel = ({
  heading,
  lead,
  items,
  link,
  topLink,
  moduleStyle = "default",
  showImageDisclaimer,
  showThumbNavigation = false,
  padding = "none",
  imageCrop = "none",
  loading = "lazy",
  initialSlide = 0,
  isJacuzzi = false
}) => {
  const [mainSwiper, getMainSwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const modifiers = [
    style["carousel--" + moduleStyle.toLowerCase().replace(/ /gi, "-")],
    style[`carousel--${showThumbNavigation ? "thumbs" : "no-thumbs"}`],
    style["carousel--padding-" + padding.toLowerCase().replace(/ /gi, "-")]
  ]

  if (isJacuzzi && moduleStyle === "testimonial") {
    modifiers.push(style[`carousel--jacuzzi-testimonial`])
  }

  const defaultConfig = {
    spaceBetween: 25,
    modules: [Navigation, Pagination, Controller],
    pagination:
      items.length > 1
        ? {
            type: "fraction"
          }
        : false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    onSwiper: getMainSwiper,
    controller: {
      control: thumbnailSwiper
    }
  }
  const badgeConfig = {
    modules: [Navigation],
    spaceBetween: 25,
    slidesPerView: 1,
    centeredSlides: moduleStyle === "warranties" ? false : true,
    loop: moduleStyle === "warranties" ? false : true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  }

  const mainParams = {
    ...defaultConfig,
    breakpoints: {
      320: {
        autoHeight: true
      },
      1024: {
        autoHeight: false
      }
    }
  }

  const noPagination = {
    ...defaultConfig,
    pagination: false
  }

  const badgeParams = {
    ...badgeConfig,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    }
  }

  const config = () => {
    switch (moduleStyle) {
      case "badges":
        return badgeParams
      case "awards":
        return {
          ...badgeConfig,
          breakpoints: {
            1024: {
              centeredSlides: false,
              slidesPerView: 2,
              spaceBetween: 0
            }
          }
        }
      case "gallery":
        return {
          modules: [Navigation],
          slidesPerView: 1,
          spaceBetween: 25,
          centeredSlides: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          initialSlide
        }
      case "warranties":
        return badgeParams
      case "testimonial":
        return { autoHeight: true, ...noPagination }
      case "image-slider":
      case "hero":
        return {
          ...noPagination,
          spaceBetween: 0,
          loop: true,
          autoplay: {
            delay: 8000
          }
        }
      case "full-width":
        return noPagination
      case "simple":
      default:
        return mainParams
    }
  }

  const thumbnailParams = {
    modules: [Controller],
    onSwiper: getThumbnailSwiper,
    controller: {
      control: mainSwiper
    },
    spaceBetween: 20,
    slidesPerView: 3,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    centeredSlides: true
  }

  const multipleSlides = items.length > 1

  return (
    <div className={[style.carousel, ...modifiers].join(" ")}>
      {(heading || lead || topLink) && (
        <div className={style.carousel__top}>
          {heading && <h2 children={<Superscript children={heading} />} />}
          {lead && <Lead body={lead} />}
          {topLink && (
            <Button
              to={topLink.path}
              secondary={topLink.secondary}
              gaTarget={`${moduleStyle}-carousel-top-button`}
            >
              {topLink.text}
            </Button>
          )}
        </div>
      )}

      {multipleSlides ? (
        <div className={style.carousel__container}>
          <Swiper {...config()}>
            {items &&
              items.map((item, index) => (
                <SwiperSlide key={`slide--${item.id}`}>
                  {getElement(
                    item,
                    moduleStyle,
                    imageCrop,
                    index,
                    loading,
                    showImageDisclaimer
                  )}
                </SwiperSlide>
              ))}
            <div className={style.carousel__buttons}>
              <CarouselPrev />
              <CarouselNext />
            </div>
            {link && <Button to={link.link.path}>{link.displayText}</Button>}
          </Swiper>
          {showThumbNavigation && (
            <Swiper {...thumbnailParams}>
              {items &&
                items.map(({ name, heroImage, id, defaultImage, heading }) => {
                  return (
                    <SwiperSlide
                      className={style.carousel__thumb}
                      key={`${name}-slide-thumb--${id}`}
                    >
                      <Thumb
                        title={name || heading}
                        image={heroImage || defaultImage}
                        padded={moduleStyle.toLowerCase() === "products"}
                        icon={<IoIosEye />}
                      />
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          )}
        </div>
      ) : (
        <>
          {items && (
            <div key={`slide--${items[0].id}`}>
              {getElement(items[0], moduleStyle)}
            </div>
          )}
        </>
      )}
    </div>
  )
}

const getElement = (
  item,
  moduleStyle,
  imageCrop,
  index,
  loading,
  showImageDisclaimer
) => {
  switch (moduleStyle) {
    case "awards":
      return <Badge {...item} moduleStyle="award" />
    case "badges":
      return <Badge {...item} crop={imageCrop} />
    case "warranties":
      return <Badge {...item} moduleStyle="warranty" />
    case "testimonial":
      return <Testimonial slide {...item} />
    case "image-slider":
    case "hero":
      return (
        <CarouselImageSlide
          {...item}
          moduleStyle={moduleStyle}
          index={index}
          loading={loading}
        />
      )
    case "products":
      return (
        <CarouselSlide
          {...item}
          showImageDisclaimer={showImageDisclaimer}
          defaultImage={item.heroImage}
          heading={item.name}
          subHeading={item.subtitle}
          caption={item.excerpt}
          link={{ title: "more", path: item.path }}
        />
      )
    case "gallery":
      return <GallerySlide {...item} />
    default:
      return (
        <CarouselSlide
          {...item}
          showImageDisclaimer={showImageDisclaimer}
          moduleStyle={moduleStyle}
          elevated
          secondary
        />
      )
  }
}

const CarouselSlide = ({
  caption,
  heading,
  elevated = false,
  secondary = false,
  defaultImage,
  video,
  moduleStyle,
  featureImage,
  subHeading = "",
  link,
  showImageDisclaimer
}) => {
  return (
    <div className={style.carousel__slide}>
      <div className={style.carousel__media}>
        {defaultImage && <Image image={defaultImage} elevated={elevated} />}
        {video && (
          <Video video={video} image={video.image} moduleStyle={moduleStyle} />
        )}
        {moduleStyle === "Simple" && showImageDisclaimer && (
          <div className={style.carousel__disclaimer}>
            <Disclaimer
              children="Image may show optional extras."
              alignment="left"
              width={"small"}
            />
          </div>
        )}
      </div>
      <div className={style.carousel__content}>
        {heading && moduleStyle === "simple" ? (
          <h2
            className={style.carousel__heading}
            children={<Superscript children={heading} />}
          />
        ) : (
          <h3
            className={style.carousel__heading}
            children={<Superscript children={heading} />}
          />
        )}
        {subHeading && (
          <h4
            className={style.carousel__subheading}
            children={<Superscript children={subHeading} />}
          />
        )}
        {caption && (
          <div
            className={style.carousel__caption}
            children={<Superscript children={caption} />}
          />
        )}
        {link && (
          <div className="swiper-no-swiping">
            <Button
              to={link.path}
              fullWidthMobile
              secondary={secondary}
              gaTarget={`${moduleStyle}-carousel-slide-button`}
            >
              {link.title}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const CarouselImageSlide = ({
  heading,
  image,
  mobileImage,
  link,
  index,
  moduleStyle,
  ...props
}) => {
  const images = withArtDirection(getImage(image), [
    {
      media: "(max-width: 700px)",
      image: getImage(mobileImage)
    }
  ])

  const isFirst = index === 0

  const fadeIn = !isFirst && props.loading === "eager"
  const loading = isFirst && props.loading === "eager" ? props.loading : "lazy"

  return (
    <div className={style.carousel__slide}>
      <ConditionalLink
        condition={link}
        config={
          link && {
            to: link?.link?.path || link?.url,
            gaTarget: `${moduleStyle}-carousel-slide-link`
          }
        }
      >
        <div className={style.carousel__media}>
          {image && (
            <GatsbyImage
              className={style.carousel__image}
              image={images}
              loading={(isFirst && loading) || "lazy"}
              fadeIn={!isFirst && loading === "eager"}
              alt={image.alt}
            />
          )}
        </div>
        <div className={style.carousel__content}>
          {heading && (
            <h2
              className={style.carousel__heading}
              children={<Superscript children={heading} />}
            />
          )}
        </div>
      </ConditionalLink>
    </div>
  )
}

const GallerySlide = ({ image, content, link, video }) => {
  return (
    <div className={style[`carousel__slide--gallery`]}>
      <div className={style.carousel__image}>
        {image && !video && <Image image={image} alt={image.alt} />}
        {video && <Video video={video} image={image} />}
      </div>
      {(content || link) && (
        <div
          className={[style["carousel__content"], "swiper-no-swiping"].join(
            " "
          )}
        >
          {content && <Superscript children={content} />}
          {link && <Button {...link} />}
        </div>
      )}
    </div>
  )
}

const CarouselPrev = () => (
  <button aria-label="Previous" className="swiper-button-prev">
    <MdKeyboardArrowLeft />
  </button>
)

const CarouselNext = () => (
  <button aria-label="Next" className="swiper-button-next">
    <MdKeyboardArrowRight />
  </button>
)

export default Carousel

export { CarouselPrev, CarouselNext }

export const query = graphql`
  fragment AwardsCarousel on DatoCmsAwardsCarousel {
    heading
    id
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    padding
    model {
      apiKey
    }
    linkHash
    __typename
    awards {
      ...Award
    }
    link {
      ...Link
    }
  }

  fragment BadgeCarousel on DatoCmsBadgeCarousel {
    heading
    id
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    padding
    model {
      apiKey
    }
    linkHash
    linkHash
    __typename
    badges {
      ...Badge
    }
    imageCrop
  }

  fragment CarouselProductSlide on DatoCmsHome {
    productCarousel {
      id
      path
      excerpt
      subtitle
      name
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "472"
            fit: "fill"
            fill: "solid"
            w: "778"
            q: 60
            fillColor: "00ffffff"
            auto: "format"
          }
        )
        alt
      }
    }
  }

  fragment CarouselHomeImageSlide on DatoCmsHome {
    homepageCarousel {
      id
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1170", q: 60 }
        )
        alt
      }
      mobileImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "457", fit: "crop", w: "375", q: 60 }
        )
        alt
      }
      link {
        ...Link
      }
    }
  }

  fragment JetListCarousel on DatoCmsJetLayout {
    jetList {
      id
      quantity
      jetType {
        showInCarousel
        id
        name
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        model {
          apiKey
        }
        __typename
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "700"
              fit: "crop"
              w: "1170"
              q: 60
              auto: "format"
            }
          )
          alt
        }
      }
    }
  }

  fragment Carousel on DatoCmsCarousel {
    heading
    id
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    padding
    showThumbNavigation
    model {
      apiKey
    }
    linkHash
    __typename
    carouselItem {
      id
      heading
      captionNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "430"
            fit: "crop"
            w: "610"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      link {
        ...Link
      }
    }
  }

  fragment CarouselImageSlide on DatoCmsImageSlider {
    id
    model {
      apiKey
    }
    linkHash
    __typename
    moduleStyle
    padding
    background
    slides {
      id
      heading
      captionNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "430"
            fit: "crop"
            w: "610"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      link {
        ...Link
      }
    }
  }

  fragment WarrantySlider on DatoCmsWarrantySlider {
    id
    model {
      apiKey
    }
    linkHash
    __typename
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    warrantyDocument {
      url
    }
    warrantyDoc {
      ... on DatoCmsDocAsset {
        id
        item {
          url
        }
      }
      ... on DatoCmsDocUrl {
        id
        url
      }
    }
    padding
    background
    warranties {
      ...Warranty
    }
  }

  fragment TestimonialSlider on DatoCmsTestimonialSlider {
    id
    model {
      apiKey
    }
    linkHash
    __typename
    heading
    padding
    testimonials {
      id
      quote
      country
      credit
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "320"
            fit: "crop"
            w: "560"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      video {
        url
        title
        provider
        providerUid
      }
    }
    link {
      ...Link
    }
  }

  fragment JetCarousel on DatoCmsJet {
    id
    quantity
    jetType {
      id
      name
      description
      model {
        apiKey
      }
      __typename
      image {
        gatsbyImageData
      }
    }
  }

  fragment MediaSlider on DatoCmsMediaSlider {
    id
    moduleStyle
    padding
    background
    showImageDisclaimer
    model {
      apiKey
    }
    linkHash
    __typename
    slides {
      ... on DatoCmsSlide {
        id
        heading
        captionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "525"
              fit: "crop"
              w: "878"
              q: 50
              auto: "format"
            }
          )
          alt
        }
        model {
          apiKey
        }
        __typename
      }
      ... on DatoCmsVideoSlide {
        id
        heading
        captionNode {
          childMarkdownRemark {
            html
          }
        }
        video {
          height
          provider
          providerUid
          thumbnailUrl
          title
          url
          width
        }
        overlayImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "525"
              fit: "crop"
              w: "878"
              q: 50
              auto: "format"
            }
          )
          alt
        }
        model {
          apiKey
        }
        __typename
      }
    }
  }

  fragment ThumbnailCarousel on DatoCmsThumbnailCarousel {
    id
    padding
    background
    showImageDisclaimer
    model {
      apiKey
    }
    linkHash
    __typename
    slides {
      ... on DatoCmsSlide {
        id
        heading
        thumbnailText
        captionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "525"
              fit: "crop"
              w: "878"
              q: 50
              auto: "format"
            }
          )
          alt
        }
        model {
          apiKey
        }
        __typename
      }
      ... on DatoCmsVideoSlide {
        id
        heading
        thumbnailText
        captionNode {
          childMarkdownRemark {
            html
          }
        }
        video {
          height
          provider
          providerUid
          thumbnailUrl
          title
          url
          width
        }
        overlayImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "525"
              fit: "crop"
              w: "878"
              q: 50
              auto: "format"
            }
          )
          alt
        }
        model {
          apiKey
        }
        __typename
      }
    }
  }
`
