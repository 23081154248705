import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageLink = ({image, link}) => {
  // console.log("ImageLink", link);
  let to = "/"
  if (link && link.url) {
    to = link.url
  } else if (link.link && link.link.path) {
    to = link.link.path
  }
  return (
    <a href={to} aria-label={link.displayText}>
      <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ""} />
    </a>
  )
}

export default ImageLink

export const query = graphql`
  fragment ImageLink on DatoCmsImageLink {
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {w: "768", h: "768"}
      )
      alt
      url
    }
    link {
      id
      displayText
      url
      link {
        ... on DatoCmsPage {
          id
          path
        }
        ... on DatoCmsProductLandingPage {
          id
          path
        }
        ... on DatoCmsProduct {
          id
          path
        }
        ... on DatoCmsCollection {
          id
          path
        }
        ... on DatoCmsBlogPost {
          id
          path
        }
      }
      locationHash
    }
    model {
      apiKey
    }
    __typename
  }
`
