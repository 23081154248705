import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./image.mod.scss"

const Image = ({
  smallPortrait,
  largePortrait,
  smallLandscape,
  largeLandscape,
  square,
  image,
  standAlone = false,
  elevated = false,
  modifiers = [],
  aspectRatio = null,
  caption
}) => {
  standAlone && modifiers.push(style["image--stand-alone"])
  elevated && modifiers.push(style["image--elevated"])

  const getImageData = () => {
    switch (aspectRatio) {
      case "Large Landscape":
        return largeLandscape.gatsbyImageData
      case "Small Landscape":
        return smallLandscape.gatsbyImageData
      case "Square":
        return square.gatsbyImageData
      case "Large Portrait":
        return largePortrait.gatsbyImageData
      case "Small Portrait":
        return smallPortrait.gatsbyImageData
      default:
        return image.gatsbyImageData
    }
  }
  return (
    <>
      <div className={[style.image, ...modifiers].join(" ")}>
        {image && (
          <GatsbyImage
            className={style.image__gatsby}
            image={getImageData()}
            alt={image.alt}
          />
        )}
      </div>
      {caption && (
        <div className={style.image__caption}>
          <p>{caption}</p>
        </div>
      )}
    </>
  )
}

export default Image

export const query = graphql`
  fragment Image on DatoCmsImage {
    id
    caption
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { w: "768", h: "432", fit: "crop", auto: "format" }
      )
      alt
      url
    }
    square: image {
      gatsbyImageData(
        layout: CONSTRAINED
        aspectRatio: 1
        imgixParams: { w: "768", h: "432", fit: "crop", auto: "format" }
      )
      alt
      url
    }
    smallLandscape: image {
      gatsbyImageData(
        layout: CONSTRAINED
        aspectRatio: 1.25
        imgixParams: { w: "768", h: "432", fit: "crop", auto: "format" }
      )
      alt
      url
    }
    largeLandscape: image {
      gatsbyImageData(
        layout: CONSTRAINED
        aspectRatio: 1.77
        imgixParams: { w: "768", h: "432", fit: "crop", auto: "format" }
      )
      alt
      url
    }
    smallPortrait: image {
      gatsbyImageData(
        layout: CONSTRAINED
        aspectRatio: 0.8
        imgixParams: { w: "768", h: "432", fit: "crop", auto: "format" }
      )
      alt
      url
    }
    largePortrait: image {
      gatsbyImageData(
        layout: CONSTRAINED
        aspectRatio: 0.56
        imgixParams: { w: "768", h: "432", fit: "crop", auto: "format" }
      )
      alt
      url
    }
    padding
    aspectRatio
    model {
      apiKey
      id
    }
    linkHash
    __typename
  }
`
