import React from "react"
import style from "./back-to-top.mod.scss"
import { FaAngleUp } from "@react-icons/all-files/fa/FaAngleUp"
import Scroll from "@helpers/scroll"
import { Button } from "@components"

const BackToTop = ({ element = "", type = "" }) => {
  return (
    <div className={style["back-to-top"]}>
      <Scroll type={type} element={element}>
        <Button to={element} unstyled>
          <FaAngleUp />
        </Button>
      </Scroll>
    </div>
  )
}

export default BackToTop
