import React, { useRef } from "react"

const TestPage = () => {
  const inputEl = useRef(null)

  const startViewer = () => {
    if (inputEl) {
      inputEl.current
        .startViewer()
        .then(() => {
          // Viewer was successfully launched, use this to run
          // other logic if needed
        })
        .catch(err => {
          // Viewer is not possible
          // run failure logic here
        })
    }
  }

  return (
    <>
      <div>
        <h1>Hello</h1>
        {/* <plattar-viewer id="embed" scene-id="1adeea20-5e04-11ec-8d4e-6d57c9a95cc5" /> */}
        <plattar-embed
          ref={inputEl}
          id="embed"
          scene-id="1adeea20-5e04-11ec-8d4e-6d57c9a95cc5"
          width="512px"
          height="512px"
        />
        <div onClick={startViewer}>Click here</div>
      </div>
    </>
  )
}

export default TestPage
