import React, { useEffect, useRef, useState } from "react"
import SearchBox from "./search-box"
import style from "./search-bar.mod.scss"
import SearchIcon from "@svgs/search.svg"
import LearnCentreSearchResultDropdown from "./learn-centre-results-dropdown"
import useClickOutside from "@helpers/use-click-outside"

const SearchBar = ({ context, tags, suggested }) => {
  const rootRef = useRef(null)
  useClickOutside(rootRef, () => {
    context.focus && context.setFocus(false)
  })
  const [scrollPosition, setScrollPosition] = useState(0)
  const [searchPosition, setSearchPosition] = useState(1)

  const onClick = () => {
    context.clearSearchState()
    context.setFocus(false)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = event => {
        setScrollPosition(Math.round(window.scrollY))
      }
      document.addEventListener("scroll", handleScroll)
    }
  })

  useEffect(() => {
    if (context.focus && window.innerWidth < 700) {
      window.scrollTo({ top: searchPosition, behavior: "smooth" })
    } else {
      document.documentElement.style.overflow = "initial"
    }
  }, [context.focus])

  useEffect(() => {
    const yOffset = -10
    const element = document.getElementById("input--search-bar")
    if (element) {
      setSearchPosition(
        Math.round(
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        )
      )
    }
    if (
      context.focus &&
      scrollPosition === searchPosition &&
      window.innerWidth < 700
    ) {
      document.documentElement.style.overflow = "hidden"
    }
  }, [scrollPosition, context.focus])

  return (
    <div ref={rootRef} className={style["search-bar__inner"]}>
      <SearchBox
        onFocus={() => {
          context.setFocus(true)
        }}
        hasFocus={context.focus}
        onClick={onClick}
        icon={<SearchIcon />}
        isOpen
        clearsSearch={context.searchState?.query === null}
        defaultRefinement={context.defaultRefinement}
        context={context}
        reverseIcon
        name={`search-bar`}
      />
      {context.focus && (
        <LearnCentreSearchResultDropdown
          index={context.index}
          context={{ ...context }}
          suggested={suggested}
        />
      )}
      <div
        className={
          context.focus
            ? style["search-bar__overlay"]
            : style["search-bar__hidden"]
        }
      ></div>
    </div>
  )
}

export default SearchBar
