import React from "react"
import style from "./disclaimer.mod.scss"

const Disclaimer = ({ content, children, alignment, weight, width }) => {
  const modifiers = []
  alignment && modifiers.push(style[`disclaimer--${alignment}`])
  weight && modifiers.push(style[`disclaimer--${weight}`])
  width && modifiers.push(style[`disclaimer--width-${width}`])

  return (
    <div className={[style.disclaimer, ...modifiers].join(" ")}>
      {content && content.childMarkdownRemark && (
        <div
          className={style.disclaimer__caption}
          dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
        />
      )}
      {children}
    </div>
  )
}

export default Disclaimer
