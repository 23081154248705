import React from "react"
import cn from "classnames"
import style from "./birdeye-grid.mod.scss"
import { graphql, useStaticQuery } from "gatsby"
import ReviewCard from "./review-card"
import Masonry from "@mui/lab/Masonry"

const BirdeyeGrid = () => {
  const classes = cn(style.birdeyes)

  const data = useStaticQuery(graphql`
    {
      allBirdeyeReview(
        filter: { rating: { in: [5, 4] } }
        sort: { rdate: DESC }
        limit: 200
      ) {
        nodes {
          id
          businessId
          rating
          sourceType
          comments
          reviewDate
          businessName
          uniqueReviewUrl
          reviewer {
            name: nickName
          }
        }
      }
    }
  `)

  const items = data.allBirdeyeReview.nodes

  return (
    <div className={style.birdeyes__wrap}>
      <div className={classes}>
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
          {items.map(item => (
            <ReviewCard key={item.id} {...item} />
          ))}
        </Masonry>
      </div>
    </div>
  )
}

export default BirdeyeGrid
