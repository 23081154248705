import React from "react"
import { Image, Modal } from "@components"
import style from "./testimonial.mod.scss"
import Play from "@svgs/play.svg"
import { JsonLd } from "react-schemaorg"
import { useStaticQuery, graphql } from "gatsby"

const Testimonial = ({ image, quote, credit, video, slide = false }) => {
  const modifiers = []
  image && modifiers.push(`${style.testimonial}--image`)
  slide && modifiers.push(`${style.testimonial}--slide`)

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          lang
          checkoutURL
          siteUrl
          facebook
          instagram
          twitter
          pinterest
          title
          logo
          description
          phoneNumber
        }
      }
    }
  `)

  const schema = {
    "@context": "http://schema.org",
    "@type": "Review",
    reviewBody: quote,
    author: {
      "@type": "Person",
      name: credit
    },
    itemReviewed: {
      "@type": "Organization",
      name: site.siteMetadata.title,
      description: site.siteMetadata.description,
      telephone: site.siteMetadata.phoneNumber,
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: site.siteMetadata.phoneNumber,
          contactType: "customer service"
        }
      ],
      url: site.siteMetadata.siteUrl,
      logo: site.siteMetadata.logo
    }
  }

  return (
    <div className={[style.testimonial, ...modifiers].join(" ")}>
      {image && (
        <div className={style.testimonial__image}>
          <Image elevated image={image} />
          {video && (
            <div className={style.testimonial__video}>
              <Modal
                placement="center"
                video={video}
                icon={<Play />}
                ariaLabel={`Open modal and play video: ${video.title}`}
                gtm={{
                  event: `testimonial-engagement`,
                  label: `Testimonial Model Click`,
                  value: `${video.title}`
                }}
              ></Modal>
            </div>
          )}
        </div>
      )}
      <blockquote className={style.testimonial__blockquote}>
        <p className={style.testimonial__quote}>{quote}</p>
        <cite className={style.testimonial__credit}>{credit}</cite>
      </blockquote>
      <JsonLd item={schema} />
    </div>
  )
}

export default Testimonial
