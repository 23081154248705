import React, { useContext } from "react"
import { Grid, Card, Pill } from "@components"
import CartContext from "@context/cartContext"
import ProductContext, {
  getPricing,
  CurrencyFormatter
} from "@context/productContext"
import { AnimatePresence, motion } from "framer-motion"

const Upsells = ({ items = [] }) => {
  const { cart, toggle } = useContext(CartContext)
  const { productLoading, currentModel, parentProduct } = useContext(
    ProductContext
  )

  return (
    <Grid moduleStyle={"3-up-grid"}>
      <AnimatePresence>
        {items
          .sort((a, b) => a?.position - b?.position)
          .map(upsell => {
            // Remove invalid upsells from list
            if (
              !productLoading &&
              currentModel.upsells.indexOf(upsell.sku) < 0
            ) {
              return null
            }

            const prices = upsell.netoProduct && getPricing(upsell.netoProduct)
            const isSelected = cart.has(upsell.sku)
            const UpsellPill = () => (
              <Pill
                secondary
                elevated
                name={`select-${upsell.sku}`}
                value={upsell.sku}
                isChecked={isSelected}
                type="checkbox"
                onChange={({ target }) => {
                  toggle({
                    attachTo: parentProduct.sku,
                    type: upsell.netoProductType,
                    sku: upsell.sku,
                    id: upsell.id,
                    name: upsell.name,
                    prices: prices,
                    category: "upsell",
                    brand: upsell.brand?.name || ""
                  })
                }}
              >
                {isSelected ? `Added` : `Add to cart`}
              </Pill>
            )

            return (
              <motion.div
                layout
                key={`upsell-${upsell.id}`}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.25 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <Card
                  heading={upsell.name}
                  image={upsell.heroImage}
                  caption={upsell.excerpt}
                  subHeading={
                    prices?.price && <CurrencyFormatter value={prices.price} />
                  }
                  defaultModel={upsell.netoProduct?.defaultModel}
                  elevated
                  selected={isSelected}
                  pill={<UpsellPill />}
                  truncate={3}
                  upsellVideo={upsell.upsellVideo}
                />
              </motion.div>
            )
          })}
      </AnimatePresence>
    </Grid>
  )
}

export default Upsells
