import React from "react"
import { graphql } from "gatsby"
import style from "./blog-cta.mod.scss"
import { Block, Container, Button } from "@components"

const BlogCta = ({ heading, text, buttonText, link, color }) => {
  const classes = [
    style.blog_cta,
    style[`blog_cta__background--${color.toLowerCase()}`]
  ]

  return (
    <Container maxWidth="inner">
      <Block gutters>
        <div className={classes.join(" ")}>
          <div className={style.blog_cta__content}>
            <h3>{heading}</h3>
            <p>{text}</p>
          </div>
          <div className={style.blog_cta__button_wrap}>
            <Button {...link}>{buttonText}</Button>
          </div>
        </div>
      </Block>
    </Container>
  )
}

export default BlogCta

export const query = graphql`
  fragment BlogCta on DatoCmsBlogCta {
    id
    __typename
    heading
    text
    buttonText
    link {
      id
      path
    }
    color
    padding
    model {
      apiKey
    }
  }
`
