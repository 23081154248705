import React from "react"
import style from "./menu-item.mod.scss"
import ThemeContext from "../../context/themeContext"
import { Button, Superscript } from "@components"
import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import { motion } from "framer-motion"

const MenuItem = ({
  footer = false,
  modifiers = [],
  displayText,
  link,
  url,
  showIcon = false,
  backIcon = false,
  vertical = false,
  tabIndex = 0,
  isButton = false,
  isExternal = false,
  onClick,
  unstyled = false,
  isMobile = false
}) => {
  footer && modifiers.push(style["menu-item--footer"])
  vertical
    ? modifiers.push(style["menu-item--vertical"])
    : modifiers.push(style["menu-item--horizontal"])
  showIcon && modifiers.push(style["menu-item--has-icon"])
  backIcon && modifiers.push(style["menu-item--is-back"])
  isMobile && modifiers.push(style["menu-item--is-mobile"])

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 16,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  }

  return (
    <ThemeContext.Consumer>
      {({ isSidebarOpen, closeSidebar }) => {
        return (
          <motion.li
            className={[style["menu-item"], ...modifiers].join(" ")}
            variants={variants}
          >
            <Button
              onClick={onClick}
              tabIndex={tabIndex}
              isExternal={isExternal}
              type={isButton ? "button" : null}
              isMenuItem
              to={link && link.path ? link.path : url}
              unstyled={isButton && !unstyled ? false : true}
              activeClass="menu-item--current"
            >
              {backIcon && <FaAngleLeft className={style["menu-item__icon"]} />}
              <span>
                <Superscript>{displayText}</Superscript>
              </span>
              {showIcon && (
                <FaAngleRight className={style["menu-item__icon"]} />
              )}
            </Button>
          </motion.li>
        )
      }}
    </ThemeContext.Consumer>
  )
}

export default MenuItem
