import React, { useState, useEffect } from "react"
import SwiperCore, { Navigation, Thumbs } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import style from "./carousel.mod.scss"
import { Button, Image, Video, Disclaimer, Superscript } from "@components"
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft"
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight"

SwiperCore.use([Navigation, Thumbs])

const ThumbnailCarousel = ({
  items,
  showThumbnails = true,
  initialSlide = 0
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [mainSwiper, setMainSwiper] = useState(null)

  const mainConfig = {
    spaceBetween: 25,
    slidesPerView: 1.125,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    initialSlide,
    thumbs: { swiper: thumbsSwiper }
  }

  // Track and sync the active slide with thumbnails
  useEffect(() => {
    if (mainSwiper && thumbsSwiper) {
      const handleSlideChange = () => {
        const activeIndex = mainSwiper.activeIndex
        thumbsSwiper.slideTo(activeIndex) // Force update of thumbnail Swiper
      }

      mainSwiper.on("slideChange", handleSlideChange)

      // Clean up the event listener on unmount or update
      return () => mainSwiper.off("slideChange", handleSlideChange)
    }
  }, [mainSwiper, thumbsSwiper])

  return (
    <div
      className={`${style.carousel} ${style["carousel--simple"]} ${style["carousel--thumbnails"]}`}
    >
      <div className={style.carousel__container}>
        <Swiper
          {...mainConfig}
          onSwiper={setMainSwiper}
          breakpoints={{
            768: {
              slidesPerView: 1
            }
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={`slide--${item.id || index}`}>
              <CarouselSlide {...item} />
            </SwiperSlide>
          ))}
          <div className={style.carousel__buttons}>
            <CarouselPrev />
            <CarouselNext />
          </div>
        </Swiper>

        {showThumbnails && (
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={20}
            slidesPerView={2.5}
            centeredSlides={false}
            watchSlidesProgress
            watchSlidesVisibility
            className={style.thumbnail__wrap}
            loop={false}
            breakpoints={{
              768: {
                slidesPerView: 3
              },
              1024: {
                slidesPerView: 4,
                centeredSlides: false
              }
            }}
          >
            {items.map((item, index) => {
              return (
                <SwiperSlide
                  key={`thumb--${item.id || index}`}
                  className={style.thumbnail}
                >
                  {(item.defaultImage || item.video.image) && (
                    <GatsbyImage
                      loading="lazy"
                      image={
                        item.defaultImage?.gatsbyImageData ||
                        item.video.image.gatsbyImageData
                      }
                    />
                  )}
                  <div className={style.thumbnail__content}>
                    <p>{item.thumbnailText || item.heading}</p>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  )
}

const CarouselSlide = ({
  caption,
  heading,
  secondary = false,
  defaultImage,
  video,
  moduleStyle,
  subHeading = "",
  link,
  showImageDisclaimer
}) => {
  return (
    <div className={style.carousel__slide}>
      <div className={style.carousel__media}>
        {defaultImage && <Image image={defaultImage} elevated />}
        {video && (
          <Video video={video} image={video.image} moduleStyle={moduleStyle} />
        )}
        {moduleStyle === "simple" && showImageDisclaimer && (
          <div className={style.carousel__disclaimer}>
            <Disclaimer
              children="Image may show optional extras."
              alignment="left"
              width={"small"}
            />
          </div>
        )}
      </div>
      <div className={style.carousel__content}>
        {heading && (
          <h2
            className={style.carousel__heading}
            children={<Superscript children={heading} />}
          />
        )}
        {subHeading && (
          <h4
            className={style.carousel__subheading}
            children={<Superscript children={subHeading} />}
          />
        )}
        {caption && (
          <div
            className={style.carousel__caption}
            children={<Superscript children={caption} />}
          />
        )}
        {link && (
          <div className="swiper-no-swiping">
            <Button
              to={link.path}
              fullWidthMobile
              secondary={secondary}
              gaTarget={`${moduleStyle}-carousel-slide-button`}
            >
              {link.title}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const CarouselPrev = () => (
  <button aria-label="Previous" className="swiper-button-prev">
    <MdKeyboardArrowLeft />
  </button>
)

const CarouselNext = () => (
  <button aria-label="Next" className="swiper-button-next">
    <MdKeyboardArrowRight />
  </button>
)

export default ThumbnailCarousel
export { CarouselPrev, CarouselNext }
