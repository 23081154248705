/**
 * Known product options sorted by priority
 * @type {Array}
 */
const knownSpecifics = [
  {
    name: "Model Type",
    slug: "model-type",
    helpMessage: "How to choose model type >"
  },
  {
    name: "Package",
    slug: "package",
    helpMessage: "Why upgrade to Avante? >"
  },
  {
    name: "Depth",
    slug: "depth",
    helpMessage: "Compare depth options >"
  },
  {
    name: "Entertainment",
    slug: "features",
    helpMessage: "Entertainment options >"
  },
  {
    name: "Shell Colour",
    slug: "swatches",
    helpMessage: "Shell colour options >"
  },
  {
    name: "Cabinet Colour",
    slug: "swatches",
    helpMessage: "Cabinet colour options >"
  },
  {
    name: "Canopy Colour",
    slug: "swatches",
    helpMessage: "Canopy colour options >"
  },
  {
    name: "Colour",
    slug: "colour",
    helpMessage: "Colour options >"
  },
  {
    name: "Burner Colour",
    slug: "swatches",
    helpMessage: "Burner colour options >"
  },
  {
    name: "Size",
    slug: "size",
    helpMessage: "Size options >"
  },
  {
    name: "Fixing",
    slug: "fixing",
    helpMessage: "Fixing options >"
  },
  {
    name: "Edition",
    slug: "features",
    helpMessage: ""
  }
]

/**
 * Type to handle neto variants / Specifics stucture
 */
class Variants {
  constructor(variants, initialVariant) {
    this.list = variants && variants.length > 0 ? [...variants] : []
    // returns null if no variants
    this.defaultModel = getDefaultVariant(this.list, initialVariant.sku)
    // returns empty map if no variants
    this.options = this.getOptions(this.list)
    // returns blank map iterator if no default values
    this.defaultValues = this.getDefaultValues()
  }

  /**
   * Filters Specifics based on a value map
   * @param  {Map}    [values=new Map]        name value pair for form fields
   * @param  {Array}  [variants=this.list]    neto products variants
   * @return {Array}                          array of reduced variants
   */
  static filter(values = new Map(), variants = []) {
    const arr = []
    variants?.length > 0 &&
      variants.forEach(({ specifics, ...props }) => {
        const match = checkOptions(specifics, values)

        match &&
          arr.push({
            specifics,
            ...props
          })
      })

    return arr
  }

  filterVariants(values = new Map(), variants = this.list) {
    return Variants.filter(values, variants)
  }

  /**
   * For each option, create a blank value
   * @param  {Map}    [values=new Map()]   name and option array map for form fields
   * @return {Map}                          name value pair for form fields
   */
  getDefaultValues(values = new Map()) {
    if (this.defaultModel && this.defaultModel !== undefined) {
      return this.getVariantValues(this.defaultModel)
    }

    ;[...this.options.keys()].forEach(item => values.set(item, ""))

    return values
  }

  getVariantValues(variant, values = new Map()) {
    variant.specifics.forEach(({ name, value }) => values.set(name, value))
    return values
  }

  /**
   * Flattens variant array
   * @param  {Array}  [arr=[]] array of objects with a single property
   * @return {[type]}          array of Specifics for all models
   */
  flatten(variants, arr = []) {
    variants.forEach(({ specifics }) => {
      arr = [...arr, ...specifics]
    })
    return arr
  }

  /**
   * Convert Specifics to a format that can be outputted as a select list
   * @return {Map}
   */
  getOptions(variants = this.list) {
    const allSpecifics = this.flatten(variants)
    const dedupedSpecs = groupOptions(allSpecifics)

    let sortedOptions
    try {
      sortedOptions = sortOptions(dedupedSpecs)
    } catch (e) {
      console.log("failed to sort options", e)
      sortedOptions = dedupedSpecs
    }
    return sortedOptions
  }

  /**
   * Get all valid options based on current values
   *
   * @param  {Map}    [currentValues=new Map()]
   * @return {Map}    validOptions
   */
  getValidOptions(currentValues = new Map(), optionsArr = this.options) {
    // for each option type.
    let allValidVariants = []

    // Get all options and omit current option.
    optionsArr.forEach((options, name) => {
      const optionValues = new Map(currentValues)
      optionValues.delete(name)

      // Filter list base on these options.
      const possibleVariants = this.filterVariants(optionValues)
      allValidVariants = [...allValidVariants, ...possibleVariants]
    })

    // Get options base on remaining specifics.
    const validOptions = this.getOptions(allValidVariants)

    return validOptions
  }
}

/**
 * Take all options. Sort by known specifics then add remaining options to the end.
 * @param {Map} options
 * @return {Map} sortedOptions
 */
const sortOptions = options => {
  const sortedOptions = new Map()

  // Sort known options
  knownSpecifics.forEach(({ name }) => {
    if (options.has(name)) {
      sortedOptions.set(name, options.get(name))
      options.delete(name)
    }
  })

  // Sort known options
  options.forEach((value, key) => {
    sortedOptions.set(key, options.get(key))
  })

  // Add remaining options
  return sortedOptions
}

/**
 * Check neto specifics against values Map
 * @param {Array} specifics
 * @param {Map} values
 * @param {Boolean} match
 * @returns
 */
const checkOptions = (specifics, values, match = true) => {
  // if one of the specific options can't find a match in the values then fail the variant
  for (let option of specifics) {
    if (values.get(option.name) && values.get(option.name) !== option.value) {
      match = false
    }
  }
  return match
}

/**
 * Group array values by name
 * @param {Array} arr
 * @returns {Map}
 */
const groupOptions = (arr = []) => {
  const groups = new Map()

  if (!arr.length > 0) return groups

  for (let { name, value } of arr) {
    // Is new group
    if (!groups.has(name)) {
      // Create group and value
      groups.set(name, new Set([value]))
    } else {
      // Add new value into set
      groups.set(name, groups.get(name).add(value))
    }
  }

  // Convert value set to array. This is the expected type thoughout the app.
  groups.forEach((value, name) => {
    groups.set(name, [...value.values()])
  })

  return groups
}

/**
 * Take a sku and find it in variant data. Or set the default model. Or
 * just get the first
 * @return {Object} NetoProduct
 */
const getDefaultVariant = (netoVariants, initialVariantSku) => {
  if (!netoVariants || !netoVariants.length > 0) return null

  if (initialVariantSku) {
    return netoVariants.find(({ sku }) => sku === initialVariantSku)
  }

  return (
    netoVariants.find(({ defaultModel }) => defaultModel) || netoVariants[0]
  )
}

/**
 * Takes neto's standard specs array and transforms it into a map.
 * @param {Array} objArray
 * @returns {Map}
 */
const getOptionsMap = objArray =>
  new Map(objArray.map(({ name, options, value }) => [name, options || value]))

/**
 * Finds render in render array given current neto variant and option type map
 * @param {Array} rendersArray
 * @param {Object} currentVariant
 * @param {Map} optionTypes
 * @returns {Object}
 */
const getCurrentRenderFromVariant = (
  rendersArray,
  currentVariant,
  optionTypes = new Map([["colourOne", "colourTwo"]])
) => {
  const currentValues = getOptionsMap(currentVariant.specifics)
  const currentRender = rendersArray.find(render => {
    let match = true

    // Loop through option types and match render color values to options
    optionTypes.forEach((value, key) => {
      const renderOptionValue = render[key].name
      const currentOptionValue = currentValues.get(value)

      const result =
        !renderOptionValue || currentOptionValue === renderOptionValue

      if (!result) match = result
    })

    return match
  })

  if (!currentRender) {
    console.log(
      `No render found for the selected ${Array.from(
        optionTypes,
        ([name, value]) =>
          `${(currentValues.get(name), currentValues.get(value))}`
      ).join(" and ")} combination.`
    )
  }

  return currentRender
}

export default Variants

export { knownSpecifics, getOptionsMap, getCurrentRenderFromVariant }
