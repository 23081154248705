import React from "react"
import style from "./contact.mod.scss"
import { Input, Select, Textarea } from "@components"

const ContactWarrantyForm = ({ register }) => {
  return (
    <>
      <div className={style.contact__row}>
        <Input
          label={"Your Phone Number"}
          type="tel"
          name="phone"
          {...register("phone")}
          required
          placeholder={"(##) ### #### or ### ### ####"}
          fullWidth
        />
        <Input
          label={"Approximate Delivery Date"}
          type="date"
          name="deliveryDate"
          {...register("deliveryDate")}
          required
          placeholder={"Enter delivery date"}
          fullWidth
        />
      </div>
      <div className={style.contact__row}>
        <Select
          label={"Product Type"}
          required
          name="productType"
          {...register("productType")}
          options={[
            {
              value: "Spa",
              text: "Spa"
            },
            {
              value: "Swim Spa",
              text: "Swim Spa"
            },
            {
              value: "Sauna",
              text: "Sauna"
            },
            {
              value: "Pergola",
              text: "Pergola"
            },
            {
              value: "Outdoor Fires",
              text: "Outdoor Fires"
            },
            {
              value: "Accessories",
              text: "Accessories"
            }
          ]}
        />
        <Input
          label={"Serial Number"}
          type="text"
          name="serialNumber"
          {...register("serialNumber")}
          placeholder={"Enter your serial number"}
          fullWidth
        />
      </div>
      <div>
        <Input
          label={"Describe Issue"}
          name="message"
          {...register("message")}
          required
          placeholder={"Describe the issue here"}
          multiline
        />
      </div>
      <div>
        <Input
          label={"Attachment"}
          type="file"
          name="attachment"
          fullWidth
          id={"fileInput"}
          {...register("attachment")}
          accept="image/*"
        />
      </div>
    </>
  )
}

export { ContactWarrantyForm }
