import React from "react"
import style from "./mega-menu.mod.scss"
import { MenuItem, Card } from "@components"
import { motion } from "framer-motion"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "./button"

const MegaMenu = ({ columns, isOpen, index, modifiers = [] }) => {
  const variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      opacity: 0,
      y: 32,
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  }

  return (
    <div
      className={[
        style["mega-menu"],
        `${isOpen ? style["mega-menu--open"] : style["mega-menu--closed"]}`,
        ...modifiers
      ].join(" ")}
      aria-labelledby={`menu-${index}-button`}
      id={`menu-${index}-button`}
    >
      <motion.div
        className={style["mega-menu__container"]}
        initial={"closed"}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <div className={style["mega-menu__wrapper"]}>
          {columns.map(column => {
            if (column.__typename === "DatoCmsMenuLearnColumn") {
              const {
                heading,
                id,
                collections,
                placeholderImage,
                linkPath
              } = column

              return (
                <div
                  className={[style[`mega-menu__column`]].join(" ")}
                  key={`mega-menu__column--${id}`}
                >
                  {collections?.length > 0 && (
                    <>
                      <div className={style["mega-menu__category-link"]}>
                        <Button unstyled to={linkPath || "/"}>
                          <h3 className={style["mega-menu__heading"]}>
                            {heading}
                          </h3>
                          {placeholderImage && (
                            <GatsbyImage
                              image={placeholderImage.gatsbyImageData}
                            />
                          )}
                        </Button>
                      </div>
                      <motion.ul
                        initial={"closed"}
                        animate={isOpen ? "open" : "closed"}
                        variants={variants}
                        className={style["mega-menu__list"]}
                      >
                        {collections.map(
                          ({ path, slug, title, id, parentCategory }) => {
                            let itemUrl = `/learn/${slug}`

                            if (parentCategory) {
                              itemUrl =
                                parentCategory.length > 0
                                  ? `/learn/${parentCategory[0].slug}/${slug}`
                                  : itemUrl
                            }

                            return (
                              <MenuItem
                                vertical={true}
                                key={`mega-menu__item--${id}`}
                                displayText={title}
                                link={{ path }}
                                url={itemUrl}
                                tabIndex={isOpen ? 0 : -1}
                                unstyled
                              />
                            )
                          }
                        )}
                      </motion.ul>
                    </>
                  )}
                </div>
              )
            } else {
              const { heading, id, collections, tile } = column

              return (
                <div
                  className={[
                    style[`mega-menu__column`],
                    tile && style[`mega-menu__column--tile`]
                  ].join(" ")}
                  key={`mega-menu__column--${id}`}
                >
                  {collections?.length > 0 && !tile && (
                    <>
                      <h3 className={style["mega-menu__heading"]}>{heading}</h3>
                      <motion.ul
                        initial={"closed"}
                        animate={isOpen ? "open" : "closed"}
                        variants={variants}
                        className={style["mega-menu__list"]}
                      >
                        {collections.map(item => {
                          let customlink

                          if (item.link) {
                            customlink = item.link.link?.path
                          } else {
                            customlink = item.path
                          }
                          return (
                            <MenuItem
                              vertical={true}
                              key={`mega-menu__item--${item.id}`}
                              displayText={item.name}
                              link={{ path: customlink, url: item.link?.url }}
                              tabIndex={isOpen ? 0 : -1}
                            />
                          )
                        })}
                      </motion.ul>
                    </>
                  )}

                  {tile && (
                    <motion.div
                      initial={"closed"}
                      animate={isOpen ? "open" : "closed"}
                      variants={variants}
                      className={style["mega-menu__tile"]}
                    >
                      <Card
                        key={`tile-${tile.id}`}
                        {...tile}
                        link={
                          tile?.link?.link?.path || tile?.link?.url
                            ? {
                                path: tile?.link?.link?.path || tile.link.url,
                                title: tile.link.displayText
                              }
                            : null
                        }
                        pathway
                        elevated
                        truncate={3}
                      />
                    </motion.div>
                  )}
                </div>
              )
            }
          })}
        </div>
      </motion.div>
    </div>
  )
}

export default MegaMenu
