import Variants, { knownSpecifics, getOptionsMap } from "@helpers/variants"

const AVANTE_NAMESPACE = "Package"
const AVANTE_VALUE = "Avanté™"
const NON_AVANTE_VALUE = "Standard"

const avanteSpecific = knownSpecifics.find(
  ({ name }) => name === AVANTE_NAMESPACE
)

function isCartItemAvante(item) {
  let result = false

  try {
    result =
      item.variantDetails.specifics.find(
        ({ name, value }) => name === AVANTE_NAMESPACE && value === AVANTE_VALUE
      ) !== undefined
  } catch (e) {
    // console.log("Unable to find avante upgrade")
  }

  return result
}

function isCartItemUpgradable(item) {
  let result = false

  try {
    result =
      item.variantDetails.specifics.find(
        ({ name }) => name === AVANTE_NAMESPACE
      ) !== undefined
  } catch (e) {
    // console.log("Unable to find avante upgrade")
  }

  return result
}

function hasAvanteOption(parent) {
  let result = false

  try {
    result =
      parent.productOptions.find(
        ({ name, options }) =>
          name === AVANTE_NAMESPACE && options.includes(AVANTE_VALUE)
      ) !== undefined
  } catch (e) {
    console.log("Avante upgrade is not one of the package options")
  }

  return result
}

function getAvanteComparisonVariant(currentVariant, allVariants) {
  const currentValues = getOptionsMap(currentVariant.specifics)
  const currentPackage = currentValues.get(AVANTE_NAMESPACE)
  const isAvante = currentPackage === AVANTE_VALUE

  const compareValues = new Map(currentValues)
  compareValues.set(
    AVANTE_NAMESPACE,
    isAvante ? NON_AVANTE_VALUE : AVANTE_VALUE
  )

  const tempCompareVariants = Variants.filter(compareValues, allVariants)

  if (tempCompareVariants.length < 1) {
    console.error(
      `Could not find an avante model comparison for ${currentVariant.sku}`
    )
  }

  return tempCompareVariants[0]
}

export {
  isCartItemAvante,
  isCartItemUpgradable,
  AVANTE_NAMESPACE,
  AVANTE_VALUE,
  NON_AVANTE_VALUE,
  avanteSpecific,
  getAvanteComparisonVariant,
  hasAvanteOption
}
