import React, { useState } from "react"
import { Block, Card, Grid, Button, VideoCard, Modal } from "@components"
import style from "./featured-cards.mod.scss"

const FeaturedCards = ({ items, pageSize = 6, featured = false }) => {
  const [itemsDisplayed, setItemsDisplayed] = useState(pageSize)
  const hasMore = items.length > itemsDisplayed

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoInModal, setVideoInModal] = useState(null)

  const toggleIsModalOpen = boolean => setIsModalOpen(boolean)

  const initVideo = video => {
    // set video to use in modal
    setVideoInModal(video)

    //  set modal open
    toggleIsModalOpen(true)
  }

  const loadMoreItems = () => {
    if (itemsDisplayed < items.length) {
      setItemsDisplayed(itemsDisplayed + pageSize)
    }
  }
  return (
    <>
      {featured && (
        <div className={style.featured_cards__heading}>
          <h2>{"Featured"}</h2>
        </div>
      )}
      <Grid moduleStyle={"2-up-grid"}>
        {items.slice(0, itemsDisplayed).map(item => {
          if (item.video) {
            return (
              <VideoCard
                key={`search-video-card--${item.id}`}
                caption={item.caption}
                heading={item.title || item.heading}
                overlayImage={item.overlayImage}
                onClick={initVideo}
                elevated
                video={item.video}
                isLearnCard
                meta={item.meta}
              />
            )
          } else {
            return (
              <Card
                key={`search-card--${item.id}`}
                heading={item.title || item.heading}
                image={item.heroImage}
                link={{ url: item.path, title: `Learn More` }}
                caption={item.excerpt}
                truncate={3}
                elevated
                isClickable
                isLearnCard
                itemId={item.id}
              />
            )
          }
        })}
      </Grid>
      {hasMore && (
        <Block padding={`double`}>
          <Button
            type="button"
            onClick={loadMoreItems}
            children="Load More"
            fullWidthMobile
            block
            short
          />
        </Block>
      )}
      <Modal
        onChange={toggleIsModalOpen}
        globalState={isModalOpen}
        video={videoInModal}
        ariaLabel={`Open modal and play video`}
        gtm={{
          event: `video-engagement`,
          label: `Video Model Click`,
          value: videoInModal?.title
        }}
      />
    </>
  )
}

export default FeaturedCards
