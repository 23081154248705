import React, { useState } from "react"
import style from "./show-more.mod.scss"
import { Button } from "@components"
import { isBrowser } from "@context/themeContext"
import { motion } from "framer-motion"
import { getHeight } from "@helpers/miscHelpers"

const ShowMore = ({
  children,
  enabled = false,
  maxHeight = 250,
  contentId = "",
  mobileOnly = false,
  background
}) => {
  const [state, setState] = useState({ isExpanded: null, contentHeight: 1000 })

  const mql = isBrowser() ? window.matchMedia("(max-width: 1024px)") : false

  const isMobile = mql.matches

  const modifiers = []
  state.isExpanded && modifiers.push(style["show-more--expanded"])
  background && modifiers.push(style[`show-more--${background}`])

  const disableForDesktop = mobileOnly && !isMobile

  const variants = {
    open: {
      maxHeight: `${state.contentHeight}px`,
      transition: {
        type: "spring",
        stiffness: 40,
        restDelta: 2
      }
    },
    closed: {
      maxHeight: `${maxHeight}px`,
      overflow: "hidden",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  }

  return (
    <>
      {enabled && !disableForDesktop ? (
        <div className={[style["show-more"], ...modifiers].join(" ")}>
          <motion.div
            className={style["show-more__inner"]}
            style={{ position: "relative" }}
            variants={variants}
            initial={"closed"}
            animate={state.isExpanded ? "open" : "closed"}
          >
            {children}
          </motion.div>
          <div className={style["show-more__button"]}>
            <Button
              type="button"
              onClick={() =>
                setState({
                  isExpanded: !state.isExpanded,
                  contentHeight: getHeight(`${contentId}`)
                })
              }
              children={state.isExpanded ? "Show Less" : "Show More"}
              wide
            />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default ShowMore
