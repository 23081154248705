import React from "react"
import { ThemeProvider } from "@context/themeContext"
import { ChooserProvider } from "@context/chooserContext"
import { CartProvider } from "@context/cartContext"
import { ProductProvider } from "@context/productContext"
import { FoneDynamicsProvider } from "@context/foneDynamicsContext"
import vhCheck from "vh-check"
import typography from "@helpers/typography"
import "./src/assets/scss/reset.global.scss"
import "./src/assets/scss/styles.global.scss"
import { gtmPush } from "@helpers/gtmHelpers"
// eslint-disable-next-line
import useWhatInput from "react-use-what-input"
import { SearchProvider } from "@context/searchContext"
import { LearnCentreSearchProvider } from "./src/context/learnCentreSearchContext"
import { ModalProvider } from "./src/context/modalContext"
import RivaModal from "./src/partials/components/riva/modal"

let journeyDepth = 0

const checkoutURL = `${process.env.GATSBY_NETOAPI_DOMAIN}/cartload`

export const wrapRootElement = ({ element }) => {
  typography.injectStyles()
  vhCheck({
    cssVarName: "vh-offset",
    redefineVh: true,
    bind: true,
    updateOnTouch: true
  })

  return (
    <ThemeProvider>
      <CartProvider checkoutURL={checkoutURL}>
        <ProductProvider>
          <FoneDynamicsProvider>
            <ChooserProvider>
              <SearchProvider>
                <ModalProvider>
                  <LearnCentreSearchProvider>
                    {element}
                    <RivaModal />
                  </LearnCentreSearchProvider>
                </ModalProvider>
              </SearchProvider>
            </ChooserProvider>
          </FoneDynamicsProvider>
        </ProductProvider>
      </CartProvider>
    </ThemeProvider>
  )
}

export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    journeyDepth++

    setTimeout(() => {
      gtmPush({
        event: `gatsby-route-change`,
        journeyDepth
      })
    }, 50)
  }

  // Fone Dynmaics. Don't track while developing.
  if (
    process.env.NODE_ENV === `production` &&
    typeof window._ctq === `object`
  ) {
    window._ctq.push(["trackPageView"])
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
