import React from "react"
import style from "./learn-centre-navigation.mod.scss"
import { Link } from "gatsby"
import { Superscript, Container, Select } from "@components"
import StateResults from "../components/search/state-results"
import SearchSelect from "../components/search/search-select"

const LearnCentreNavigation = ({
  breadcrumbs,
  hasResults,
  categories,
  filters = [],
  onChange = () => {}
}) => {
  filters.length > 0 &&
    filters[0]?.text !== "Refine By" &&
    filters.unshift({ value: "", text: `Refine By` })

  return (
    <nav className={style.lc_navigation} aria-label="learn-centre-breadcrumb">
      {breadcrumbs && (
        <Container>
          <div className={style.lc_navigation__wrap}>
            <div className={style.lc_navigation__breadcrumb}>
              <ul className={style.lc_navigation__list}>
                {breadcrumbs.slice(-3).map(({ path, title }, i) => {
                  const newTitle = title === "Learn" ? "Learn Centre" : title
                  return (
                    <>
                      <li
                        key={`${i}-${title}`}
                        className={style.lc_navigation__item}
                      >
                        <Link
                          to={path}
                          children={<Superscript children={newTitle} />}
                        />
                      </li>
                    </>
                  )
                })}
              </ul>
              {hasResults ? (
                <StateResults hasResults={hasResults} />
              ) : (
                <h3 className={style.lc_navigation__heading}>
                  {breadcrumbs.slice(-1)[0].title}
                </h3>
              )}
            </div>
            {categories && (
              <div className={style.lc_navigation__select}>
                <div className={style.lc_navigation__topline}></div>
                <SearchSelect tags={categories.nodes} />
              </div>
            )}
            {filters.length > 0 && (
              <div className={style.lc_navigation__select}>
                <Select
                  name={"filters"}
                  options={filters}
                  onChange={onChange}
                />
              </div>
            )}
          </div>
        </Container>
      )}
    </nav>
  )
}

export default LearnCentreNavigation
