import React from "react"
import cn from "classnames"
import style from "./tile.mod.scss"
import { Card } from "../components"

const Tile = ({ name, id, onClick, image }) => {
  return (
    <div className={style.tile} onClick={onClick} key={id}>
      <Card heading={name} image={image} pathway elevated tiled />
    </div>
  )
}

export default Tile
