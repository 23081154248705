import React from "react"
import style from "./input.mod.scss"
import textAreaStyle from "./textarea.mod.scss"
import CloseIcon from "@svgs/close.svg"

const Input = React.forwardRef(
  (
    {
      label,
      name,
      type = "text",
      placeholder,
      ErrorMessage,
      required,
      pattern,
      value,
      fullWidth,
      disabled = false,
      ariaLabel,
      onChange,
      onFocus,
      focus,
      icon,
      onIconClick,
      multiline = false,
      autoComplete = "on",
      reverseIcon = false,
      cancelEnter = false
    },
    ref
  ) => {
    const modifiers = []
    ErrorMessage && modifiers.push(style["input--error"])
    fullWidth && modifiers.push(style["input--full-width"])
    icon && modifiers.push(style["input--with-icon"])
    reverseIcon && modifiers.push(style["input--with-icon-reverse"])

    const inputConfig = {
      id: `input--${name}`,
      type,
      name,
      ref,
      pattern,
      placeholder,
      required,
      value,
      onChange,
      onFocus,
      autoComplete
    }

    const handleKeyDown = e => {
      if (e.key === "Enter") {
        e.preventDefault()
      }
    }

    if (multiline) {
      return (
        <div className={textAreaStyle.textarea}>
          {label && (
            <label htmlFor={`input--${name}`}>
              {label}
              {required && <span aria-label="required">*</span>}
            </label>
          )}
          <textarea {...inputConfig} />
        </div>
      )
    }

    return (
      <div className={[style.input, ...modifiers].join(" ")}>
        {name === "search-bar" && focus && (
          <div className={style.input__icon_close} onClick={onIconClick}>
            <CloseIcon />
          </div>
        )}
        {label && (
          <label htmlFor={`input--${name}`}>
            {label}
            {required && <span aria-label="required">{`*`}</span>}
          </label>
        )}

        {multiline ? (
          <textarea {...inputConfig} />
        ) : cancelEnter ? (
          <input
            {...inputConfig}
            disabled={disabled}
            aria-label={ariaLabel}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <input {...inputConfig} disabled={disabled} aria-label={ariaLabel} />
        )}
        {icon && (
          <div className={style.input__icon} onClick={onIconClick}>
            {icon}
          </div>
        )}
        {ErrorMessage && (
          <div className={style.input__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    )
  }
)

export default Input
