import React from "react"
import { Location } from "@reach/router"

const isBrowser = () => typeof window !== "undefined" && window

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(0px at calc(100% - 32px) 48px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}

const sidebarWrapper = {
  open: {
    display: "block"
  },
  closed: {
    display: "none",
    transition: {
      delay: 1.5
    }
  }
}

const defaultState = {
  isSidebarOpen: false,
  isCartOpen: false,
  toggleSidebar: () => {},
  setCartOpen: () => {},
  isSearchOpen: false,
  setSearchOpen: () => {},
  setDepth: () => {},
  productDepth: 0,
  blogDepth: 0,
  videoViewDepth: 0,
  isCartPopup: false,
  setIsCartPopup: () => {}
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    isSidebarOpen: false,
    isCartOpen: false,
    isSearchOpen: false,
    productDepth: 0,
    blogDepth: 0,
    videoViewDepth: 0,
    isCartPopup: false
  }

  toggleSidebar = () => {
    let isSidebarOpen = !this.state.isSidebarOpen
    localStorage.setItem("Theme.isSidebarOpen", JSON.stringify(isSidebarOpen))

    this.toggleBodyScroll(isSidebarOpen)

    this.setState({ isSidebarOpen })
  }

  setCartOpen = isCartOpen => {
    this.setState({ isCartOpen })
  }

  setIsCartPopup = isCartPopup => {
    this.setState({ isCartPopup })
  }

  setSearchOpen = isSearchOpen => {
    this.setState({ isSearchOpen })
  }

  closeSidebar = () => {
    localStorage.setItem("Theme.isSidebarOpen", JSON.stringify(false))
    this.toggleBodyScroll(false)
    this.setState({ isSidebarOpen: false })
  }

  openSidebar = () => {
    localStorage.setItem("Theme.isSidebarOpen", JSON.stringify(true))
    this.toggleBodyScroll(true)
    this.setState({ isSidebarOpen: true })
  }

  toggleBodyScroll = enable => {
    if (enable) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.overflow = ""
      document.body.style.overflowX = "hidden"
      document.documentElement.style.overflowX = "hidden"
    }
  }

  setDepth = (type, depth) => {
    this.setState({
      [type]: depth
    })
  }

  componentDidMount() {
    const lsisSidebarOpen = JSON.parse(
      localStorage.getItem("Theme.isSidebarOpen")
    )

    this.toggleBodyScroll(lsisSidebarOpen)

    if (lsisSidebarOpen) {
      this.setState({ isSidebarOpen: lsisSidebarOpen })
    }

    this.setState({
      blogDepth: 0,
      productDepth: 0,
      videoViewDepth: 0
    })
  }

  render() {
    const { children } = this.props
    const {
      isSidebarOpen,
      isCartOpen,
      isSearchOpen,
      productDepth,
      blogDepth,
      videoViewDepth,
      isCartPopup
    } = this.state
    return (
      <Location>
        {({ location }) => {
          return (
            <ThemeContext.Provider
              value={{
                location,
                isSidebarOpen,
                isCartOpen,
                isSearchOpen,
                toggleSidebar: this.toggleSidebar,
                openSidebar: this.openSidebar,
                closeSidebar: this.closeSidebar,
                setCartOpen: this.setCartOpen,
                setSearchOpen: this.setSearchOpen,
                setDepth: this.setDepth,
                productDepth,
                blogDepth,
                videoViewDepth,
                setIsCartPopup: this.setIsCartPopup,
                isCartPopup
              }}
            >
              {children}
            </ThemeContext.Provider>
          )
        }}
      </Location>
    )
  }
}
export default ThemeContext

export { ThemeProvider, isBrowser, sidebar, sidebarWrapper }
