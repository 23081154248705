import React from "react"
import style from "./search-select.mod.scss"
import { Select } from "@components"
import { connectMenu } from "react-instantsearch-dom"

const MenuSelect = ({ items, currentRefinement, refine, tags }) => {
  const selectOptions = items.map(item => ({
    value: item.isRefined ? currentRefinement : item.value,
    text: `${item.label} (${item.count})`
  }))
  selectOptions.unshift({ value: "", text: `Refine By` })

  const tagsOrder = tags
    .sort((a, b) => a.position - b.position)
    .map(({ name }) => name)
  const sortedOptions = selectOptions.sort(
    (a, b) => tagsOrder.indexOf(a.value) - tagsOrder.indexOf(b.value)
  )

  return (
    <Select
      name={"categories"}
      options={sortedOptions}
      onChange={event => refine(event.currentTarget.value)}
      value={currentRefinement || ""}
    />
  )
}

const CustomMenuSelect = connectMenu(MenuSelect)

const SearchSelect = ({ attribute = "categories", tags = [] }) => {
  return (
    <div className={style[`search-select`]}>
      <CustomMenuSelect attribute={attribute} tags={tags} />
    </div>
  )
}

export default SearchSelect
