import React from "react"
import style from "./not-found-page-intro.mod.scss"
import { Lead, Container, Button } from "@components"

const NotFoundPageIntro = ({ title, lead, link, logo }) => {
  return (
    <Container>
      <div className={style["not-found-page-intro"]}>
        <div className={style["not-found-page-intro__container"]}>
          <div className={style["not-found-page-intro__wrapper"]}>
            {logo && (
              <div className={style["not-found-page-intro__logo"]}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            )}
            {title && (
              <h1
                className={style["not-found-page-intro__title"]}
                children={title}
              />
            )}
            {lead && <Lead body={lead} />}
            {link && (
              <Button secondary children={link.displayText} to={link.path} />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default NotFoundPageIntro
