import React from "react"
import style from "./recommendation.mod.scss"
import ChooserContext from "@context/chooserContext"
import { Pill } from "@components"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"

const Recommendation = ({ productId, moduleStyle }) => {
  if (!productId) return null

  const modifiers = []

  moduleStyle && modifiers.push(`${style.recommendation}--${moduleStyle}`)

  return (
    <ChooserContext.Consumer>
      {({ recommendations }) => {
        if (!recommendations || recommendations === undefined) return null

        const matches = recommendations.find(
          recommendation => recommendation.id === productId
        )

        return matches ? (
          <div className={[style.recommendation, ...modifiers].join(" ")}>
            <Pill
              icon={<FaCheck />}
              moduleStyle="large-multiline"
              children="Recommended by Product Selector"
            />
          </div>
        ) : null
      }}
    </ChooserContext.Consumer>
  )
}

export default Recommendation
