import React from "react"
import { graphql } from "gatsby"
import { Accordion, Block, Container } from "../components"

const BlogAccordion = ({ id, accordionItem, maxWidth = "inner" }) => {
  return (
    <Container maxWidth={maxWidth}>
      <Block gutters>
        <Accordion items={accordionItem} firstOpen id={id} />
      </Block>
    </Container>
  )
}

export default BlogAccordion

export const query = graphql`
  fragment BlogAccordion on DatoCmsBlogAccordion {
    id
    __typename
    accordionItem {
      heading
      text
      id
    }
    padding
    model {
      apiKey
    }
  }
`
