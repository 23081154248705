import React from "react"
import ModalVideo from "react-modal-video"
import CSSTransition from "react-transition-group/CSSTransition"

export default class CustomModal extends ModalVideo {
  constructor(props) {
    super(props)
  }

  render() {
    const style = {
      paddingBottom: this.props.noPadding
        ? 0
        : this.getPadding(this.props.ratio)
    }

    const modifiers = []

    this.props.background &&
      modifiers.push([
        `modal-video--background-${this.props.background
          .toLowerCase()
          .replace(/ /gi, "-")}`
      ])
    this.props.noPadding && modifiers.push([`modal-video--no-padding`])
    this.props.overflowScroll &&
      modifiers.push([`modal-video--overflow-scroll`])
    this.props.flexInner && modifiers.push([`modal-video--flex-inner`])

    return (
      <CSSTransition
        classNames={this.props.classNames.modalVideoEffect}
        timeout={this.props.animationSpeed}
      >
        {() => {
          if (!this.state.isOpen) {
            return null
          }

          return (
            <div
              className={[this.props.classNames.modalVideo, ...modifiers].join(
                " "
              )}
              tabIndex="-1"
              role="dialog"
              aria-label={this.props.aria.openMessage}
              ref={node => {
                this.modal = node
              }}
              onKeyDown={this.updateFocus}
            >
              <div className={this.props.classNames.modalVideoBody}>
                <div className={this.props.classNames.modalVideoInner}>
                  <div
                    className={this.props.classNames.modalVideoIframeWrap}
                    style={style}
                  >
                    <button
                      className={this.props.classNames.modalVideoCloseBtn}
                      aria-label={this.props.aria.dismissBtnMessage}
                      ref={node => {
                        this.modalbtn = node
                      }}
                      onKeyDown={this.updateFocus}
                      onClick={this.closeModal}
                    />
                    {this.props.children || (
                      <iframe
                        width="460"
                        height="230"
                        src={this.getVideoUrl(this.props, this.props.videoId)}
                        frameBorder="0"
                        allow={
                          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        }
                        allowFullScreen={this.props.allowFullScreen}
                        tabIndex="-1"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={"modal-video-close"}
                onClick={this.closeModal}
                aria-label={this.props.aria.dismissBtnMessage}
              />
            </div>
          )
        }}
      </CSSTransition>
    )
  }
}
