import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import {
  Intro,
  Container,
  Location,
  Block,
  Embed,
  Banner,
  Reviews
} from "@components"
import Layout from "@layouts/layout"
import ModuleArea from "../helpers/moduleArea"
import { JsonLd } from "react-schemaorg"
import JacuzziHero from "../partials/jacuzzi/hero"

const Showroom = ({ data, pageContext }) => {
  const {
    id,
    title,
    seoMetaTags,
    leadNode,
    lead,
    businessName,
    placeholder,
    content,
    addressLine1,
    addressLine2,
    city,
    state,
    postcode,
    primaryPhoneNumber,
    path,
    tour,
    openHours,
    hero,
    banner,
    isJacuzzi,
    salesPhoneNumber,
    partsAndAccessoriesText,
    partsAndAccessoriesUrl,
    partsPhoneNumber,
    isCustom,
    hideAddress,
    customBox,
    customBoxLink
  } = data.showroom

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title,
    lead: leadNode?.childMarkdownRemark?.html
  }

  const locationConfig = {
    name: businessName,
    ...data.showroom
  }

  const callUsNumber =
    primaryPhoneNumber || salesPhoneNumber || partsPhoneNumber

  const schemaOpenHours = openHours.map(({ microdata }) => microdata)

  const schema = {
    "@context": "https://schema.org",
    "@type": "Store",
    "@id": path,
    name: businessName,
    telephone: callUsNumber,
    address: {
      "@type": "PostalAddress",
      streetAddress: `${addressLine1} ${addressLine2}`,
      addressLocality: city,
      addressRegion: state,
      postalCode: postcode,
      addressCountry: process.env.GATSBY_LANGUAGE.replace("en-", "")
    },
    url: path,
    openingHoursSpecification: [...schemaOpenHours],
    image: hero?.url
  }

  // Conditionally adding aggregateRating
  if (pageContext.rating.reviewCount > 0) {
    schema.aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: pageContext.rating.avgRating,
      reviewCount: pageContext.rating.reviewCount,
      bestRating: "5",
      worstRating: "0"
    }
  }

  return (
    <Layout isJacuzzi={isJacuzzi}>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block gutters={!isJacuzzi}>
        {isJacuzzi ? (
          <JacuzziHero
            title={title}
            caption={leadNode.childMarkdownRemark}
            image={hero}
            // cta={data.collection.jacuzziHeroLink}
            breadcrumbs={pageContext.breadcrumbs}
            showroom
          />
        ) : (
          <Intro {...introConfig} banner={banner} rating={pageContext.rating} />
        )}
      </Block>
      <Block gutters>
        <Location {...locationConfig} isJacuzzi={isJacuzzi} />
      </Block>
      <Container>
        {tour && (
          <Block gutters padding="double" heading={"Tour Our Virtual Showroom"}>
            <Embed
              placeholder={placeholder}
              iframeProps={{
                id: `plattar-${id}`,
                src: `${tour}&nt=1&title=0`,
                width: "100%",
                height: "100%",
                frameBorder: "0",
                allow: `xr-spatial-tracking`,
                allowFullScreen: `allowFullScreen`,
                title: "Tour Our Virtual Showroom"
              }}
              gtm={{
                event: `embedded-content-engagement`,
                label: `VR Embed Click`,
                value: `Tour Our Virtual Showroom`
              }}
            ></Embed>
          </Block>
        )}
        {pageContext.rating?.avgRating > 1 && (
          <Block gutters>
            <Reviews
              location={locationConfig}
              rating={pageContext.rating}
              reviews={data.reviews}
            />
          </Block>
        )}
      </Container>
      {content && (
        <Block padding="both">
          <ModuleArea modules={content} isJacuzzi={isJacuzzi} />
        </Block>
      )}
      <JsonLd item={schema} />
    </Layout>
  )
}

export default Showroom

export const query = graphql`
  query showroomById($id: String!, $language: String!, $birdeyeId: String!) {
    showroom: datoCmsLocation(locale: $language, id: { eq: $id }) {
      ...Showroom

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    reviews: allBirdeyeReview(filter: { businessId: { eq: $birdeyeId } }) {
      nodes {
        id
        businessId
        rating
        sourceType
        comments
        reviewDate
        businessName
        reviewer {
          name: nickName
        }
      }
    }
  }

  fragment Showroom on DatoCmsLocation {
    id
    title
    slug
    path
    banner {
      id
      link {
        ...Link
      }
      image {
        url
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "295"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      mobileImage {
        url
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "600", fit: "cover", q: 60, auto: "format" }
        )
        alt
      }
    }
    hero {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { w: "1600", h: "1080", fit: "crop", q: 70 }
      )
      alt
      url
    }
    placeholder: hero {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "658", w: "1170", fit: "crop", q: 60, auto: "format" }
      )
      alt
    }
    ...LocationContentModuleArea
    businessName
    addressLine1
    addressLine2
    city
    state
    postcode
    areasServedNode {
      childMarkdownRemark {
        html
      }
    }
    openHours {
      opens
      closes
      dayRange
      microdata
    }
    openHoursText
    partsAndAccessoriesText
    partsAndAccessoriesUrl
    primaryPhoneNumber
    salesPhoneNumber
    partsPhoneNumber
    email
    tour
    lead
    isCustom
    hideAddress
    customBox
    customBoxLink
    customBoxLinkText
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    showPathways
    isJacuzzi: jacuzziExclusiveShowroom
  }
`
