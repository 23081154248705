import React from "react"
import style from "./menu.mod.scss"
import { MegaMenu, DropDown, Superscript } from "@components"
import { TransitionLink } from "@helpers/pageTransitions"
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown"

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: -1
    }
  }

  handleToggle(curIndex, wasClicked = false) {
    this.setState({
      activeIndex: curIndex
    })
  }

  handleMouseEnter(curIndex) {
    const { activeIndex } = this.state

    if (activeIndex !== curIndex) {
      this.handleToggle(curIndex, false)
    }
  }

  handleMouseLeave() {
    this.setState({
      activeIndex: -1
    })
  }

  render() {
    const { items } = this.props
    const { activeIndex } = this.state

    const transitionSettings = {
      exit: {
        length: 0.5
      },
      entry: {
        delay: 0.2
      }
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent
        ? {
            className: [style.menu__link, style["menu__link--parent"]].join(" ")
          }
        : null
    }

    const linkConfig = {
      partiallyActive: true,
      className: style.menu__link,
      activeClassName: style["menu__link--current"],
      ...transitionSettings
    }

    return (
      <nav className={style.menu}>
        <ul className={style.menu__list}>
          {items.map((item, index) => {
            const {
              title,
              path,
              columns,
              treeChildren,
              isMegaMenu = false
            } = item
            return (
              <li
                key={`menu__list--${index}`}
                className={style.menu__item}
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={() => this.handleMouseLeave(index)}
              >
                {treeChildren?.length > 0 || isMegaMenu ? (
                  <TransitionLink
                    {...linkConfig}
                    to={path}
                    getProps={isPartiallyActive}
                    onFocus={() => this.handleToggle(index, true)}
                    aria-haspopup="true"
                    aria-controls={`menu-${index}-box`}
                    aria-expanded={index === activeIndex}
                    id={`menu-${index}-button`}
                    children={
                      <>
                        <Superscript children={title} />
                        <FaAngleDown />
                      </>
                    }
                  />
                ) : (
                  <TransitionLink
                    getProps={isPartiallyActive}
                    {...linkConfig}
                    to={path}
                    children={<Superscript children={title} />}
                  />
                )}

                {isMegaMenu && (
                  <MegaMenu
                    index={index}
                    columns={columns}
                    isOpen={index === activeIndex}
                  />
                )}

                {!isMegaMenu && treeChildren?.length > 0 && (
                  <DropDown
                    index={index}
                    items={treeChildren}
                    isOpen={index === activeIndex}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }
}

export default Menu
