import React from "react"
import PropTypes from "prop-types"

const Superscript = ({ children }) => {
  // Handle no children
  if (!children) return null

  // Handle non string values
  if (typeof children !== "string") return children

  // Set dangerouslySetInnerHTML value
  const html = { __html: children.replace(/[®]/gi, `<sup>$&</sup>`) }

  return <span dangerouslySetInnerHTML={html} />
}

export default Superscript

Superscript.propTypes = {
  children: PropTypes.node
}
