import React, { useContext, useState, useEffect } from "react"
import style from "./lead.mod.scss"
import { Superscript } from "@components"
import FoneDynamicsContext from "@context/foneDynamicsContext"
import "core-js/proposals/string-replace-all"

const Lead = ({ body }) => {
  const { loaded, num, defaultNum } = useContext(FoneDynamicsContext)
  const [leadContent, setLeadContent] = useState(body)

  useEffect(() => {
    setLeadContent(body.replaceAll(defaultNum, num))
  }, [loaded, num, body, defaultNum])

  return (
    <div className={style.lead}>
      <div className={style.lead__container}>
        {body && <div children={<Superscript children={leadContent} />} />}
      </div>
    </div>
  )
}

export default Lead
