import React, { useState, useEffect } from "react"
import config from "@config/website"

const { phoneNumber, foneDynamicsId } = config[process.env.GATSBY_LANGUAGE]

const FoneDynamicsContext = React.createContext({
  loading: true,
  defaultNum: phoneNumber,
  num: phoneNumber,
  id: foneDynamicsId,
  loaded: false
})

const FoneDynamicsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [num, setNum] = useState(phoneNumber)
  const [id] = useState(foneDynamicsId)
  const [loaded, setLoaded] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!loaded && num !== window.__hcNum) {
        setNum(window.__hcNum || phoneNumber)
        setLoading(false)
        setLoaded(true)
      }
    }, 100)
    return () => clearTimeout(timeout)
  }, [loaded, num])

  useEffect(() => {
    if (window._ctq === undefined) {
      window._ctq = []
    }

    window._ctq.push([
      "onNumberAvailable",
      num => {
        if (window) {
          window.__hcNum = num
        }
      },
      "main.phoneNumber",
      { format: "n" }
    ])

    //if (process.env.NODE_ENV === `production`)
    window._ctq.push(["trackPageView"])

    setLoading(false)
    setLoaded(false)
    //setNum(window.__hcNum)
  }, [])

  return (
    <FoneDynamicsContext.Provider
      value={{
        defaultNum: phoneNumber,
        loading,
        num,
        id
      }}
    >
      {children}
    </FoneDynamicsContext.Provider>
  )
}

export default FoneDynamicsContext
export { FoneDynamicsProvider }
