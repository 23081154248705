import React from "react"
import style from "./video-card.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Play from "@svgs/play.svg"
import { Button, CardTags } from "@components"
import { JsonLd } from "react-schemaorg"

const VideoCard = ({
  caption,
  elevated,
  heading,
  onClick,
  overlayImage,
  video,
  inMenu = false,
  buttonText = false,
  isLearnCard = false,
  meta
}) => {
  const modifiers = []
  elevated && modifiers.push(style[`video-card--elevated`])
  onClick && modifiers.push(style[`video-card--clickable`])

  const schema = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    name: video.title,
    thumbnailUrl: video.thumbnailUrl,
    embedUrl: video.url,
    width: video.width,
    height: video.height,
    description: caption
  }

  if (meta?.createdAt) {
    schema.uploadDate = meta.createdAt
  }

  return (
    <div
      className={[style[`video-card`], ...modifiers].join(" ")}
      onClick={() => onClick(video)}
    >
      <div className={style[`video-card__video`]}>
        <div className={style[`video-card__overlay`]}>
          <GatsbyImage image={overlayImage.gatsbyImageData} />
        </div>
        <button className={style[`video-card__button`]}>
          <Play />
        </button>
      </div>
      <div className={style[`video-card__content`]}>
        {isLearnCard && <CardTags video />}
        <h3>{heading}</h3>
        <p>{caption}</p>
        {inMenu && (
          <Button
            children={buttonText || "Watch"}
            fullWidthMobile
            short
            onClick={() => onClick(video)}
            type={"button"}
          ></Button>
        )}
      </div>
      <JsonLd item={schema} />
    </div>
  )
}

export default VideoCard
