import React, { useRef, useContext, useState, useEffect } from "react"
import SearchBox from "./search-box"
import SearchResult from "./search-result"
import useClickOutside from "@helpers/use-click-outside"
import SearchIcon from "@svgs/magnifying-glass.svg"
import style from "./search.mod.scss"
import SearchContext from "@context/searchContext"

const Search = ({ isSearchOpen, setSearchOpen }) => {
  const context = useContext(SearchContext)
  const rootRef = useRef(null)
  useClickOutside(rootRef, () => context.focus && context.setFocus(false))

  const onClick = boolean => {
    setSearchOpen(boolean)
    if (boolean === false) context.setFocus(false)
  }

  return (
    <div ref={rootRef} className={style["search"]}>
      <SearchIcon
        onClick={() => {
          setSearchOpen(true)
          context.setFocus(true)
        }}
      />
      <div
        className={style["search__inner"]}
        aria-hidden={isSearchOpen ? "false" : "true"}
      >
        <SearchBox
          onFocus={() => context.setFocus(true)}
          hasFocus={context.focus}
          onClick={onClick}
          isOpen={isSearchOpen}
          clearsSearch={context.searchState?.query === undefined}
          defaultRefinement={context.defaultRefinement}
          context={{ ...context }}
          containClose
          animatePresence
          cancelEnter={true}
        />
        {isSearchOpen && (
          <SearchResult index={context.index} context={{ ...context }} />
        )}
      </div>
    </div>
  )
}

export default Search
