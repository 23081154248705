import React from "react"
import { graphql } from "gatsby"
import SplitBlock from "../components/split-block"
import Video from "./video"
import Image from "./image"
import ImageLink from "./image-link"

const SplitMediaBlock = ({blocks}) => {
  // console.log("SplitMediaBlock", test)
  const renderBlock = (block) => {
    if (block.model && block.model.apiKey === "video") {
      return (<div><Video {...block} /></div>)
    }
    if (block.model && block.model.apiKey === "image") {
      return (<div><Image {...block} /></div>)
    }
    if (block.model && block.model.apiKey === "image_link") {
      return (<div><ImageLink {...block} /></div>)
    }
  }
  return (
    <SplitBlock
      leftChildren={renderBlock(blocks[0])}
      rightChildren={renderBlock(blocks[1])}
    />
  )
}

export default SplitMediaBlock

export const query = graphql`
  fragment SplitMediaBlock on DatoCmsSplitMediaBlock {
    id
    blocks {
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsImageLink {
        ...ImageLink
      }
    }
    padding
    model {
      apiKey
    }
    __typename
  }
`
