import React from "react"
import style from "./swirl.mod.scss"
import SwirlSvg from "@svgs/swirl.svg"
import Blob from "@svgs/video-blob.svg"

const Swirl = ({ blob, modifiers = [] }) => {
  blob && modifiers.push(`${style.swirl}--blob`)
  return (
    <div className={[style.swirl, ...modifiers].join(" ")}>
      {blob ? <Blob /> : <SwirlSvg />}
    </div>
  )
}

export default Swirl
