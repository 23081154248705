import React from "react"
import style from "./thumb.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Superscript } from "@components"

const Thumb = ({ image, title, icon, padded = false }) => {
  const modifier = padded ? style["thumb--padded"] : ""

  return (
    <div className={[style.thumb, modifier].join(" ")}>
      <div className={style.thumb__image}>
        {image && <GatsbyImage loading="lazy" image={image.gatsbyImageData} />}
      </div>
      <span className={style.thumb__text}>
        <span
          className={style.thumb__caption}
          children={<Superscript children={title} />}
        />
        <span className={style.thumb__icon}>{icon}</span>
      </span>
    </div>
  )
}

export default Thumb
