import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./swatch.mod.scss"

const Swatch = ({ onChange, swatch, name, isChecked, disabled }) => {
  const modifiers = []
  isChecked && modifiers.push(`${style.swatch}--checked`)
  disabled && modifiers.push(`${style.swatch}--disabled`)
  return (
    <label className={[style.swatch, ...modifiers].join(" ")}>
      <input
        type="radio"
        checked={isChecked && "checked"}
        onChange={onChange}
        name={name}
        disabled={disabled}
      ></input>
      <GatsbyImage
        className={style.swatch__image}
        image={swatch.gatsbyImageData}
        alt={swatch.alt}
      />
      <div className={style.swatch__name}>{name}</div>
    </label>
  )
}

export default Swatch

export const query = graphql`
  fragment SwatchShell on DatoCmsShellColour {
    id
    name
    swatch {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "65", fit: "crop", w: "110", q: 60 }
      )
      alt
    }
  }
  fragment SwatchCabinet on DatoCmsCabinetColour {
    id
    name
    swatch {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "65", fit: "crop", w: "110", q: 60 }
      )
      alt
    }
  }
`
