import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import {
  Intro,
  Cards,
  Card,
  Block,
  SplitBlock,
  Container,
  Banner
} from "@components"
import Layout from "@layouts/layout"
import ModuleArea from "../helpers/moduleArea"
import LocationMapModuleArea from "../helpers/locationMapModuleArea"

const LandingPage = props => {
  const { data, location } = props
  const {
    leadNode,
    banner,
    heroImage,
    videoPlaceholder,
    heroVideo,
    seoMetaTags,
    contentWithForm,
    content,
    pathways,
    showPathways,
    showHeroImage,
    logo,
    showHeader
  } = data.landingPage

  const allPathways = [...pathways, ...data.globalConfig.defaultPathways].slice(
    0,
    3
  )

  const introConfig = {
    logo: logo,
    lead: leadNode?.childMarkdownRemark.html,
    heroImage,
    videoPlaceholder,
    heroVideo,
    showHeroImage,
    noTitle: true,
    noCrumbs: true
  }

  return (
    <Layout showHeader={showHeader}>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block>
        <Intro {...introConfig} banner={banner} />

        {contentWithForm && contentWithForm.length >= 2 ? (
          <SplitBlock
            maxWidth="content"
            leftChildren={
              <>
                {contentWithForm[0] && (
                  <ModuleArea
                    modules={[{ ...contentWithForm[0] }]}
                    location={location}
                  />
                )}
                {contentWithForm?.[1] && (
                  <ModuleArea
                    modules={[{ ...contentWithForm[1] }]}
                    location={location}
                  />
                )}
              </>
            }
            rightChildren={
              <>
                {(contentWithForm?.[2] || contentWithForm?.[1]) && (
                  <ModuleArea
                    modules={[
                      contentWithForm?.[2]
                        ? { ...contentWithForm[2] }
                        : { ...contentWithForm[1] }
                    ]}
                    location={location}
                  />
                )}
              </>
            }
          />
        ) : (
          <ModuleArea modules={contentWithForm} location={location} />
        )}
      </Block>
      {content && (
        <LocationMapModuleArea modules={content} location={location} />
      )}
      {showPathways && (
        <Block padding="both" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
    </Layout>
  )
}

export const query = graphql`
  query landingPageById($id: String!, $language: String!) {
    landingPage: datoCmsLandingPage(locale: $language, id: { eq: $id }) {
      title
      id
      slug
      path
      logo {
        alt
        url(imgixParams: { h: "80", q: 50 })
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "550"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      showHeroImage
      showHeader
      videoPlaceholder: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "658"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      heroVideo {
        url
        title
        provider
        providerUid
        thumbnailUrl
        width
        height
      }
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      banner {
        id
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { h: "600", fit: "cover", q: 60, auto: "format" }
          )
          alt
        }
      }
      ...LandingPageContentModuleArea
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pathways {
        ...Card
      }
      showPathways
      showTrustPilot
    }
    globalConfig: datoCmsGlobal(locales: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`

export default LandingPage
