import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import style from "./card-tags.mod.scss"
import WatchIcon from "../../assets/svg/play-icon.svg"
import ReadIcon from "../../assets/svg/book-icon.svg"

const CardTags = ({ video = false, article = false, id = false }) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsBlogPost {
        nodes {
          id
          content {
            ... on DatoCmsVideo {
              id
            }
          }
        }
      }
    }
  `)
  const tagText = { video: "Watch", article: "Read" }

  // determine if post has any video within
  const currentArticle = data.allDatoCmsBlogPost.nodes.find(
    article => article.id === id
  )
  const hasVideo = currentArticle?.content.some(el => el.id)

  return (
    <div className={style["card-tags"]}>
      {(video || hasVideo) && (
        <div className={style["card-tags__pill"]}>
          <WatchIcon />
          <span>{tagText.video}</span>
        </div>
      )}
      {article && (
        <div className={style["card-tags__pill"]}>
          <ReadIcon />
          <span>{tagText.article}</span>
        </div>
      )}
    </div>
  )
}

export default CardTags
