import React from "react"
import { navigate } from "gatsby"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"

const defaultState = {
  focus: false,
  setFocus: () => {},
  onSubmit: () => {},
  defaultRefinement: "",
  setDefaultRefinement: () => {},
  searchState: {},
  index: null,
  createURL: () => {},
  searchClient: null,
  searchIndexName: process.env.GATSBY_ALGOLIA_PRODUCT_INDEX
}

const SearchContext = React.createContext(defaultState)

/**
 * Provides context to search
 * @extends React
 */
class SearchProvider extends React.Component {
  state = {
    focus: false,
    defaultRefinement: "",
    searchState: {},
    searchIndexName: process.env.GATSBY_ALGOLIA_PRODUCT_INDEX
  }

  index = {
    products: {
      name: process.env.GATSBY_ALGOLIA_PRODUCT_INDEX,
      title: process.env.GATSBY_ALGOLIA_PRODUCT_INDEX,
      heading: "Spa Pools & More"
    },
    learn: {
      name: process.env.GATSBY_ALGOLIA_LEARN_INDEX,
      title: process.env.GATSBY_ALGOLIA_LEARN_INDEX,
      heading: "Learn"
    },
    store: {
      name: process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX,
      title: process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX,
      heading: "Parts & Accessories"
    }
  }

  searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  createURL = state => {
    const config = {
      query: state?.query
    }

    return `?${qs.stringify(config)}`
  }

  onSearchStateChange = updatedSearchState => {
    this.setState({ searchState: updatedSearchState })
  }

  setDefaultRefinement = refinement =>
    this.setState({ defaultRefinement: refinement })

  setSearchIndexName = indexName => {
    this.setState({ searchIndexName: indexName })
  }

  setFocus = boolean => this.setState({ focus: boolean })

  onSubmit = e => {
    e.preventDefault()
    e.persist()

    this.setState({ focus: false, defaultRefinement: e?.target?.[0]?.value })
    navigate(`/search-results/${this.createURL(this.state.searchState)}`)
  }

  render() {
    const { children } = this.props
    const {
      searchState,
      focus,
      defaultRefinement,
      searchIndexName
    } = this.state

    return (
      <SearchContext.Provider
        value={{
          index: this.index,
          searchState,
          focus,
          setFocus: this.setFocus,
          onSubmit: this.onSubmit,
          defaultRefinement,
          setDefaultRefinement: this.setDefaultRefinement,
          createURL: this.createURL,
          searchClient: this.searchClient,
          onSearchStateChange: this.onSearchStateChange,
          setSearchIndexName: this.setSearchIndexName,
          searchIndexName
        }}
      >
        {children}
      </SearchContext.Provider>
    )
  }
}

export default SearchContext

export { SearchProvider }
