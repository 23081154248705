import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Table, Toggle, Pill, ShowMore } from "@components"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { CurrencyFormatter } from "@context/productContext"
import ProductContext, { getPricing } from "@context/productContext"
import style from "@scss/_vars.mod.scss"
import { gtmPush } from "@helpers/gtmHelpers"
import {
  AVANTE_NAMESPACE,
  AVANTE_VALUE,
  NON_AVANTE_VALUE,
  getAvanteComparisonVariant
} from "@helpers/avanteHelpers"
import { getOptionsMap } from "@helpers/variants"

const EMPTY_CELL = ""

const Avante = ({
  features,
  productImage,
  defaultVariant,
  netoVariants,
  ...props
}) => {
  const { setOption, currentModel, productLoading } = useContext(ProductContext)
  const [currentVariant, setCurrentVariant] = useState(defaultVariant)
  const currentValues = getOptionsMap(currentVariant.specifics)
  const currentPackage = currentValues.get(AVANTE_NAMESPACE)
  const tempCompareVariant = getAvanteComparisonVariant(
    currentVariant,
    netoVariants
  )
  const [compareVariant, setCompareVariant] = useState(tempCompareVariant)

  // Update table state when product comes online.
  useEffect(() => {
    if (!productLoading) {
      const variant = getAvanteComparisonVariant(currentModel, netoVariants)
      setCurrentVariant(currentModel)
      setCompareVariant(variant)
    }
  }, [productLoading, currentModel])

  const currentPrice = getPricing(currentVariant)
  const comparisonPrice = getPricing(compareVariant)
  const isAvante = currentValues.get(AVANTE_NAMESPACE) === AVANTE_VALUE
  const standardPrice = !isAvante ? currentPrice : comparisonPrice
  const avantePrice = isAvante ? currentPrice : comparisonPrice
  const totalValue = avantePrice.standard
  const totalDifference = totalValue - standardPrice.price
  const totalAddonValue = [...features]
    .filter(({ value }) => value !== null)
    .map(({ value }) => value)
    .reduce((total, value) => total + value)

  if (totalDifference !== totalAddonValue) {
    // Warn on build for mismatches
    console.log(
      `Warning: ${props.name} addon value mismatch. Is ${totalAddonValue}, should be ${totalDifference}`
    )
  }

  // Alternate Savings calulation
  // const saving = avantePrice.price - totalValue
  const saving = avantePrice.onSale
    ? avantePrice.standard - avantePrice.was
    : avantePrice.standard - avantePrice.price

  const head = [
    [
      EMPTY_CELL,
      <GatsbyImage image={productImage.gatsbyImageData} />,
      <GatsbyImage image={productImage.gatsbyImageData} />,
      EMPTY_CELL
    ],
    [
      EMPTY_CELL,
      EMPTY_CELL,
      <Pill icon={<FaStar />} elevated children={"Most Popular"} />,
      EMPTY_CELL
    ],
    [
      EMPTY_CELL,
      <h4>{`${props.name} ${NON_AVANTE_VALUE}`}</h4>,
      <h4>{`${props.name} ${AVANTE_VALUE}`}</h4>,
      EMPTY_CELL
    ]
  ]

  const body = features.map(
    ({ title, descriptionNode, standard, avante, value }) => {
      const description = descriptionNode
        ? descriptionNode.childMarkdownRemark.html
        : null

      return [
        <Toggle
          title={title}
          children={<div dangerouslySetInnerHTML={{ __html: description }} />}
        />,
        standard ? (
          <FaCheckCircle
            style={{ color: style.primaryColor, fontSize: "1.5em" }}
          />
        ) : (
          <FaTimes
            style={{ color: "rgba(0, 0, 0, 0.15)", fontSize: "1.5em" }}
          />
        ),
        avante ? (
          <FaCheckCircle
            style={{ color: style.primaryColor, fontSize: "1.5em" }}
          />
        ) : (
          <FaTimes style={{ color: style.lightGray, fontSize: "1.5em" }} />
        ),
        value ? (
          <span style={{ color: style.primaryColor }}>
            {`Value `}
            <CurrencyFormatter value={value} />
          </span>
        ) : (
          EMPTY_CELL
        )
      ]
    }
  )

  body.push([
    <h3 style={{ color: style.black }}>{`Total Value`}</h3>,
    <h3 style={{ color: style.black }}>
      <CurrencyFormatter value={standardPrice.price} />
    </h3>,

    <h3 style={{ color: style.black }}>
      <CurrencyFormatter value={totalValue} />
    </h3>,
    <h3 style={{ color: style.black }}>
      <CurrencyFormatter value={totalAddonValue} />
    </h3>
  ])

  const foot = [
    [
      <h3>{`${AVANTE_VALUE} Package Price`}</h3>,
      EMPTY_CELL,
      <h3>
        <CurrencyFormatter
          value={avantePrice.onSale ? avantePrice.was : avantePrice.price}
        />
      </h3>,
      EMPTY_CELL
    ],
    [
      <h3>{`${AVANTE_VALUE} Package Savings`}</h3>,
      EMPTY_CELL,
      <h3>
        <CurrencyFormatter value={saving} />
      </h3>,
      EMPTY_CELL
    ],
    [
      EMPTY_CELL,
      <Pill
        secondary
        elevated
        name={AVANTE_NAMESPACE}
        value={NON_AVANTE_VALUE}
        isChecked={!isAvante}
        type="radio"
        onChange={({ target }) => {
          setOption(target.name, target.value)
        }}
      >
        {!isAvante ? "Selected" : "Select"}
        {` ${NON_AVANTE_VALUE}`}
      </Pill>,
      <Pill
        secondary
        elevated
        name={AVANTE_NAMESPACE}
        value={AVANTE_VALUE}
        isChecked={isAvante}
        type="radio"
        onChange={({ target }) => {
          if (!isAvante) {
            gtmPush({
              event: "upsell-click",
              label: `${AVANTE_VALUE} Upsell`,
              value: `Select ${AVANTE_VALUE}`
            })
          }
          setOption(target.name, target.value)
        }}
      >
        {isAvante ? "Selected" : "Select"}
        {` ${AVANTE_VALUE}`}
      </Pill>,
      EMPTY_CELL
    ]
  ]

  if (avantePrice.onSale) {
    foot.splice(2, 0, [
      <h3>{`Special Sale Price`}</h3>,
      EMPTY_CELL,
      <h3>
        <CurrencyFormatter value={avantePrice.price} />
      </h3>,
      EMPTY_CELL
    ])
  }

  return (
    <ShowMore
      mobileOnly
      maxHeight={300}
      enabled={body.length > 3}
      background="teal"
      contentId={"#avanteTable"}
    >
      <div id="avanteTable">
        <Table
          data-current-package={currentPackage}
          modifier={"avante"}
          head={head}
          body={body}
          foot={foot}
        />
      </div>
    </ShowMore>
  )
}

export default Avante

export const query = graphql`
  fragment AvanteTable on DatoCmsProduct {
    avanteThumb: heroImage {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: {
          h: "135"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          w: "200"
          q: 60
        }
      )
      alt
    }

    avanteData: avanteUpgradeTable {
      upgrades {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        value
        standard
        avante
      }
    }
    ...AvanteVideo
  }

  fragment AvanteContent on ContentYaml {
    avanteContent: product {
      heading: avanteUpgradeHeading
    }
  }
`
