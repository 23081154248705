import React, { useEffect, useState, useContext } from "react"
import config from "@config/website"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./customise.mod.scss"
import { Price, Button, Select, Superscript, Tooltip } from "@components"
import { knownSpecifics, getOptionsMap } from "@helpers/variants"
import ProductContext from "@context/productContext"
import CartContext from "@context/cartContext"
import { ProductFinanceDisclaimer } from "../../templates/product"
import useAddToCart from "@helpers/useAddToCart"
import { gtmPush } from "../../helpers/gtmHelpers"

const Customise = ({
  bannerMessage,
  productOptions,
  defaultVariant,
  heading,
  editCart = false,
  colourOneHeading,
  colourTwoHeading,
  parentPrice,
  simple = false,
  ...props
}) => {
  const { cart, cartLoading, getSubtotal } = useContext(CartContext)
  const [currentVariant, setCurrentVariant] = useState(defaultVariant)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const defaultValues =
    defaultVariant.specifics && getOptionsMap(defaultVariant.specifics)
  const [anchors, setAnchors] = useState(
    new Map(knownSpecifics.map(spec => [spec.name, spec]))
  )
  const options = new Map(
    productOptions.map(({ name, options }) => [name, options])
  )

  const {
    setOption,
    currentValues,
    currentPrice,
    validOptions,
    valid,
    currentBanner,
    productLoading,
    currentModel,
    parentProduct
  } = useContext(ProductContext)

  const { addToCart } = useAddToCart()
  useEffect(() => {
    return () => {
      setCurrentVariant(null)
    }
  }, [])

  useEffect(() => {
    // when the product is done loading, if the currentModel from the product context
    // is not equal to the product in our currentVariant state, then update our currentVariant state to use the
    // currentModel instead.
    if (!productLoading) {
      if (JSON.stringify(currentModel) !== JSON.stringify(currentVariant)) {
        setCurrentVariant(currentModel)
      }
    }
  }, [productLoading, currentModel, currentVariant])

  const product = props
  const parentInCart = !cartLoading && cart.has(product.sku)
  const cartProduct = parentInCart && cart.get(product.sku)
  const modelInCart =
    !cartLoading && !productLoading && cartProduct
      ? cartProduct.variantSku === currentVariant.sku
      : false

  const optionsOrder = knownSpecifics.map(({ name }) => name)
  if (optionsOrder.indexOf(colourTwoHeading) < 0)
    optionsOrder.push(colourTwoHeading)
  if (optionsOrder.indexOf(colourOneHeading) < 0)
    optionsOrder.push(colourOneHeading)

  const sortedOption = productOptions
    .map(({ name }) => name)
    .sort((a, b) => optionsOrder.indexOf(a) - optionsOrder.indexOf(b))

  const buttonText = () => {
    if (modelInCart) return "In cart"
    if (parentInCart && !modelInCart) return "Update cart"
    return valid ? "Add to cart" : "Please select options"
  }

  useEffect(() => {
    if (!document) return

    const newAnchors = new Map(anchors)

    newAnchors.forEach((spec, name) => {
      if (!document.querySelector(`#${spec.slug}`)) {
        newAnchors.delete(name)
      }
    })

    if (newAnchors.size !== anchors.size) setAnchors(newAnchors)
  }, [])

  const getClickId = () => {
    const name = product.name
    return name
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .trim("-")
  }

  useEffect(() => {
    const ecommerceItem = []
    if (cart.size !== 0 && editCart) {
      cart.forEach(item => {
        ecommerceItem.push({
          item_id: item.sku,
          item_name: item.name,
          affiliation: "Spa World",
          item_brand: item.brand,
          item_category: item.category,
          item_variant: item.variantDetails?.name || "none",
          price: item.prices?.price,
          currency: item.prices?.currency,
          quantity: item.qty
        })
      })

      gtmPush({ ecommerce: null })
      gtmPush({
        event: "view_cart",
        ecommerce: {
          currency: ecommerceItem[0].currency,
          value: getSubtotal(),
          items: ecommerceItem
        }
      })
    }
  }, [])

  return (
    <>
      {simple ? (
        <div className={style.customise__simple}>
          <div>
            {/* <Price
              prices={!productLoading && valid ? currentPrice : parentPrice}
              isAggregate={productLoading || !valid}
              customize={true}
            /> */}
          </div>
          <div>
            {sortedOption.map((key, i) => {
              return (
                <div key={`specific-${key}-${i}`}>
                  <Select
                    label={key}
                    name={key}
                    custom
                    value={
                      currentValues.get(key) || defaultValues.get(key) || ""
                    }
                    options={options.get(key).map(option => ({
                      value: option,
                      text: option,
                      disabled:
                        !validOptions ||
                        !validOptions.get(key) ||
                        validOptions.get(key).indexOf(option) < 0
                    }))}
                    onChange={e => setOption(e.target.name, e.target.value)}
                    onBlur={e => setOption(e.target.name, e.target.value)}
                    helpMessage={
                      !editCart &&
                      anchors.has(key) && (
                        <Button
                          unstyled
                          to={`#${anchors.get(key).slug}`}
                          children={anchors.get(key).helpMessage}
                        />
                      )
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <CustomiseWrapper
          bannerMessage={bannerMessage || currentBanner}
          heading={heading}
          footer={
            product.finance.length > 0 &&
            (product.finance.length > 1 ? (
              <>
                <div className={style.customise__finance}>
                  <GatsbyImage
                    image={
                      product.finance[0].wideIcon
                        ? product.finance[0].logoWide.gatsbyImageData
                        : product.finance[0].logo.gatsbyImageData
                    }
                  />
                  <ProductFinanceDisclaimer
                    displayText={"Finance Options on Select Models*"}
                    linkPath={"/contact-us/finance-enquiry/"}
                  />
                  <GatsbyImage
                    image={
                      product.finance[1].wideIcon
                        ? product.finance[1].logoWide.gatsbyImageData
                        : product.finance[1].logo.gatsbyImageData
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className={style.customise__finance}>
                  <GatsbyImage
                    image={
                      product.finance[0].wideIcon
                        ? product.finance[0].logoWide.gatsbyImageData
                        : product.finance[0].logo.gatsbyImageData
                    }
                  />
                  <ProductFinanceDisclaimer
                    displayText={product.finance[0].name}
                    linkPath={product.finance[0].enquiryFormPage.path}
                  />
                </div>
              </>
            ))
          }
        >
          <form className={style.customise__form}>
            <Price
              prices={!productLoading && valid ? currentPrice : parentPrice}
              isAggregate={productLoading || !valid}
              customize={true}
            />
            <div className={style.customise__options}>
              {sortedOption.map((key, i) => {
                return (
                  <div key={`specific-${key}-${i}`}>
                    <Select
                      label={key}
                      name={key}
                      value={
                        currentValues.get(key) || defaultValues.get(key) || ""
                      }
                      options={options.get(key).map(option => ({
                        value: option,
                        text: option,
                        disabled:
                          !validOptions ||
                          !validOptions.get(key) ||
                          validOptions.get(key).indexOf(option) < 0
                      }))}
                      onChange={e => setOption(e.target.name, e.target.value)}
                      onBlur={e => setOption(e.target.name, e.target.value)}
                      helpMessage={
                        !editCart &&
                        anchors.has(key) && (
                          <Button
                            unstyled
                            to={`#${anchors.get(key).slug}`}
                            children={anchors.get(key).helpMessage}
                          />
                        )
                      }
                    />
                  </div>
                )
              })}
            </div>

            {!editCart && (
              <Button
                gaTarget={"customise-add-to-cart"}
                type={"button"}
                disabled={!valid}
                wide
                id={`${getClickId()}-add-to-cart`}
                onClick={() =>
                  addToCart(
                    currentVariant,
                    () =>
                      !editCart &&
                      navigate(product.upsellPath, {
                        state: { currentSku: currentVariant.sku }
                      })
                  )
                }
                children={buttonText()}
              />
            )}
            <div className={style["customise__order-multiple"]}>
              <Tooltip
                isTooltipOpen={isTooltipOpen}
                closeTooltip={e => {
                  e.preventDefault()
                  setIsTooltipOpen(false)
                }}
              >
                <p>{"Interested in ordering multiple of this product?"}</p>
                <div>
                  <Button
                    to={`tel:${config[
                      process.env.GATSBY_LANGUAGE
                    ].phoneNumber.replace(/ /g, "")}`}
                    short
                  >
                    {"Give us a Call"}
                  </Button>
                  <Button
                    to={"/contact-us/get-a-quote/"}
                    state={{
                      heading: currentVariant?.name,
                      image: parentProduct?.heroImage?.gatsbyImageData,
                      brand: parentProduct?.brand,
                      excerpt: parentProduct?.excerpt,
                      sku: currentVariant?.sku
                    }}
                    short
                  >
                    {"Get a Quote"}
                  </Button>
                </div>
              </Tooltip>
              <Button
                type={"button"}
                wide
                unstyled
                tertiary
                center
                gaTarget={"customise-order-multiple"}
                onClick={e => {
                  e.preventDefault()
                  setIsTooltipOpen(true)
                }}
              >
                {"Ordering multiple?"}
              </Button>
            </div>
          </form>
        </CustomiseWrapper>
      )}
    </>
  )
}

const CustomiseWrapper = ({ bannerMessage, heading, footer, children }) => (
  <div className={style.customise}>
    <header className={style.customise__banner}>
      <h2>{bannerMessage}</h2>
    </header>

    <div className={style.customise__body}>
      {heading && (
        <h2
          className={style.customise__heading}
          children={<Superscript children={heading} />}
        />
      )}
      {children}
    </div>

    <footer className={style.customise__footer}>{footer && footer}</footer>
  </div>
)

export default Customise

export const query = graphql`
  fragment Customise on DatoCmsProduct {
    netoProduct {
      valueProposition
    }

    netoVariants {
      valueProposition
      defaultModel
      specifics {
        name
        value
      }
      name
      model
      sku
      parentSku
      upsells
      ...Price
    }
  }

  fragment CustomiseDefaultVariant on NetoProduct {
    id
    sku
    valueProposition
    defaultModel
    specifics {
      name
      value
    }
    name
    model
    sku
    parentSku
    upsells
    ...Price
  }

  fragment CustomiseFinance on DatoCmsFinance {
    id
    slug
    name
    repaymentOptions
    enquiryFormPage {
      path
    }
    logo {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: {
          h: "38"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          w: "38"
          q: 60
          dpr: 2
        }
      )
      alt
    }
    wideIcon
    logoWide: logo {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: {
          h: "38"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          w: "88"
          q: 60
          dpr: 2
        }
      )
      alt
    }
  }
`
