import React, { useState } from "react"
import { MenuItem } from "@components"
import style from "./drilldown.mod.scss"
import { motion } from "framer-motion"
import uniqid from "uniqid"

const Drilldown = ({ items, isVisible, closeSidebar }) => {
  const [curDepth, setCurDepth] = useState(0)
  const [curChildMenu, setCurChildMenu] = useState([])
  const [curChildHeading, setCurChildHeading] = useState("")
  const [prevChildMenu, setPrevChildMenu] = useState([])
  const [prevChildHeading, setPrevChildHeading] = useState("")
  const [isNestedMenuVisible, setIsNestedMenuVisible] = useState(false)

  const returnToIndex = (depth = 0, prevMenu = [], prevHeading = "") => {
    if (depth > 1) {
      setCurChildMenu(prevMenu)
      setCurChildHeading(prevHeading)
      setCurDepth(1)
      setIsNestedMenuVisible(true)
    } else {
      setCurChildMenu([])
      setCurChildHeading("")
      setCurDepth(0)
    }
  }

  const handleSetChildMenu = (items, heading = "", depth) => {
    setCurChildMenu(items)
    setCurChildHeading(heading)
    setCurDepth(depth)
    setIsNestedMenuVisible(true)
  }

  const handleSetPrevChildMenu = (curMenu, curHeading = "") => {
    setPrevChildMenu(curMenu)
    setPrevChildHeading(curHeading)
    setIsNestedMenuVisible(false)
  }

  const flattenedMegaMenuItems = ({ path, title, id, columns }, i, depth) => {
    const html = [
      <MenuItem
        key={`drilldown__item-${id}-${i}`}
        dataKey={`drilldown__item-${id}-${i}`}
        link={{ path }}
        modifier={"drilldown"}
        displayText={title}
        vertical
        tabIndex={depth === 0 ? 0 : -1}
        index={i}
        onClick={() => close()}
        isMobile
      />
    ]

    // if (title === "Learn") return html

    columns.every(({ heading, collections, tile }, ii) => {
      if (tile) return
      html.push(
        <MenuItem
          key={`drilldown__item-${uniqid()}-ii`}
          dataKey={`drilldown__item-${i}-${ii}`}
          modifier={"drilldown"}
          link={{ path }}
          displayText={heading}
          onClick={() => {
            handleSetPrevChildMenu(collections, heading)
            handleSetChildMenu(collections, heading, 1)
          }}
          isButton
          unstyled
          showIcon
          vertical
          tabIndex={depth === 0 ? 0 : -1}
          index={ii}
          isMobile
        />
      )
    })

    return html
  }

  const megaMenuItems = (item, i, depth) => {
    const html = []
    const columnsWithCollections = item.columns.filter(column => !column.tile)
    let customLink = item.link?.link?.path || item.path
    html.push(
      <MenuItem
        key={`drilldown__item-${item.id}-${i}`}
        dataKey={`drilldown__item-${item.id}-${i}`}
        modifier={"drilldown"}
        link={{ path: customLink, url: item.link?.url }}
        displayText={item.title}
        onClick={() => {
          columnsWithCollections.forEach(column =>
            column.collections.length > 0
              ? (column.hasChildren = true)
              : (column.hasChildren = false)
          )

          handleSetPrevChildMenu([...columnsWithCollections], item.title)
          handleSetChildMenu([...columnsWithCollections], item.title, 1)
        }}
        isButton
        unstyled
        showIcon
        vertical
        tabIndex={depth === 0 ? 0 : -1}
        index={i}
        isMobile
      />
    )

    return html
  }

  const close = () => {
    closeSidebar()
    returnToIndex()
  }

  const variants = {
    open: {
      display: "block",
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      display: "none",
      opacity: 0,
      y: -16,
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  }

  return (
    <div className={style.drilldown}>
      <motion.ul
        id={`drilldown__list--index`}
        initial={"closed"}
        animate={isVisible && curDepth === 0 ? "open" : "closed"}
        variants={variants}
        className={style.drilldown__list}
      >
        {items.map((item, i) => {
          const {
            id,
            path,
            title,
            treeChildren = [],
            isMegaMenu = false
          } = item

          const hasChildren = treeChildren.length > 0

          if (
            isMegaMenu &&
            path === "/products/"
            // || path === "/learn/"
          ) {
            return flattenedMegaMenuItems(item, i, curDepth)
          }

          if (isMegaMenu && path !== "/products/") {
            // console.log(megaMenuItems(item, i, curDepth))
            return megaMenuItems(item, i, curDepth)
          } else
            return (
              <MenuItem
                key={`drilldown__item-${id}-${i}`}
                dataKey={`drilldown__item-${id}-${i}`}
                modifier={"drilldown"}
                link={{ path }}
                onClick={
                  hasChildren
                    ? e => {
                        e.preventDefault()
                        handleSetPrevChildMenu([...treeChildren], title)
                        handleSetChildMenu([...treeChildren], title, 1)
                      }
                    : () => close()
                }
                isButton={hasChildren}
                unstyled
                displayText={title}
                showIcon={isMegaMenu || hasChildren}
                vertical
                tabIndex={curDepth === 0 ? 0 : -1}
                index={i}
                isMobile
              />
            )
        })}
      </motion.ul>

      {curChildMenu.length > 0 && (
        <motion.ul
          initial={"closed"}
          animate={
            isVisible && isNestedMenuVisible && curDepth > 0 ? "open" : "closed"
          }
          variants={{
            open: {
              opacity: 1,
              y: 0,
              transition: {
                staggerChildren: 0.07,
                delayChildren: 0.5
              }
            },
            closed: {
              opacity: 0,
              y: -16,
              transition: {
                staggerChildren: 0.05,
                staggerDirection: -1
              }
            }
          }}
          className={style.drilldown__child}
        >
          {curChildHeading !== "" && (
            <motion.li className={style.drilldown__subheading}>
              <h4>{curChildHeading}</h4>
            </motion.li>
          )}
          {curChildMenu.map(
            (
              {
                path,
                slug,
                title,
                name,
                parentCategory,
                isExternal = false,
                hasChildren = false,
                heading,
                collections,
                id
              },
              iii
            ) => {
              let safePath =
                parentCategory !== undefined
                  ? `/${parentCategory.slug}/${slug}/`
                  : path

              if (
                id.includes("LearnSubcategory") &&
                parentCategory?.length > 0
              ) {
                safePath = `/learn/${parentCategory[0].slug}/${slug}/`
              }

              return (
                <MenuItem
                  key={`drilldown__child-item-${id}-${iii}`}
                  modifier={"drilldown"}
                  dataKey={`drilldown__child-item-${id}-${iii}`}
                  link={{ path: safePath }}
                  displayText={title || name || heading}
                  vertical
                  index={iii}
                  tabIndex={curDepth > 1 ? 0 : -1}
                  onClick={
                    hasChildren
                      ? e => {
                          e.preventDefault()
                          setIsNestedMenuVisible(false)
                          handleSetChildMenu([...collections], heading, 2)
                        }
                      : () => close()
                  }
                  isExternal={isExternal}
                  isButton={hasChildren}
                  showIcon={hasChildren}
                  unstyled
                  isMobile
                />
              )
            }
          )}

          <MenuItem
            modifier={"drilldown"}
            key={`drilldown__child-item-return-${curChildHeading}`}
            dataKey={`drilldown__child-item-return-${curChildHeading}`}
            displayText={"Back"}
            backIcon
            link={{ path: "/" }}
            onClick={e => {
              e.preventDefault()
              setIsNestedMenuVisible(false)
              returnToIndex(curDepth, prevChildMenu, prevChildHeading)
            }}
            isButton
            unstyled
            vertical
            index={100}
            tabIndex={curDepth > 1 ? 0 : -1}
            isMobile
          />
        </motion.ul>
      )}
    </div>
  )
}

export default Drilldown
