import React, { useState, useEffect, useContext } from "react"
import style from "./modal.mod.scss"
import ReactDOM from "react-dom"
import { isBrowser } from "@context/themeContext"
import { gtmPush } from "@helpers/gtmHelpers"
import { CustomModal } from "@components"
import ThemeContext from "@context/themeContext"

const Modal = ({
  children = null,
  icon,
  largeIcon = false,
  ariaLabel,
  gtm,
  video = false,
  placement = "default",
  onChange = () => {},
  globalState = false,
  background = false,
  noPadding = false,
  overflowScroll = false,
  flexInner = false,
  upsellVideo = false
}) => {
  const { setDepth, videoViewDepth } = useContext(ThemeContext)
  const [isOpen, setOpen] = useState(globalState)

  useEffect(() => {
    setOpen(globalState)
  }, [globalState])

  const modifiers = [
    style["modal--" + placement.toLowerCase().replace(/ /gi, "-")]
  ]

  const appRoot = isBrowser()
    ? window.document.querySelector("#___gatsby")
    : false

  upsellVideo && modifiers.push(style["modal--upsell"])
  largeIcon && modifiers.push(style["modal--large-icon"])

  return (
    <div className={[style.modal, ...modifiers].join(" ")}>
      <div className={style.modal__wrap}>
        {isBrowser() &&
          ReactDOM.createPortal(
            <CustomModal
              isOpen={isOpen}
              channel={video?.provider}
              autoplay="true"
              videoId={video?.providerUid}
              onClose={() => {
                onChange(false)
                setOpen(false)
              }}
              children={children}
              closeOnClick
              background={background}
              noPadding={noPadding}
              overflowScroll={overflowScroll}
              flexInner={flexInner}
            />,
            appRoot
          )}
        {icon && (
          <button
            className={style.modal__button}
            aria-label={ariaLabel}
            onClick={() => {
              gtm &&
                gtmPush({
                  ...gtm,
                  videoViewDepth: video && videoViewDepth + 1
                })
              video && setDepth(`videoViewDepth`, videoViewDepth + 1)

              onChange(true)
              setOpen(true)
            }}
          >
            {upsellVideo && <span>{"Watch video"}</span>}
            {icon}
          </button>
        )}
      </div>
    </div>
  )
}

export default Modal
