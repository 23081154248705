import React from "react"
import algoliasearch from "algoliasearch/lite"
import { navigate } from "gatsby"
import qs from "qs"
import { Location } from "@reach/router"

const defaultState = {
  focus: false,
  setFocus: () => {},
  onSubmit: () => {},
  defaultRefinement: "",
  setDefaultRefinement: () => {},
  searchState: {},
  clearSearchState: () => {},
  createURL: () => {}
}

const LearnCentreSearchContext = React.createContext(defaultState)

class LearnCentreSearchProvider extends React.Component {
  state = {
    focus: false,
    defaultRefinement: "",
    searchState: {}
  }

  index = {
    name: process.env.GATSBY_ALGOLIA_LEARN_CENTRE_INDEX,
    title: process.env.GATSBY_ALGOLIA_LEARN_CENTRE_INDEX
  }

  searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  onSearchStateChange = updatedSearchState => {
    this.setState({ searchState: updatedSearchState })
  }

  clearSearchState = () => {
    this.setState({ searchState: { query: null } })
  }

  setDefaultRefinement = refinement =>
    this.setState({ defaultRefinement: refinement })

  setFocus = boolean => {
    this.setState({ focus: boolean })
  }

  onSubmit = e => {
    e.preventDefault()
    e.persist()

    this.setState({ focus: false, defaultRefinement: e?.target?.[0]?.value })
    navigate(`/learn/search-results/${this.createURL(this.state.searchState)}`)
  }

  createURL = state => {
    const config = {
      query: state?.query
    }

    return `?${qs.stringify(config)}`
  }

  render() {
    const { children } = this.props
    const { searchState, focus, defaultRefinement } = this.state

    return (
      <Location>
        {({ location }) => {
          return (
            <LearnCentreSearchContext.Provider
              value={{
                location,
                index: this.index,
                searchState,
                focus,
                setFocus: this.setFocus,
                onSubmit: this.onSubmit,
                defaultRefinement,
                setDefaultRefinement: this.setDefaultRefinement,
                createURL: this.createURL,
                searchClient: this.searchClient,
                onSearchStateChange: this.onSearchStateChange,
                clearSearchState: this.clearSearchState
              }}
            >
              {children}
            </LearnCentreSearchContext.Provider>
          )
        }}
      </Location>
    )
  }
}

export default LearnCentreSearchContext

export { LearnCentreSearchProvider }
