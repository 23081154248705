import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Variants from "@helpers/variants"

// sets default state of the product. Product loading is set to true. Once the product has been set this will be set to false.
const defaultState = {
  setProduct: () => {},
  resetProduct: () => {},
  setOption: () => {},
  setSku: () => {},
  getVariantComparison: () => {},
  currentVariant: [],
  options: new Map([]),
  currentValues: new Map([]),
  validOptions: new Map([]),
  defaultValues: new Map([]),
  currentBanner: null,
  currentPrice: null,
  parentPrice: null,
  parentProduct: null,
  valid: false,
  currentModel: null,
  productLoading: true,
  ready: false
}

const ProductContext = React.createContext(defaultState)

/**
 * Provides current parent and variant product data
 * @extends React
 */
class ProductProvider extends React.Component {
  constructor(props) {
    super(props)

    // sets default values for the provider
    this.state = {
      currentVariant: [],
      options: new Map([]),
      currentValues: new Map([]),
      validOptions: new Map([]),
      defaultValues: new Map([]),
      currentBanner: null,
      currentPrice: null,
      parentPrice: null,
      parentProduct: null,
      valid: false,
      currentModel: null,
      productLoading: true,
      ready: false
    }

    this.variants = {}
    this.isConfigurable = false
    this.initialVariant = null
    this.currency = ""
    this.parentProduct = null
    this.type = null
    this.parentPrice = null
  }

  // when the product context mounts, set ready to true. This only happens once when the application mounts
  componentDidMount() {
    this.setState({
      ready: true
    })
  }

  /**
   * Resets the product to the initial state.
   * Should be run at the componentWillUnmount stage of the product lifecycle.
   */
  resetProduct = () => {
    this.setState({
      currentVariant: [],
      options: new Map([]),
      currentValues: new Map([]),
      validOptions: new Map([]),
      defaultValues: new Map([]),
      currentBanner: null,
      currentPrice: null,
      parentPrice: null,
      parentProduct: null,
      valid: false,
      currentModel: null,
      productLoading: true
    })

    this.variants = {}
    this.isConfigurable = false
    this.initialVariant = null
    this.currency = ""
    this.parentProduct = null
    this.type = null
    this.parentPrice = null
  }

  /**
   * Sets products. Should really only be run once.
   * @param {Object} productData
   * @param {String} initialVariantSku
   */
  setProduct = ({ netoVariants = [], ...product }, initialVariantSku) => {
    // Get initial form values. If the product is in the cart already - it's
    // sku can be passed in and checked
    this.type = product.netoProductType
    // Expose product type: simple / configurable
    this.isConfigurable = this.type === "configurable"

    // if product is configurable look at all the variants and return the one tagged as default. Otherwise return the netoProduct
    this.initialVariant = this.isConfigurable
      ? getDefaultVariant(netoVariants, initialVariantSku)
      : product.netoProduct

    // Set variants if is a configurable product
    this.variants =
      this.isConfigurable && netoVariants
        ? new Variants(netoVariants, this.initialVariant.sku)
        : {}

    this.currency = product.netoProduct.currency

    // Expose some parent product data.
    this.parentProduct = {
      name: product.name,
      excerpt: product.excerpt,
      heroImage: product.heroImage,
      id: product.id,
      type: product.netoProductType,
      sku: product.sku,
      path: product.path,
      brand: product.brand?.name || "",
      category: product.category?.title || "",
      finance: product.finance,
      hasUpsells: product.hasUpsells,
      upsellPath: product.upsellPath,
      shippingOptions: product.shippingOptions
    }

    // Get aggregate pricing for parent
    this.parentPrice = getPricing(product.netoProduct, this.isConfigurable)

    const currentVariants = this.isConfigurable && [this.variants.defaultModel]
    const currentValues = this.isConfigurable && this.variants.defaultValues
    const currentModel = this.isConfigurable && this.variants.defaultModel
    const valid = currentModel !== undefined && currentModel !== null
    const currentPrice = getPricing(currentModel) || null
    const currentBanner = getBanner(currentModel) || null
    const validOptions =
      this.isConfigurable && this.variants.getValidOptions(currentValues)

    this.setState({
      currentVariants,
      currentValues,
      validOptions,
      currentBanner,
      currentPrice,
      parentPrice: this.parentPrice,
      valid,
      currentModel,
      productLoading: false
    })
  }

  setSku = sku => {
    const hasVariants = Object.keys(this.variants).length > 0
    const { list } = hasVariants && this.variants
    const currentModel = list?.find(item => item.sku === sku) || sku
    const valid = currentModel !== undefined && currentModel !== null

    if (!valid) {
      alert(`setSku failed. SKU: ${sku} does not exist`)
    }

    if (valid) {
      const newValues = hasVariants && this.variants.getOptions([currentModel])
      const currentValues = new Map()
      newValues.size > 0 &&
        newValues.forEach((value, key) => currentValues.set(key, value[0]))
      const validOptions =
        hasVariants && this.variants.getValidOptions(currentValues)
      const currentPrice = getPricing(currentModel) || null
      const currentBanner = getBanner(currentModel) || null

      this.setState({
        currentVariants: [currentModel],
        currentValues,
        validOptions,
        currentBanner,
        currentPrice,
        valid,
        currentModel
      })
    }
  }

  /**
   * Updates currently selected product options. Function will attempt to
   * resolve to a single sku. This will mean the form is valid and the product
   * can be added to the cart.
   * @param {String} name  Specifics option name
   * @param {String} value Specifics option value
   */
  setOption = (name, value) => {
    const { currentValues } = this.state
    let newValues = new Map(currentValues).set(name, value)
    let newVariants = this.variants.filterVariants(newValues)

    if (newVariants.length > 1) {
      alert(
        "We are having issues matching your selection to a product. Please contact a sales person."
      )
    }

    if (newVariants.length < 1) {
      alert(
        "No products available with these options. Please contact a sales person."
      )
    }

    const valid = newVariants.length === 1

    if (valid) {
      const currentModel = newVariants[0]
      const validOptions = this.variants.getValidOptions(newValues)
      const currentPrice = getPricing(currentModel)
      const currentBanner = getBanner(currentModel)

      this.setState({
        currentValues: newValues,
        currentVariants: newVariants,
        currentBanner,
        currentPrice,
        valid,
        currentModel,
        validOptions
      })
    } else {
      this.setState({
        currentValues,
        currentModel: null,
        currentPrice: null,
        currentBanner: null
      })
    }
  }

  /**
   * Get a compare price for a variant that differs by one Spec
   * @param {String} name           Specifics option name
   * @param {String} value          Specifics option value
   * @param {Array}  variantsArray  Array of neto variants
   */
  getCompare = (name, value, variantsArray = this.variants.list) => {
    const { currentValues } = this.state
    const compareValues = new Map(currentValues)

    compareValues.set(name, value)

    const compareVariants = Variants.filter(compareValues, variantsArray)
    const valid = compareVariants.length === 1
    const compareModel = valid ? compareVariants[0] : null
    const comparePrice = valid ? getPricing(compareModel) : null

    return {
      valid,
      compareModel,
      comparePrice
    }
  }

  render() {
    const { children } = this.props
    const {
      currentValues,
      currentModel,
      productLoading,
      valid,
      currentPrice,
      currentBanner,
      validOptions,
      ready
    } = this.state
    return (
      <ProductContext.Provider
        value={{
          setProduct: this.setProduct,
          resetProduct: this.resetProduct,
          options: this?.variants?.options || new Map([]),
          validOptions: validOptions,
          setOption: this.setOption,
          setSku: this.setSku,
          variants: this.variants.list,
          defaultValues: this.variants.defaultValues,
          currentPrice,
          currentValues,
          currentBanner,
          currentModel,
          parentPrice: this.parentPrice,
          parentProduct: this.parentProduct,
          valid,
          productLoading,
          ready,
          getVariantComparison: this.getCompare
        }}
      >
        {children}
      </ProductContext.Provider>
    )
  }
}

/*
 * Take a sku and find it in variant data. Or set the default model. Or
 * just get the first
 * @return {Object} NetoProduct
 */
const getDefaultVariant = (netoVariants, initialVariantSku) => {
  if (initialVariantSku) {
    return netoVariants.find(({ sku }) => sku === initialVariantSku)
  }

  return (
    netoVariants.find(({ defaultModel }) => defaultModel) || netoVariants[0]
  )
}

/**
 * Standardised pricing object
 * @param  {Object}  NetoProduct          NetoProduct
 * @param  {Boolean} [isAggregate=false]  Does price represent a range.
 * @return {Object}                       Pricing Object
 */
const getPricing = (props, isAggregate = false) => {
  if (props) {
    const {
      promotionPrice,
      promotionStartDate,
      promotionExpiryDate,
      retailPrice,
      rrp,
      currency,
      saveUpTo = 0
    } = props
    const startDate = getDate(promotionStartDate)
    const expiryDate = getDate(promotionExpiryDate)
    const onSale = isOnSale(startDate, expiryDate)
    const isWasNow =
      (retailPrice < rrp && retailPrice !== 0) ||
      (onSale && retailPrice > promotionPrice && promotionPrice !== 0)
    const isFromSave = onSale && saveUpTo > 0
    const was = onSale ? retailPrice : rrp
    const now =
      onSale && promotionPrice < retailPrice && promotionPrice > 0
        ? promotionPrice
        : retailPrice
    const save = onSale ? retailPrice - promotionPrice : rrp - retailPrice
    const standard = rrp
    const prices = {
      price: isWasNow ? now : standard,
      isWasNow,
      isFromSave,
      onSale,
      standard,
      was,
      now,
      save,
      currency,
      saveUpTo
    }

    return prices
  }
}

/**
 * Standardised pricing formatter
 * @type {Intl}
 */
const formatter = (currency = "AUD", language = "en-AU") =>
  new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency
  })

/**
 * [currencyFormatter description]
 * @param  {[type]} value              [description]
 * @param  {String} [language="en-AU"] [description]
 * @return {[type]}                    [description]
 */
const CurrencyFormatter = ({ value = 0, language = "en-AU" }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            currency
          }
        }
      }
    `}
    render={({ site }) => {
      const format = formatter(site.siteMetadata.currency).format

      return <>{format(value).replace(/\D00(?=\D*$)/, "")}</>
    }}
  ></StaticQuery>
)

/**
 * Check datetime string
 * @param  {Date}  d   Date
 * @return {Boolean}   is d a Date
 */
const isValidDate = d => {
  return d instanceof Date && !isNaN(d)
}

/**
 * Take datetime string from NetoProduct and convert to date
 * @param  {String} dateString
 * @return {Date}
 */
const getDate = dateString => {
  if (!dateString) return false

  try {
    const formattedString = dateString.replace(" ", "T")
    const date = new Date(formattedString)
    return isValidDate(date) ? date : false
  } catch (e) {
    console.log("Unable to parse time", e)
    return false
  }
}

/**
 * Check dates provided and see if current date fall within.
 * @param  {Date}  promoStartDate
 * @param  {Date}  promoExpiryDate
 * @return {Boolean}
 */
const isOnSale = (promoStartDate, promoExpiryDate) => {
  const now = Date.now()

  // Start date is invalid
  if (promoStartDate === undefined || !promoStartDate) return false

  try {
    // it's passed the start date
    if (+now >= +promoStartDate) {
      // end date is invalid
      if (promoExpiryDate === undefined || !promoExpiryDate) return true

      // has end date has passed
      return +now < +promoExpiryDate
    }
  } catch (e) {
    console.log("Unable to compare promo dates", e)
    return false
  }
  return false
}

/**
 * Return banner message
 * @param  {Object}  NetoProduct          NetoProduct
 * @return {String}                       BannerMessage
 */
const getBanner = ({ valueProposition }) => valueProposition

export default ProductContext

export {
  ProductProvider,
  getPricing,
  isOnSale,
  getDate,
  isValidDate,
  formatter,
  CurrencyFormatter,
  getDefaultVariant
}
