import React from "react"
import cn from "classnames"
import style from "./reviews-card.mod.scss"
import { Stars } from "../components"

const ReviewCard = ({
  reviewer,
  comments,
  rating,
  sourceType,
  reviewDate,
  uniqueReviewUrl
}) => {
  const classes = cn(style.revcard)

  return (
    <a
      href={uniqueReviewUrl}
      rel="noopener noreferrer"
      target="__blank"
      className={classes}
    >
      <div className={style.revcard__heading}>
        <h4>{reviewer.name}</h4>
        <p className={style.revcard__bullet}>&bull;</p>
        <p> On {sourceType}</p>
      </div>
      <div className={style.revcard__rating}>
        <Stars rating={rating.toFixed(1)} />
        <p className={style.revcard__bullet}>&bull;</p>
        <p className={style.revcard__date}>{reviewDate}</p>
      </div>
      <p className={style.revcard__comments}>{comments}</p>
    </a>
  )
}

export default ReviewCard
