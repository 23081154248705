import React from "react"
import style from "./hero.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Swirl from "./swirl"
import UndoLogo from "./undo-logo"
import { Container, Block, Button, Breadcrumbs } from "../components"
import { motion } from "framer-motion"

const toSuperscript = (str = "") => {
  return {
    __html:
      str !== null
        ? str.replace(/®/gi, "<sup>®</sup>").replace(/™/gi, "<sup>™</sup>")
        : ""
  }
}

const JacuzziHero = ({
  title,
  caption,
  image,
  cta,
  breadcrumbs,
  showroom = false
}) => {
  const MobileArchSvg = () => (
    <svg
      height="0"
      width="0"
      viewBox="0 0 320 320"
      xmlns="http://www.w3.org/2000/svg"
      className={style[`jacuzzi-hero__svg--mobile`]}
    >
      <clipPath
        id="banner-arch-gradient-mobile"
        clipPathUnits="objectBoundingBox"
      >
        <path
          fill="#1C1C1C"
          fillRule="evenodd"
          d="M0,0 L1,0 L1,0.601851852 C0.810705326,0.645061728 0.636746992,0.666666667 0.478125,0.666666667 C0.319503008,0.666666667 0.161169674,0.645061728 0.0,0.601851852 L0,0 Z"
        />
      </clipPath>
    </svg>
  )

  const variants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  const DesktopArchSvg = () => (
    <div className={style[`jacuzzi-hero__svg-wrapper`]}>
      <svg
        viewBox="0 0 629 878"
        xmlns="http://www.w3.org/2000/svg"
        className={style[`jacuzzi-hero__svg--desktop-dark`]}
      >
        {/* <defs>
                    <linearGradient
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="banner-arch-gradient"
                    >
                        <stop offset="0%" stopColor={style['secondar-color']} />
                        <stop
                            offset="100%"
                            stopColor={style['primary-color']}
                        />
                    </linearGradient>
                </defs> */}
        <path
          fill="#1C1C1C"
          fillRule="evenodd"
          d="M557.005 0c106.382 258.667 94.87 462.826-34.538 612.479C379.702 777.579 124.841 803.094 0 878L.018 0h556.987z"
        />
      </svg>
      <svg
        viewBox="0 0 651 927"
        xmlns="http://www.w3.org/2000/svg"
        className={style[`jacuzzi-hero__svg--desktop-light`]}
      >
        {/* <path
                    fillOpacity=".5"
                    fill="#1C1C1C"
                    fillRule="evenodd"
                    d="M574.082 0c113.656 276.532 101.356 494.792-36.9 654.78C392.28 822.46 139.29 855.46 0 927L.014 0h574.068z"
                /> */}
      </svg>
    </div>
  )

  let classes = [style["jacuzzi-hero"]]
  if (!cta) {
    classes.push(style["small"])
  }
  if (showroom) {
    classes.push(style["jacuzzi-hero--showroom"])
  }

  return (
    <motion.div variants={variants} className={classes.join(" ")}>
      <div className={style["jacuzzi-hero__image"]}>
        <span className={style["jacuzzi-hero__undo"]}>
          <UndoLogo />
        </span>
        <GatsbyImage loading={"eager"} image={image.gatsbyImageData} />
      </div>
      <div className={style["jacuzzi-hero__swirl"]}>
        <Swirl type={"arch to white"} />
      </div>
      <MobileArchSvg />
      <div className={style[`jacuzzi-hero__content-wrapper`]}>
        <DesktopArchSvg />
        <div className={style["jacuzzi-hero__content"]}>
          <h1
            className={style["jacuzzi-hero__title"]}
            dangerouslySetInnerHTML={toSuperscript(title)}
          />
          <div
            className={style["jacuzzi-hero__caption"]}
            dangerouslySetInnerHTML={{ __html: caption.html }}
          ></div>
          <div className={style["jacuzzi-hero__cta"]}>
            {cta && (
              <Button
                to={`${cta.link ? cta.link.path : ""}${
                  cta.locationHash ? "#" + cta.locationHash : ""
                }`}
                url={`${cta.url}`}
              >
                {cta.displayText}
              </Button>
            )}
          </div>
        </div>
      </div>
      {breadcrumbs && (
        <div className={style["jacuzzi-hero__breadcrumbs"]}>
          <Container>
            <Block gutters>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Block>
          </Container>
        </div>
      )}
    </motion.div>
  )
}

export default JacuzziHero
