import React from "react"
import style from "./pill.mod.scss"

export default ({ icon, children, modifiers = [], ...props }) => {
  const isCheckbox = props.type
  props.secondary && modifiers.push(`${style.pill}--secondary`)
  props.accent && modifiers.push(`${style.pill}--accent`)
  props.elevated && modifiers.push(`${style.pill}--elevated`)
  props.moduleStyle && modifiers.push(`${style.pill}--${props.moduleStyle}`)
  props.disabled && modifiers.push(`${style.pill}--disabled`)

  if (isCheckbox) {
    const { name, type, value, isChecked, onChange } = props
    modifiers.push(`${style.pill}--${type}`)
    isChecked && modifiers.push(`${style.pill}--checked`)

    return (
      <label className={[style.pill, ...modifiers].join(" ")}>
        <input
          className={style.pill__input}
          name={name}
          value={value}
          checked={isChecked && "checked"}
          type={type}
          disabled={props.disabled}
          onChange={onChange}
        />
        <span className={style.pill__icon}>{icon}</span>
        <span className={style.pill__text}>{children}</span>
      </label>
    )
  }

  return (
    <button className={[style.pill, ...modifiers].join(" ")}>
      <span className={style.pill__icon}>{icon}</span>
      <span className={style.pill__text}>{children}</span>
    </button>
  )
}
