import React from "react"
import { graphql } from "gatsby"
import style from "./chooser.mod.scss"
import { motion, AnimatePresence } from "framer-motion"
import { Progress, Button } from "@components"
import ChooserContext from "@context/chooserContext"

const Chooser = ({ steps, products, contactPagePath }) => {
  const Step = ({ id, heading, answers, stepIndex }) => {
    const skipStep = stepIndex === 0

    return (
      <AnimatePresence>
        <motion.div
          key={id}
          className={style.chooser__step}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
        >
          <Progress position={stepIndex + 1} length={steps.length} />
          <h4 dangerouslySetInnerHTML={{ __html: heading }}></h4>
          <ul className={style.chooser__options}>
            {answers.map(answer => (
              <Answer key={answer.id} {...answer} skip={skipStep} />
            ))}
          </ul>
        </motion.div>
      </AnimatePresence>
    )
  }

  const Results = () => (
    <ChooserContext.Consumer>
      {({ recommendations, clearChooser }) => {
        const noResults = !recommendations || recommendations.length < 1

        return (
          <AnimatePresence>
            <motion.div
              key={"chooser-end"}
              className={style.chooser__step}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
            >
              <h3>
                {noResults
                  ? "Sorry, we can’t find an exact match for your search. Try again or contact a spa expert."
                  : "We recommend the following products"}
              </h3>

              <div className={style.chooser__step_ctas}>
                <Button
                  type="button"
                  onClick={() => clearChooser()}
                  children={"Clear Results"}
                  fullWidthMobile
                />
                <Button
                  gaTarget={"intro-cta"}
                  to={contactPagePath}
                  children={"Contact a Spa Expert"}
                  fullWidthMobile
                />
              </div>
            </motion.div>
          </AnimatePresence>
        )
      }}
    </ChooserContext.Consumer>
  )

  const Answer = answer => (
    <ChooserContext.Consumer>
      {contextProps => {
        const { updateChooser, currentIndex } = contextProps
        const isComplete = currentIndex + 1 === steps.length

        let buttonConfig

        const checkShouldReroute = () => {
          const shouldReroute = answer.displayText !== "Spa Pools"
          if (shouldReroute) {
            buttonConfig = {
              to: answer.path
            }
          } else {
            buttonConfig = {
              type: "button",
              onClick: () => updateChooser(answer, isComplete, products)
            }
          }
        }

        if (answer.skip) {
          checkShouldReroute()
        } else {
          buttonConfig = {
            type: "button",
            onClick: () => updateChooser(answer, isComplete, products)
          }
        }

        return (
          <li className={style.chooser__option}>
            <Button
              {...buttonConfig}
              gaTarget={"chooser-option-button"}
              aria-controls={`spa-chooser`}
              children={answer.displayText}
              short
              fullWidthMobile
            />
          </li>
        )
      }}
    </ChooserContext.Consumer>
  )

  return (
    <ChooserContext.Consumer>
      {({ isComplete, currentIndex }) => {
        const step = steps[currentIndex]

        return (
          <div id={`spa-chooser`} className={style.chooser}>
            <div className={style.chooser__surface}>
              <header className={style.chooser__header}>
                <h2>Let us help you find the right product</h2>
              </header>
              <div className={style.chooser__body} aria-live="assertive">
                {!isComplete && step ? (
                  <Step stepIndex={currentIndex} {...step} />
                ) : (
                  <Results />
                )}
              </div>
            </div>
          </div>
        )
      }}
    </ChooserContext.Consumer>
  )
}

export default Chooser

export const query = graphql`
  fragment ChooserSteps on DatoCmsSelectorStep {
    heading
    id
    answers {
      ...ChooserAnswer
    }
  }

  fragment ChooserAnswer on DatoCmsAnswer {
    id
    displayText
    excludeMatches
    relatedProducts {
      id
      name
      model {
        apiKey
      }
      __typename
      slug
    }
  }
`
