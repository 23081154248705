import React, { useState, Fragment, useContext, useEffect, useRef } from "react"
import style from "./cart.mod.scss"
import CartContext from "@context/cartContext"
import { CurrencyFormatter } from "@context/productContext"
import { TransitionLink } from "@helpers/pageTransitions"
import CartSvg from "@svgs/cart.svg"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight"
import { motion, AnimatePresence } from "framer-motion"
import { useLocation } from "@reach/router"
import ThemeContext from "@context/themeContext"

const Cart = ({ linkMode = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(null)
  const cartRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = e => {
      if (cartRef.current && !cartRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.body.addEventListener("click", handleOutsideClick)

    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [])

  const classes = [style.cart]

  isOpen && classes.push(`${style.cart}--open`)

  const overlay = {
    open: {
      display: "block",
      opacity: 1,
      x: 0,
      pointerEvents: "initial"
    },
    closed: {
      display: "none",
      pointerEvents: "none",
      x: "20%",
      opacity: 0,
      transition: {
        x: { type: "spring", stiffness: 100, duration: 0.5 },
        display: {
          delay: 0.5
        }
      }
    }
  }

  const { pathname } = useLocation()
  const isCartPage =
    pathname.search("/customise/") > -1 || pathname.startsWith("/cart")
  const {
    loading,
    count,
    cart,
    remove,
    getSubtotal,
    getCartLinkProps
  } = useContext(CartContext)

  const cartLinkProps = getCartLinkProps()

  return (
    <ThemeContext.Consumer>
      {({ setIsCartPopup }) => (
        <div className={classes.join(" ")} ref={cartRef}>
          {linkMode && !isCartPage ? (
            <TransitionLink className={style.cart__link} {...cartLinkProps}>
              <CartButton count={!loading && count} />
            </TransitionLink>
          ) : (
            <button
              className={style.cart__link}
              onClick={() => {
                setIsOpen(!isOpen)
                setIsCartPopup(isOpen)
              }}
            >
              <CartButton count={count} />
            </button>
          )}
          <motion.div
            className={style.cart__overlay}
            initial={"closed"}
            variants={overlay}
            animate={isOpen ? "open" : "closed"}
          >
            <header className={style.cart__header}>
              <h4>{"Your Cart"}</h4>
              <button
                className={style.cart__close}
                onClick={() => setIsOpen(false)}
              >
                <BsArrowRight />
              </button>
            </header>
            <CartTable cart={cart} getSubtotal={getSubtotal}>
              {[...cart.values()].map(
                item =>
                  item.type !== "shipping" && (
                    <CartTableRow
                      item={item}
                      remove={remove}
                      key={`cart-item-${item.sku}`}
                    />
                  )
              )}
            </CartTable>
          </motion.div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

const CartTableRow = ({ item, remove }) => {
  const isConfigurable = item.type === "configurable"
  let options = []
  const deliveryPrice = item.variantDetails?.fromDeliveryPrice

  if (isConfigurable) {
    const { specifics } = item.variantDetails
    options = specifics.filter(({ name, value }) => {
      switch (name) {
        case "Model Type":
        case "Depth":
        case "Package":
          return false
        default:
          return true
      }
    })
  }

  return (
    <tr>
      <td className={style.cart__product}>
        <h4>{`${item.displayName} `}</h4>
        {isConfigurable && (
          <dl className={style.cart__options}>
            {options.map(({ name, value }) => (
              <Fragment key={`item-${item.sku}-${name}`}>
                <dt>{name}</dt>
                <dd>{value}</dd>
              </Fragment>
            ))}
          </dl>
        )}
        {deliveryPrice && (
          <dl className={style.cart__options}>
            <dd>{`Additional delivery charge from $${deliveryPrice}`}</dd>
          </dl>
        )}
      </td>
      <td className={style.cart__price}>
        <CurrencyFormatter value={item.prices.price} />
      </td>
      <td>
        <button className={style.cart__remove} onClick={() => remove(item.sku)}>
          <FaTimes />
        </button>
      </td>
    </tr>
  )
}

const CartButton = ({ count }) => {
  return (
    <div className={style.cart__button}>
      <CartSvg className={style.cart__icon} />
      <AnimatePresence>
        {count > 0 && (
          <motion.span
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
            key={`count-${count}`}
            className={style.cart__count}
          >
            {count}
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  )
}

const CartTable = ({ cart, getSubtotal, children }) => (
  <table className={style.cart__table}>
    <thead>
      <tr>
        <th>{`Item Name`}</th>
        <th>{`Price`}</th>
        <td></td>
      </tr>
    </thead>
    <tfoot>
      <tr>
        <th scope="row">{`Subtotal`}</th>
        <td className={style.cart__subtotal}>
          <CurrencyFormatter value={getSubtotal()} />
        </td>
        <td></td>
      </tr>
    </tfoot>
    <tbody>
      {children.length > 0 ? (
        <Fragment>{children}</Fragment>
      ) : (
        <tr>
          <td colSpan="3">{`Your cart is empty`}</td>
        </tr>
      )}
    </tbody>
  </table>
)

export default Cart
