import React, { useState, useEffect } from "react"
import style from "./iframe.mod.scss"
import { graphql } from "gatsby"
import IframeResizer from "iframe-resizer-react"
import { Loading } from "@components"
import { motion } from "framer-motion"

const Iframe = ({ resize = false, ...props }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0,
      tabIndex: -1,
      transition: {
        delay: 0.5,
        opacity: "linear"
      }
    },
    visible: {
      opacity: 1,
      scale: 1,
      tabIndex: 0,
      transition: {
        delay: 0.5,
        opacity: "linear"
      }
    }
  }

  return (
    <div className={style.iframe}>
      {resize ? (
        <div className={style.iframe__inner}>
          <motion.div
            initial={"visible"}
            animate={loading ? "visible" : "hidden"}
            variants={variants}
            className={style.iframe__loading}
          >
            <Loading />
          </motion.div>
          <motion.div
            initial={"hidden"}
            animate={loading ? "hidden" : "visible"}
            variants={variants}
          >
            <IframeResizer
              {...props}
              style={{
                width: "1px",
                minWidth: "100%",
                minHeight: "480px"
              }}
            />
          </motion.div>
        </div>
      ) : (
        <iframe {...props} width={"100%"} height={"100%"} />
      )}
    </div>
  )
}

export default Iframe

export const query = graphql`
  fragment FormIframe on DatoCmsEmbed {
    id
    title
    embed
    padding
    model {
      apiKey
    }
    __typename
  }
`
