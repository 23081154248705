import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import style from "./learn-centre-results-dropdown.mod.scss"
import { Highlight, Index, connectHits } from "react-instantsearch-dom"
import { Button, Card, VideoCard, Modal } from "@components"
import LogoSvg from "@svgs/logo.svg"
import ThemeContext from "@context/themeContext"

const PageHit = ({
  hit,
  setFocus,
  setSearchOpen,
  searchState,
  setDefaultRefinement,
  toggleModal
}) => {
  return (
    <>
      {hit.video ? (
        <Button
          onClick={e => {
            e.preventDefault()
            toggleModal(hit.video)
          }}
          unstyled
        >
          <VideoCard
            key={`search-video-card--${hit.objectID}`}
            heading={hit.video.title}
            overlayImage={hit.image}
            onClick={() => toggleModal(hit.video)}
            elevated
            video={hit.video}
            inMenu={true}
            buttonText={"Learn More"}
          />
        </Button>
      ) : (
        <Button
          onClick={e => {
            e.preventDefault()
            setDefaultRefinement(searchState?.query)
            navigate(`${hit.url}`)
            setFocus(false)
            setSearchOpen(false)
          }}
          unstyled
        >
          <>
            {!hit.image && (
              <div className={style["learn-centre-result__learn-image"]}>
                <LogoSvg />
              </div>
            )}
            <Card
              image={hit.image}
              heading={hit.name}
              elevated
              links={[{ url: hit.url, title: "Learn More" }]}
            />
          </>
        </Button>
      )}
    </>
  )
}

const HitsGrid = ({
  section,
  viewAll,
  hits,
  searchState,
  setDefaultRefinement,
  setFocus,
  createURL,
  setSearchOpen,
  index,
  suggested
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoInModal, setVideoInModal] = useState(null)

  const toggleIsModalOpen = boolean => setIsModalOpen(boolean)

  function toggleModal(video) {
    setVideoInModal(video)
    toggleIsModalOpen(true)
  }

  const searchActive = searchState?.query && searchState?.query?.length > 0
  const modifiers = []
  searchActive && modifiers.push(style["learn-centre-result__open"])

  return (
    <>
      {suggested && !searchActive && (
        <div className={style["learn-centre-result__featured-items"]}>
          <h4 className={style["learn-centre-result__grid-category-heading"]}>
            {"Popular Topics"}
          </h4>
          <ul>
            {suggested.slice(0, 3).map(item => {
              return (
                <li>
                  <a href={item.path}>{item.title || item.heading}</a>
                </li>
              )
            })}
          </ul>
        </div>
      )}

      <div
        className={[
          style["learn-centre-result__transition"],
          ...modifiers
        ].join(" ")}
      >
        <div className={style["learn-centre-result__grid-category-head"]}>
          <h4 className={style["learn-centre-result__grid-category-heading"]}>
            {"Results"}
          </h4>
          {viewAll && hits.length > 0 && (
            <div
              className={
                style["learn-centre-result__grid-category-head-view-all"]
              }
            >
              <Button
                onClick={e => {
                  e.preventDefault()
                  setDefaultRefinement(searchState?.query)
                  navigate(`/learn/search-results/${createURL(searchState)}`)
                  setFocus(false)
                }}
                unstyled
              >
                {"View All"}
              </Button>
            </div>
          )}
        </div>
        <ul>
          {hits.length > 0 ? (
            hits.slice(0, 3).map(hit => {
              return (
                <li key={hit.objectID}>
                  <PageHit
                    section={section}
                    current
                    hit={hit}
                    setFocus={setFocus}
                    setSearchOpen={setSearchOpen}
                    setDefaultRefinement={setDefaultRefinement}
                    searchState={searchState}
                    toggleModal={toggleModal}
                  />
                </li>
              )
            })
          ) : (
            <p className={style["learn-centre-result__no-results"]}>
              {"No Results Found"}
            </p>
          )}
        </ul>
        <Modal
          onChange={toggleIsModalOpen}
          globalState={isModalOpen}
          video={videoInModal}
          ariaLabel={`Open modal and play video`}
          gtm={{
            event: `video-engagement`,
            label: `Video Model Click`,
            value: videoInModal?.title
          }}
        />

        {viewAll && hits.length > 0 && (
          <div className={style["learn-centre-result__view-all"]}>
            <Button
              onClick={e => {
                e.preventDefault()
                setDefaultRefinement(searchState?.query)
                navigate(`/learn/search-results/${createURL(searchState)}`)
                setFocus(false)
              }}
              fullWidthMobile
              secondary
              short
            >
              {"View All"}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

const CustomHits = ({ hits, indexName, viewAll, context, suggested }) => {
  const { setSearchOpen } = useContext(ThemeContext)
  const {
    searchState,
    setDefaultRefinement,
    setSearchIndexName,
    setFocus,
    createURL,
    index
  } = context

  return (
    <>
      <div className={style["learn-centre-result__scroll-window"]}>
        <div className={style["learn-centre-result__grid-category"]}>
          <HitsGrid
            hits={hits}
            section={indexName}
            viewAll={viewAll}
            searchState={searchState}
            setDefaultRefinement={setDefaultRefinement}
            setFocus={setFocus}
            setSearchIndexName={setSearchIndexName}
            createURL={createURL}
            setSearchOpen={setSearchOpen}
            index={index}
            suggested={suggested}
          />
        </div>
      </div>
      <div className={style["learn-centre-result__grid-overlay"]}></div>
    </>
  )
}

const Hits = connectHits(CustomHits)

const HitsInIndex = ({ index, viewAll, context, suggested }) => (
  <Index indexName={index.name}>
    <Hits
      className="Hits"
      hitComponent={PageHit}
      viewAll={viewAll}
      context={context}
      suggested={suggested}
    />
  </Index>
)

const LearnCentreSearchResultDropdown = ({
  index,
  viewAll = true,
  context = {},
  contained = false,
  modifiers = [],
  suggested
}) => {
  contained && modifiers.push(style["learn-centre-result--contained"])

  return (
    <>
      {context.focus && (
        <div
          className={[[style["learn-centre-result"], ...modifiers].join(" ")]}
        >
          <HitsInIndex
            index={index}
            key={index.name}
            viewAll={viewAll}
            context={context}
            suggested={suggested}
          />
        </div>
      )}
    </>
  )
}

export default LearnCentreSearchResultDropdown
