import React from "react"
import style from "./tooltip.mod.scss"
import CloseIcon from "@svgs/close.svg"
import { motion } from "framer-motion"

const Tooltip = ({
  children,
  closeTooltip = () => {},
  isTooltipOpen = false
}) => {
  const modifiers = []

  const variants = {
    open: {
      display: "block",
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        restDelta: 2,
        opacity: {
          duration: 0.3
        }
      }
    },
    closed: {
      display: "none",
      y: "20%",
      opacity: 0,
      transition: {
        display: {
          delay: 0.3
        }
      }
    }
  }

  return (
    <motion.div
      className={[style.tooltip, ...modifiers].join(" ")}
      animate={isTooltipOpen ? "open" : "closed"}
      variants={variants}
      initial={"closed"}
    >
      <button className={style.tooltip__close} onClick={closeTooltip}>
        <CloseIcon />
      </button>
      <div className={style.tooltip__children}>{children}</div>
    </motion.div>
  )
}

export default Tooltip
