import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import style from "./contact-disclaimer.mod.scss"

const ContactDisclaimer = () => {
  const { content } = useStaticQuery(graphql`
    {
      content: contentYaml {
        contact {
          disclaimer
        }
      }
    }
  `)

  return (
    <p className={style[`contact-disclaimer`]}>{content.contact.disclaimer}</p>
  )
}

export default ContactDisclaimer
