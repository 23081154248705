import React, { useContext, useEffect } from "react"
import SearchContext from "@context/searchContext"
import { InstantSearch, Configure } from "react-instantsearch-dom"

const InstantSearchWrapper = ({ children }) => {
  const {
    index,
    searchClient,
    onSearchStateChange,
    createURL,
    searchIndexName,
    setSearchIndexName,
    defaultRefinement,
    setDefaultRefinement
  } = useContext(SearchContext)

  let indexName = searchIndexName
  let refinement = defaultRefinement

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = {}
      const queryString = window.location.search.slice(1)
      queryString.split("&").forEach(function(param) {
        const keyValue = param.split("=")
        const key = decodeURIComponent(keyValue[0])
        const value = decodeURIComponent(keyValue[1]) || ""
        urlParams[key] = value
      })

      if (urlParams && urlParams.query) {
        refinement = urlParams.query
        setDefaultRefinement(refinement)
      }
      if (urlParams && urlParams.search_type) {
        indexName = urlParams.search_type
        setSearchIndexName(indexName)
      }
      // Default back to products category when pressing enter bar after leaving results pages
      if (queryString === "") {
        setSearchIndexName(process.env.GATSBY_ALGOLIA_PRODUCT_INDEX)
      }
    }
  }, [])

  const filterConfig =
    searchIndexName === process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX
      ? "user_group:1"
      : ""

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      refresh={true}
    >
      <Configure filters={filterConfig} hitsPerPage={18} />
      {children}
    </InstantSearch>
  )
}

export default InstantSearchWrapper
