import React from "react"
import { connectStateResults } from "react-instantsearch-dom"

const CustomStateResults = ({ searchResults, hasResults }) => {
  const nbHits = searchResults && searchResults?.nbHits

  return (
    <>
      {!hasResults && (
        <center>{`Sorry, no results found. Please try again.`}</center>
      )}
      {hasResults && (
        <center>
          {`Found ${nbHits} ${nbHits > 1 ? "results" : "result"} for "`}
          <span>{searchResults?.query}</span>
          {`"`}
        </center>
      )}
    </>
  )
}

const ResultsMeta = connectStateResults(CustomStateResults)

const StateResults = ({ hasResults }) => <ResultsMeta hasResults={hasResults} />

export default StateResults
