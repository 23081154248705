import React from "react"
import Layout from "@layouts/layout"
import { Block, Intro, LocationMapWithFinder } from "@components"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ModuleArea from "../helpers/moduleArea"

const ShowroomLanding = ({ data, pageContext }) => {
  const { heading, lead, seoMetaTags, content } = data.mainLanding

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: heading,
    lead: lead
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block gutters>
        <Intro {...introConfig} />
      </Block>
      <Block gutters padding="double">
        <LocationMapWithFinder />
      </Block>
      {content && (
        <Block>
          <ModuleArea modules={content} />
        </Block>
      )}
    </Layout>
  )
}

export default ShowroomLanding

export const query = graphql`
  query showroomLanding($language: String!) {
    globalConfig: datoCmsGlobal(locale: $language) {
      contactPage {
        path
      }
    }
    mainLanding: datoCmsShowroomMainLanding(locale: $language) {
      heading
      lead
      ...ShowroomMainLandingModuleArea
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
