import React from "react"
import cn from "classnames"
import style from "./stars.mod.scss"

const Stars = ({ rating }) => {
  const classes = cn(style.stars)
  const fullStars = Math.floor(rating)
  const hasHalfStar = rating % 1 >= 0.5

  return (
    <div className={classes}>
      {[...Array(fullStars)].map((_, i) => (
        <Star key={i} />
      ))}
      {hasHalfStar && <HalfStar />}
      {[...Array(5 - fullStars - (hasHalfStar ? 1 : 0))].map((_, i) => (
        <EmptyStar key={i} />
      ))}
    </div>
  )
}

export default Stars

const Star = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 23">
      <path
        fill="#FCD136"
        d="M12.738 1.923L12.5 1.19l-.238.732-2.301 7.082H1.744l.623.453 6.025 4.377-2.302 7.082-.237.732.622-.452L12.5 16.82l6.025 4.377.622.452-.237-.732-2.302-7.082 6.025-4.377.623-.453h-8.217l-2.301-7.082z"
      ></path>
    </svg>
  )
}

const HalfStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 19">
      <path
        fill="url(#a)"
        d="M10.5 0l2.357 7.255h7.63l-6.173 4.484 2.358 7.256L10.5 14.51l-6.172 4.484 2.358-7.256L.514 7.255h7.629L10.5 0z"
      ></path>
      <defs>
        <linearGradient
          id="a"
          x1="0"
          x2="21"
          y1="11"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCD136"></stop>
          <stop offset="0.50" stopColor="#FCD136"></stop>
          <stop offset="0.50" stopColor="#D9D9D9"></stop>
          <stop offset="1" stopColor="#D9D9D9"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

const EmptyStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 23">
      <path
        fill="#D9D9D9"
        d="M12.738 1.923L12.5 1.19l-.238.732-2.301 7.082H1.744l.623.453 6.025 4.377-2.302 7.082-.237.732.622-.452L12.5 16.82l6.025 4.377.622.452-.237-.732-2.302-7.082 6.025-4.377.623-.453h-8.217l-2.301-7.082z"
      ></path>
    </svg>
  )
}
