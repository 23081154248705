import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { Intro, Block, InfiniteResults } from "@components"
import SearchResultsLayout from "@layouts/search-results-layout"
import SearchContext from "@context/searchContext"

const SearchResults = ({ data, pageContext }) => {
  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: data.content.search.heading,
    lead: data.content.search.lead,
    showHeroImage: false
  }

  const context = useContext(SearchContext)

  return (
    <SearchResultsLayout>
      <Block gutters>
        <Intro {...introConfig} />
        <InfiniteResults context={{ ...context }} />
      </Block>
    </SearchResultsLayout>
  )
}

export default SearchResults

export const query = graphql`
  query search {
    content: contentYaml {
      search {
        heading
        lead
      }
    }
  }
`
