import React from "react"
import { Rings } from "svg-loaders-react"

const Loading = props => (
  <div className={"loading-test"} style={{ textAlign: "center" }}>
    <Rings stroke={"#5bbbb7"} />
  </div>
)

export default Loading
