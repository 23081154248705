import React from "react"
import { motion } from "framer-motion"
import style from "./menu-icon.mod.scss"

const MenuIcon = ({ toggle, isOpen }) => {
  return (
    <motion.button
      className={style["menu-icon"]}
      animate={isOpen ? "open" : "closed"}
      onClick={toggle}
      aria-label={"Toggle Menu"}
    >
      <svg viewBox="0 0 40 41">
        <circle cx="20" cy="20" r="20" />
        <motion.rect
          width="18"
          height="2.75"
          rx=".5"
          x="11"
          y="13"
          initial={{
            originX: "50%",
            originY: "50%"
          }}
          variants={{
            open: {
              rotate: 45,
              y: 4.5,
              x: -4
            },
            closed: {
              rotate: 0,
              y: 0,
              x: 0
            }
          }}
        />
        <motion.rect
          width="18"
          height="2.75"
          rx=".5"
          x="11"
          y="19"
          initial={{
            originX: "50%",
            originY: "50%",
            opacity: 1
          }}
          variants={{
            open: {
              y: 3,
              opacity: 0
            },
            closed: {
              y: 0,
              opacity: 1
            }
          }}
        />
        <motion.rect
          width="18"
          height="2.75"
          rx=".5"
          x="11"
          y="25"
          initial={{
            originX: "50%",
            originY: "50%"
          }}
          variants={{
            open: {
              rotate: -45,
              y: -4,
              x: -4.5
            },
            closed: {
              rotate: 0,
              y: 0,
              x: 0
            }
          }}
        />
      </svg>
    </motion.button>
  )
}

export default MenuIcon
