import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./category-cards.mod.scss"
import { Intro, Cards, Card, Block, Container } from "@components"

const CategoryCards = ({ categories, border = "none", padding = "none" }) => {
  const classes = [
    style.category_cards,
    style[`category_cards__border_${border}`],
    style[`category_cards__padding_${padding}`]
  ].join(" ")
  return (
    <div className={classes}>
      <Container>
        {categories && (
          <Block gutters padding={`double-bottom`} relative>
            <Cards>
              {categories.map(card => {
                return (
                  <Card
                    key={`category-${card.id}`}
                    heading={card.title || card.heading || card.name}
                    caption={card.cardIntroText}
                    link={{
                      path: card.slug,
                      title: "Learn More",
                      url: card.slug
                    }}
                    image={card.cardImage}
                    elevated
                  />
                )
              })}
            </Cards>
          </Block>
        )}
        <div className={style.category_cards__bottomline}></div>
      </Container>
    </div>
  )
}

export default CategoryCards
