import React from "react"
import style from "./split-block.mod.scss"

const SplitBlock = ({ leftChildren, rightChildren, maxWidth }) => {
  const modifiers = []
  maxWidth && modifiers.push([`sw--split-block--max-width-${maxWidth}`])

  return (
    <div className={[style[`split-block`], ...modifiers].join(" ")}>
      <div className={style[`split-block__left`]}>{leftChildren}</div>
      <div className={style[`split-block__right`]}>{rightChildren}</div>
    </div>
  )
}

export default SplitBlock
